import React, { useState, useEffect } from "react";
import {
  Card,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  IconButton,
  ImageList,
  ImageListItem,
  MenuItem,
  Select,
} from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";
import VuiTypography from "components/VuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import VuiPagination from "components/VuiPagination";
import Icon from "@mui/material/Icon";
import { useParams } from "react-router-dom";
import { green, grey } from "@mui/material/colors";

function BreakdownTracker() {
  const { site_id, section_id } = useParams();
  const [sectionName, setSectionName] = useState("");
  const [breakdowns, setBreakdowns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
  const [pictureDialogOpen, setPictureDialogOpen] = useState(false);
  const [currentBreakdown, setCurrentBreakdown] = useState(null);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [currentPhotos, setCurrentPhotos] = useState([]);
  const [siteName, setSiteName] = useState("");
  const [commentsDialogOpen, setCommentsDialogOpen] = useState(false);
  const [currentComments, setCurrentComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [uploadingPhoto, setUploadingPhoto] = useState(false);
  const [partsDialogOpen, setPartsDialogOpen] = useState(false);
  const [currentParts, setCurrentParts] = useState([]);
  const [newPart, setNewPart] = useState({
    part_name: "",
    part_description: "",
    quantity: 1,
  });
  const [editingPart, setEditingPart] = useState(null);

  const email = localStorage.getItem("email");
  const userName = localStorage.getItem("name");

  const statusOptions = [
    { value: "reported", label: "Reported" },
    { value: "in_progress", label: "In Progress" },
    { value: "awaiting_parts", label: "Awaiting Parts" },
    { value: "resolved", label: "Resolved" },
    { value: "closed", label: "Closed" },
  ];

  const priorityOptions = [
    { value: "low", label: "Low" },
    { value: "medium", label: "Medium" },
    { value: "high", label: "High" },
    { value: "critical", label: "Critical" },
  ];

  const [newBreakdown, setNewBreakdown] = useState({
    equipment_name: "",
    description: "",
    reported_by: userName || email,
    status: "reported",
    priority: "medium",
    downtime_hours: 0,
  });

  // Fetch site data
  const fetchSiteData = async () => {
    try {
      // Fetch site name
      const siteResponse = await fetch(
        `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/site/profile?site_id=${site_id}`
      );
      const siteData = await siteResponse.json();
      setSiteName(siteData.name);

      // Fetch section name if section_id exists
      if (section_id) {
        const sectionResponse = await fetch(
          `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/sections/profile?section_id=${section_id}`
        );
        const sectionData = await sectionResponse.json();
        setSectionName(sectionData.section_name);
      }
    } catch (error) {
      console.error("Error fetching site/section data:", error.message);
    } finally {
      setLoading(false);
    }
  };

  // Fetch breakdowns
  const fetchBreakdowns = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/sites/breakdowns?site_id=${site_id}`
      );
      if (!response.ok) throw new Error("Failed to fetch breakdowns");
      const data = await response.json();

      const transformedData = data.map((breakdown) => ({
        id: breakdown.breakdown_id,
        equipment_name: breakdown.equipment_name,
        description: breakdown.description,
        reported_by: breakdown.reported_by,
        reported_at: breakdown.reported_at,
        resolved_at: breakdown.resolved_at,
        status: breakdown.status,
        priority: breakdown.priority,
        downtime_hours: breakdown.downtime_hours,
        photo_count: breakdown.photo_count || 0,
        part_count: breakdown.part_count || 0,
        comment_count: breakdown.comment_count || 0,
      }));
      
      setBreakdowns(transformedData);
    } catch (error) {
      console.error("Error fetching breakdowns:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchSiteData();
    fetchBreakdowns();
  }, [site_id]);

  // Handle update breakdown status
  const handleUpdateStatus = async (breakdownId, newStatus) => {
    try {
      const response = await fetch(
        `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/breakdowns/update`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            breakdown_id: breakdownId,
            status: newStatus,
            // If status is resolved, set resolved_at to current time
            ...(newStatus === "resolved" && { resolved_at: new Date().toISOString() }),
          }),
        }
      );

      if (!response.ok) throw new Error("Failed to update breakdown status");

      await fetchBreakdowns();
    } catch (error) {
      console.error("Error updating breakdown status:", error);
    }
  };

  // Handle delete breakdown
  const handleDeleteBreakdown = async (breakdownId) => {
    if (window.confirm("Are you sure you want to delete this breakdown record?")) {
      try {
        await fetch(
          `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/breakdowns/delete?breakdown_id=${breakdownId}`,
          {
            method: "DELETE",
          }
        );
        fetchBreakdowns();
      } catch (error) {
        console.error("Error deleting breakdown:", error);
      }
    }
  };

  // Handle add breakdown
  const handleAddBreakdown = async () => {
    try {
      const response = await fetch(
        `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/sites/breakdowns/create?site_id=${site_id}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            equipment_name: newBreakdown.equipment_name,
            description: newBreakdown.description,
            reported_by: newBreakdown.reported_by,
            status: newBreakdown.status,
            priority: newBreakdown.priority,
            downtime_hours: Number(newBreakdown.downtime_hours),
          }),
        }
      );

      if (!response.ok) throw new Error("Failed to create breakdown");

      setAddDialogOpen(false);
      await fetchBreakdowns();
      setNewBreakdown({
        equipment_name: "",
        description: "",
        reported_by: userName || email,
        status: "reported",
        priority: "medium",
        downtime_hours: 0,
      });
    } catch (error) {
      console.error("Error creating breakdown:", error);
    }
  };

  // Handle upload photo
  const handleUploadPhoto = async (event) => {
    const file = event.target.files[0];
    if (!file || !currentBreakdown) return;

    setUploadingPhoto(true);
    try {
      const urlResponse = await fetch(
        "https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/breakdowns/photos/generate-presigned-url",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            breakdown_id: currentBreakdown.id,
            site_id: site_id,
            file_name: file.name,
          }),
        }
      );

      const { upload_url, photo_id, s3_key } = await urlResponse.json();

      const uploadResult = await fetch(upload_url, {
        method: "PUT",
        body: file,
        headers: { "Content-Type": file.type },
      });

      if (!uploadResult.ok) throw new Error("Upload failed");

      const recordResponse = await fetch(
        "https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/breakdowns/photos/create-photo-record",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            photo_id,
            breakdown_id: currentBreakdown.id,
            s3_key,
            file_name: file.name,
          }),
        }
      );

      const newPhoto = await recordResponse.json();
      setCurrentPhotos((prev) => [newPhoto, ...prev]);
    } catch (error) {
      console.error("Upload failed:", error);
      alert("Upload failed: " + error.message);
    } finally {
      setUploadingPhoto(false);
    }
  };

  const handleDeletePhoto = async (photoId) => {
    try {
      const response = await fetch(
        `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/breakdowns/photos/delete`,
        {
          method: "DELETE",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ photo_id: photoId }),
        }
      );

      if (!response.ok) throw new Error("Failed to delete photo");
      
      setCurrentPhotos((prevPhotos) =>
        prevPhotos.filter((photo) => photo.photo_id !== photoId)
      );
    } catch (error) {
      console.error("Error deleting photo:", error);
    }
  };

  // Handle add comment
  const handleAddComment = async () => {
    if (!currentBreakdown) {
      console.error("No breakdown selected for comment");
      return;
    }

    try {
      const response = await fetch(
        "https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/breakdowns/comments/add",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            breakdown_id: currentBreakdown.id,
            comment_text: newComment,
            created_by: userName || email,
          }),
        }
      );

      const data = await response.json();

      const newFormattedComment = {
        ...data,
        comment_text: newComment,
        created_by: userName || email,
        created_at: data.created_at || new Date().toISOString(),
      };

      setCurrentComments([newFormattedComment, ...currentComments]);
      setNewComment("");
      setBreakdowns((prevBreakdowns) =>
        prevBreakdowns.map((breakdown) =>
          breakdown.id === currentBreakdown.id
            ? { ...breakdown, comment_count: (breakdown.comment_count || 0) + 1 }
            : breakdown
        )
      );
    } catch (error) {
      console.error("Error adding comment:", error);
    }
  };

  // Handle open details dialog
  const handleOpenDetailsDialog = async (breakdownId) => {
    try {
      const breakdown = breakdowns.find((b) => b.id === breakdownId);
      if (breakdown) {
        setCurrentBreakdown(breakdown);
        setDetailsDialogOpen(true);
      }
    } catch (error) {
      console.error("Error fetching breakdown details:", error);
    }
  };

  // Handle open picture dialog
  const handleOpenPictureDialog = async (breakdownId) => {
    const breakdown = breakdowns.find((b) => b.id === breakdownId);
    if (breakdown) {
      setCurrentBreakdown(breakdown);
      setPictureDialogOpen(true);
      try {
        const response = await fetch(
          `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/breakdowns/photos?breakdown_id=${breakdown.id}`
        );
        if (!response.ok) throw new Error("Failed to fetch photos");
        const data = await response.json();
        setCurrentPhotos(data || []);
      } catch (error) {
        console.error("Error fetching photos:", error);
        setCurrentPhotos([]);
      }
    }
  };

  // Handle open parts dialog
  const handleOpenPartsDialog = async (breakdownId) => {
    const breakdown = breakdowns.find((b) => b.id === breakdownId);
    if (breakdown) {
      setCurrentBreakdown(breakdown);
      setPartsDialogOpen(true);
      try {
        const response = await fetch(
          `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/breakdowns/parts?breakdown_id=${breakdown.id}`
        );
        if (!response.ok) throw new Error("Failed to fetch parts");
        const data = await response.json();
        setCurrentParts(data || []);
      } catch (error) {
        console.error("Error fetching parts:", error);
        setCurrentParts([]);
      }
    }
  };

  // Handle add part
  const handleAddPart = async () => {
    try {
      const response = await fetch(
        "https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/breakdowns/parts/add",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            breakdown_id: currentBreakdown.id,
            ...newPart,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to add part");
      }

      const data = await response.json();

      if (
        !data.part_id ||
        !data.part_name ||
        !data.part_description ||
        data.quantity === undefined
      ) {
        throw new Error("Invalid part data received from server");
      }

      setCurrentParts((prevParts) => [data, ...prevParts]);

      setNewPart({
        part_name: "",
        part_description: "",
        quantity: 1,
      });
    } catch (error) {
      console.error("Error adding part:", error);
      alert("Failed to add part: " + error.message);
    }
  };

  // Handle delete part
  const handleDeletePart = async (partId) => {
    try {
      await fetch(`https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/breakdowns/parts/delete`, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          part_id: partId,
        }),
      });
      setCurrentParts((prev) => prev.filter((part) => part.part_id !== partId));
    } catch (error) {
      console.error("Error deleting part:", error);
    }
  };

  // Handle update part
  const handleUpdatePart = async () => {
    try {
      const response = await fetch(
        "https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/breakdowns/parts/update",
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            part_id: editingPart.part_id,
            part_name: editingPart.part_name,
            part_description: editingPart.part_description,
            quantity: editingPart.quantity,
          }),
        }
      );

      if (!response.ok) throw new Error("Failed to update part");

      setCurrentParts((prev) =>
        prev.map((part) => (part.part_id === editingPart.part_id ? editingPart : part))
      );
      setEditingPart(null);
    } catch (error) {
      console.error("Error updating part:", error);
    }
  };

  const handleOpenCommentsDialog = async (breakdownId) => {
    const breakdown = breakdowns.find((b) => b.id === breakdownId);
    if (breakdown) {
      setCurrentBreakdown(breakdown);
      setCommentsDialogOpen(true);
      try {
        const response = await fetch(
          `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/breakdowns/comments?breakdown_id=${breakdown.id}`
        );
        if (!response.ok) throw new Error("Failed to fetch comments");
        const data = await response.json();
        setCurrentComments(data || []);
      } catch (error) {
        console.error("Error fetching comments:", error);
        setCurrentComments([]);
      }
    }
  };

  const handleDeleteComment = async (commentId) => {
    try {
      await fetch(
        `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/breakdowns/comments/delete`,
        {
          method: "DELETE",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            comment_id: commentId,
          }),
        }
      );

      setCurrentComments((prev) => prev.filter((comment) => comment.comment_id !== commentId));

      if (currentBreakdown) {
        setBreakdowns((prevBreakdowns) =>
          prevBreakdowns.map((breakdown) =>
            breakdown.id === currentBreakdown.id && breakdown.comment_count > 0
              ? { ...breakdown, comment_count: breakdown.comment_count - 1 }
              : breakdown
          )
        );
      }
    } catch (error) {
      console.error("Error deleting comment:", error);
    }
  };

  const handleEditPart = (part) => {
    setEditingPart(part);
  };
  const handleCancelEdit = () => {
    setEditingPart(null);
  };

  // Columns for the table
  const columns = [
    { name: "Equipment", align: "left" },
    { name: "Status", align: "center" },
    { name: "Priority", align: "center" },
    { name: "Downtime (hours)", align: "center" },
    { name: "Reported By", align: "center" },
    { name: "Reported At", align: "center" },
    { name: "Comments", align: "center" },
    { name: "Pictures", align: "center" },
    { name: "Parts", align: "center" },
    { name: "Actions", align: "center" },
  ];

  // Rows for the table
  const rows = breakdowns.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((breakdown) => {
    const statusColor = {
      reported: "warning",
      in_progress: "info",
      awaiting_parts: "secondary",
      resolved: "success",
      closed: "default",
    }[breakdown.status];

    const priorityColor = {
      low: "success",
      medium: "info",
      high: "warning",
      critical: "error",
    }[breakdown.priority];

    return {
      Equipment: breakdown.equipment_name,
      Status: (
        <Select
          value={breakdown.status}
          onChange={(e) => handleUpdateStatus(breakdown.id, e.target.value)}
          size="small"
          sx={{
            minWidth: 120,
            color: statusColor,
            borderColor: statusColor,
            "& .MuiSelect-icon": { color: statusColor },
          }}
        >
          {statusOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      ),
      Priority: (
        <VuiTypography 
          variant="caption" 
          color={priorityColor} 
          fontWeight="bold"
          textTransform="uppercase"
        >
          {breakdown.priority}
        </VuiTypography>
      ),
      "Downtime (hours)": breakdown.downtime_hours,
      "Reported By": breakdown.reported_by,
      "Reported At": new Date(breakdown.reported_at).toLocaleDateString(),
      Comments: (
        <VuiButton color="info" onClick={() => handleOpenCommentsDialog(breakdown.id)}>
          {breakdown.comment_count || 0} Comments
        </VuiButton>
      ),
      Pictures: (
        <VuiButton color="info" onClick={() => handleOpenPictureDialog(breakdown.id)}>
          {breakdown.photo_count || 0} Photos
        </VuiButton>
      ),
      Parts: (
        <VuiButton color="info" onClick={() => handleOpenPartsDialog(breakdown.id)}>
          {breakdown.part_count || 0} Parts
        </VuiButton>
      ),
      Actions: (
        <>
          <VuiButton 
            color="info" 
            size="small" 
            onClick={() => handleOpenDetailsDialog(breakdown.id)}
          >
            <Icon>visibility</Icon>
          </VuiButton>
          <IconButton color="error" onClick={() => handleDeleteBreakdown(breakdown.id)}>
            <Icon>delete</Icon>
          </IconButton>
        </>
      ),
    };
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <Card>
          <VuiBox display="flex" justifyContent="space-between" alignItems="center" p={2}>
            <VuiTypography variant="lg" color="white">
              Breakdown Reports - {sectionName || siteName}
            </VuiTypography>
            <VuiButton variant="contained" color="primary" onClick={() => setAddDialogOpen(true)}>
              <Icon>add</Icon>&nbsp; Report Breakdown
            </VuiButton>
          </VuiBox>

          {loading ? (
            <VuiBox display="flex" justifyContent="center" py={4}>
              <CircularProgress />
            </VuiBox>
          ) : (
            <Table columns={columns} rows={rows} />
          )}

          {breakdowns.length > 0 && (
            <VuiBox display="flex" justifyContent="center" p={2}>
              <VuiPagination>
                <VuiPagination
                  item
                  onClick={() => setPage(Math.max(1, page - 1))}
                  disabled={page === 1}
                >
                  <Icon>keyboard_arrow_left</Icon>
                </VuiPagination>
                {Array.from(
                  { length: Math.ceil(breakdowns.length / rowsPerPage) },
                  (_, i) => (
                    <VuiPagination
                      key={i + 1}
                      item
                      active={page === i + 1}
                      onClick={() => setPage(i + 1)}
                    >
                      {i + 1}
                    </VuiPagination>
                  )
                )}
                <VuiPagination
                  item
                  onClick={() =>
                    setPage(Math.min(Math.ceil(breakdowns.length / rowsPerPage), page + 1))
                  }
                  disabled={page === Math.ceil(breakdowns.length / rowsPerPage)}
                >
                  <Icon>keyboard_arrow_right</Icon>
                </VuiPagination>
              </VuiPagination>
            </VuiBox>
          )}
        </Card>
      </VuiBox>

      {/* Add Breakdown Dialog */}
      <Dialog open={addDialogOpen} onClose={() => setAddDialogOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>Report New Breakdown</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Equipment Name"
                margin="dense"
                value={newBreakdown.equipment_name}
                onChange={(e) => setNewBreakdown({ ...newBreakdown, equipment_name: e.target.value })}
                required
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField
                fullWidth
                select
                label="Priority"
                margin="dense"
                value={newBreakdown.priority}
                onChange={(e) => setNewBreakdown({ ...newBreakdown, priority: e.target.value })}
              >
                {priorityOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField
                fullWidth
                select
                label="Status"
                margin="dense"
                value={newBreakdown.status}
                onChange={(e) => setNewBreakdown({ ...newBreakdown, status: e.target.value })}
              >
                {statusOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={3}
                label="Breakdown Description"
                margin="dense"
                value={newBreakdown.description}
                onChange={(e) => setNewBreakdown({ ...newBreakdown, description: e.target.value })}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                type="number"
                label="Downtime Hours"
                margin="dense"
                value={newBreakdown.downtime_hours}
                onChange={(e) => setNewBreakdown({ ...newBreakdown, downtime_hours: e.target.value })}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Reported By"
                margin="dense"
                value={newBreakdown.reported_by}
                onChange={(e) => setNewBreakdown({ ...newBreakdown, reported_by: e.target.value })}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddDialogOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleAddBreakdown}
            color="primary"
            disabled={!newBreakdown.equipment_name || !newBreakdown.description}
          >
            Report Breakdown
          </Button>
        </DialogActions>
      </Dialog>

      {/* Breakdown Details Dialog */}
      <Dialog
        open={detailsDialogOpen}
        onClose={() => setDetailsDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Breakdown Details - {currentBreakdown?.equipment_name}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <VuiTypography variant="h6">Description</VuiTypography>
              <VuiTypography variant="body2" color="text">
                {currentBreakdown?.description || "No description provided"}
              </VuiTypography>
            </Grid>
            <Grid item xs={6} md={3}>
              <VuiTypography variant="h6">Status</VuiTypography>
              <VuiTypography variant="body2" color="text">
                {statusOptions.find(s => s.value === currentBreakdown?.status)?.label || "Unknown"}
              </VuiTypography>
            </Grid>
            <Grid item xs={6} md={3}>
              <VuiTypography variant="h6">Priority</VuiTypography>
              <VuiTypography variant="body2" color="text">
                {priorityOptions.find(p => p.value === currentBreakdown?.priority)?.label || "Unknown"}
              </VuiTypography>
            </Grid>
            <Grid item xs={6} md={3}>
              <VuiTypography variant="h6">Downtime Hours</VuiTypography>
              <VuiTypography variant="body2" color="text">
                {currentBreakdown?.downtime_hours || "0"}
              </VuiTypography>
            </Grid>
            <Grid item xs={6} md={3}>
              <VuiTypography variant="h6">Reported By</VuiTypography>
              <VuiTypography variant="body2" color="text">
                {currentBreakdown?.reported_by || "Unknown"}
              </VuiTypography>
            </Grid>
            <Grid item xs={6} md={3}>
              <VuiTypography variant="h6">Reported At</VuiTypography>
              <VuiTypography variant="body2" color="text">
                {currentBreakdown?.reported_at ? new Date(currentBreakdown.reported_at).toLocaleString() : "Unknown"}
              </VuiTypography>
            </Grid>
            <Grid item xs={6} md={3}>
              <VuiTypography variant="h6">Resolved At</VuiTypography>
              <VuiTypography variant="body2" color="text">
                {currentBreakdown?.resolved_at ? new Date(currentBreakdown.resolved_at).toLocaleString() : "Not resolved"}
              </VuiTypography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDetailsDialogOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Photos Dialog */}
      <Dialog
        open={pictureDialogOpen}
        onClose={() => setPictureDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>{currentBreakdown?.equipment_name} Photos</DialogTitle>
        <DialogContent>
          {currentPhotos.length > 0 ? (
            <ImageList>
              {currentPhotos.map((photo) => (
                <ImageListItem key={photo.photo_id}>
                  <img src={photo.url} alt={photo.file_name} />
                  <IconButton
                    color="error"
                    onClick={() => handleDeletePhoto(photo.photo_id)}
                    sx={{ position: "absolute", top: 5, right: 5 }}
                  >
                    <Icon>delete</Icon>
                  </IconButton>
                </ImageListItem>
              ))}
            </ImageList>
          ) : (
            <VuiBox textAlign="center" py={4}>
              <VuiTypography variant="body2" color="text">
                No photos available for this breakdown.
              </VuiTypography>
            </VuiBox>
          )}
          <VuiBox mt={3}>
            <Button
              variant="contained"
              component="label"
              fullWidth
              color="primary"
              disabled={uploadingPhoto}
              startIcon={<Icon>add_a_photo</Icon>}
            >
              {uploadingPhoto ? "Uploading..." : "Upload Photo"}
              <input
                type="file"
                hidden
                onChange={handleUploadPhoto}
                accept="image/*"
                disabled={uploadingPhoto}
              />
            </Button>
          </VuiBox>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPictureDialogOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Parts Dialog */}
      <Dialog
        open={partsDialogOpen}
        onClose={() => setPartsDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>{currentBreakdown?.equipment_name} Parts</DialogTitle>
        <DialogContent>
          {currentParts.length > 0 ? (
            <List>
              {currentParts.map((part) => (
                <ListItem key={part.part_id}>
                  {editingPart?.part_id === part.part_id ? (
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12} md={4}>
                        <TextField
                          fullWidth
                          label="Part Name"
                          value={editingPart.part_name}
                          onChange={(e) =>
                            setEditingPart({ ...editingPart, part_name: e.target.value })
                          }
                          required
                          margin="dense"
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          fullWidth
                          label="Part Description"
                          value={editingPart.part_description}
                          onChange={(e) =>
                            setEditingPart({ ...editingPart, part_description: e.target.value })
                          }
                          margin="dense"
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <TextField
                          fullWidth
                          type="number"
                          label="Quantity"
                          value={editingPart.quantity}
                          onChange={(e) =>
                            setEditingPart({ ...editingPart, quantity: e.target.value })
                          }
                          required
                          margin="dense"
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleUpdatePart}
                          startIcon={<Icon>save</Icon>}
                        >
                          Save
                        </Button>
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={handleCancelEdit}
                          startIcon={<Icon>cancel</Icon>}
                          sx={{ mt: 1 }}
                        >
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  ) : (
                    <>
                      <ListItemText
                        primary={part.part_name}
                        secondary={`Quantity: ${part.quantity} - ${part.part_description}`}
                      />
                      <IconButton
                        onClick={() => handleEditPart(part)}
                        color="primary"
                        size="small"
                        sx={{ mr: 1 }}
                      >
                        <Icon>edit</Icon>
                      </IconButton>
                      <IconButton
                        onClick={() => handleDeletePart(part.part_id)}
                        color="error"
                        size="small"
                      >
                        <Icon>delete</Icon>
                      </IconButton>
                    </>
                  )}
                </ListItem>
              ))}
            </List>
          ) : (
            <VuiBox textAlign="center" py={4}>
              <VuiTypography variant="body2" color="text">
                No parts available for this breakdown.
              </VuiTypography>
            </VuiBox>
          )}
          <VuiBox mt={3}>
            <TextField
              fullWidth
              label="Part Name"
              margin="dense"
              value={newPart.part_name}
              onChange={(e) => setNewPart({ ...newPart, part_name: e.target.value })}
            />
            <TextField
              fullWidth
              label="Part Description"
              margin="dense"
              value={newPart.part_description}
              onChange={(e) => setNewPart({ ...newPart, part_description: e.target.value })}
            />
            <TextField
              fullWidth
              type="number"
              label="Quantity"
              margin="dense"
              value={newPart.quantity}
              onChange={(e) => setNewPart({ ...newPart, quantity: e.target.value })}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddPart}
              fullWidth
              sx={{ mt: 2 }}
            >
              Add Part
            </Button>
          </VuiBox>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPartsDialogOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Comments Dialog */}
      <Dialog
        open={commentsDialogOpen}
        onClose={() => setCommentsDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Breakdown Comments</DialogTitle>
        <DialogContent>
          <List>
            {currentComments.map((comment) => (
              <ListItem key={comment.comment_id}>
                <ListItemText
                  primary={comment.comment_text}
                  secondary={`At ${new Date(comment.created_at).toLocaleString()} By ${comment.created_by}`}
                />
                <IconButton
                  onClick={() => handleDeleteComment(comment.comment_id)}
                  color="error"
                  size="small"
                >
                  <Icon>delete</Icon>
                </IconButton>
              </ListItem>
            ))}
          </List>
          <TextField
            fullWidth
            multiline
            rows={2}
            variant="outlined"
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder="Add new comment..."
          />
          <Button variant="contained" color="primary" onClick={handleAddComment} sx={{ mt: 2 }}>
            Add Comment
          </Button>
        </DialogContent>
      </Dialog>

      <Footer />
      </DashboardLayout>
     );
   }
   
   export default BreakdownTracker;
   