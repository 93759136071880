import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CircularProgress } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import {
  ExclamationCircleOutlined,
  ClockCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";

const PpmSummary = ({ siteId, isIncluded }) => {
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState({ overdue: 0, upcoming: 0, completed: 0 });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTaskStats = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/sections?site_id=${siteId}`
        );
        if (!response.ok) throw new Error("Failed to fetch tasks");
        const sections = await response.json();
        const totals = sections.reduce(
          (acc, section) => ({
            overdue: acc.overdue + (section.overdue_tasks || 0),
            upcoming:
              acc.upcoming +
              ((section.total_tasks || 0) -
                (section.completed_tasks || 0) -
                (section.overdue_tasks || 0)),
            completed: acc.completed + (section.completed_tasks || 0),
          }),
          { overdue: 0, upcoming: 0, completed: 0 }
        );
        setStats(totals);
      } catch (error) {
        console.error("Error fetching task stats:", error);
      } finally {
        setLoading(false);
      }
    };

    if (siteId && isIncluded) {
      fetchTaskStats();
    }
  }, [siteId, isIncluded]);

  const handleCardClick = () => {
    if (isIncluded) {
      navigate(`ppm/section-list/${siteId}`);
    }
  };

  // Lighter grey colors for disabled state
  const disabledColor = "#B0B0B0"; // Light grey
  const disabledBgColor = "rgba(176, 176, 176, 0.15)"; // Very light grey with low opacity

  // Define colors based on whether PPM Summary is included
  const statusData = [
    {
      type: "overdue",
      label: "Overdue Tasks",
      color: isIncluded ? "#FF3333" : disabledColor,
      bgColor: isIncluded ? "rgba(255, 51, 51, 0.25)" : disabledBgColor,
      icon: <ExclamationCircleOutlined />,
    },
    {
      type: "upcoming",
      label: "Upcoming Tasks",
      color: isIncluded ? "#FFD700" : disabledColor,
      bgColor: isIncluded ? "rgba(255, 215, 0, 0.25)" : disabledBgColor,
      icon: <ClockCircleOutlined />,
    },
    {
      type: "completed",
      label: "Completed Tasks",
      color: isIncluded ? "#00FF7F" : disabledColor,
      bgColor: isIncluded ? "rgba(0, 255, 127, 0.25)" : disabledBgColor,
      icon: <CheckCircleOutlined />,
    },
  ];

  return (
    <Card
      sx={{
        height: "100%",
        padding: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        transition: "all 0.3s",
        cursor: isIncluded ? "pointer" : "default",
        opacity: isIncluded ? 1 : 0.9,
        filter: isIncluded ? "none" : "grayscale(30%)",
        backgroundColor: isIncluded ? "transparent" : "rgba(245, 245, 245, 0.1)",
        '&:hover': {
          transform: isIncluded ? "translateY(-2px)" : "none",
          boxShadow: isIncluded ? "0 4px 20px rgba(0, 0, 0, 0.15)" : "none",
        }
      }}
      onClick={handleCardClick}
    >
      <VuiBox display="flex" flexDirection="column" p={2} mb={-1}>
        <VuiTypography 
          variant="lg" 
          color={isIncluded ? "white" : disabledColor} 
          fontWeight="bold"
          sx={{ opacity: isIncluded ? 1 : 0.8 }}
        >
          PPM Summary
        </VuiTypography>
      </VuiBox>

      <VuiBox
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        flexGrow={1}
      >
        {loading && isIncluded ? (
          <CircularProgress color="info" />
        ) : (
          <VuiBox display="flex" justifyContent="center" gap={3} width="100%">
            {statusData.map(({ type, label, color, bgColor, icon }) => (
              <VuiBox key={type} textAlign="center">
                {/* Icon */}
                <VuiBox
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "50px",
                    height: "50px",
                    borderRadius: "50%",
                    backgroundColor: bgColor,
                    opacity: isIncluded ? 1 : 0.7,
                  }}
                >
                  <VuiTypography variant="h4" color={color}>
                    {icon}
                  </VuiTypography>
                </VuiBox>
                
                <VuiTypography 
                  variant="h6" 
                  color={isIncluded ? "white" : disabledColor} 
                  mt={3}
                  sx={{ opacity: isIncluded ? 1 : 0.8 }}
                >
                  {label}
                </VuiTypography>
                
                <VuiTypography 
                  variant="h3" 
                  fontWeight="bold" 
                  color={color} 
                  mt={3}
                  sx={{ opacity: isIncluded ? 1 : 0.8 }}
                >
                  {isIncluded ? stats[type] : "-"}
                </VuiTypography>
              </VuiBox>
            ))}
          </VuiBox>
        )}
      </VuiBox>

      <VuiBox width="100%"></VuiBox>
    </Card>
  );
};

export default PpmSummary;