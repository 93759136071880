import React, { useState, useEffect } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import axios from "axios";
import theme from "assets/theme";
import routes from "routes";
import { useVisionUIController } from "context";
import Configurator from "examples/Configurator";
import SiteDashboard from "layouts/site-dashboard";
import DataExport from "layouts/data-export";
import PPM from "./layouts/PPM";
import EngineerPage from "./layouts/PPM/EngineerPage";
import UserManagement from "./layouts/admin/UserManagement";
import CompanyManagement from "./layouts/admin/CompanyManagement";
import SiteManagement from "./layouts/admin/SiteManagement";
import GatewayManagement from "./layouts/admin/GatewayManagement";
import ProtectedRoute from "./ProtectedRoute";
import SectionListPage from "./layouts/PPM/SectionListPage";

export default function App() {
  const [controller] = useVisionUIController();
  const { direction, layout } = controller;
  const { pathname } = useLocation();

  const [sites, setSites] = useState([]);
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("user_id");

  useEffect(() => {
    if (token && userId) {
      const fetchUserSites = async () => {
        try {
          const response = await axios.get(
            "https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/user/sites",
            {
              headers: { Authorization: token, "Content-Type": "application/json" },
              params: { user_id: userId },
            }
          );
          setSites(response.data);
        } catch (error) {
          console.error("Error fetching sites:", error.message);
        }
      };

      fetchUserSites();
    }
  }, [token, userId]);

  const defaultSiteId = sites.length > 0 ? sites[0].site_id : null;

  useEffect(() => {
    if (defaultSiteId && pathname === "/site-dashboard") {
      window.location.href = `/site-dashboard/${defaultSiteId}`;
    }
  }, [defaultSiteId, pathname]);

  useEffect(() => {
    if (defaultSiteId && pathname === "/data-export") {
      window.location.href = `/data-export/${defaultSiteId}`;
    }
  }, [defaultSiteId, pathname]);

  useEffect(() => {
    if (defaultSiteId && pathname === "/ppm") {
      window.location.href = `/ppm/${defaultSiteId}`;
    }
  }, [defaultSiteId, pathname]);

  useEffect(() => {
    if (defaultSiteId && pathname === "ppm/section-list") {
      window.location.href = `ppm/section-list/${defaultSiteId}`;
    }
  }, [defaultSiteId, pathname]);

  useEffect(() => {
    if (defaultSiteId && pathname === "/engineer-page") {
      window.location.href = `/engineer-page/${defaultSiteId}`;
    }
  }, [defaultSiteId, pathname]);

  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (routes) => {
    return routes.map((route) => {
      if (route.type === "collapse") {
        return (
          <Route 
            key={route.key} 
            path={route.route} 
            element={
              <ProtectedRoute>
                <route.component />
              </ProtectedRoute>
            } 
          />
        );
      }
      return null;
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {layout === "site-dashboard" && <Configurator />}
      {layout === "vr" && <Configurator />}
      <Routes>
        {getRoutes(routes)}
        <Route 
          path="/data-export/:site_id" 
          element={
            <ProtectedRoute allowedRoles={[1, 2, 3]}>
              <DataExport />
            </ProtectedRoute>
          } 
        />
        <Route
          path="/data-export"
          element={defaultSiteId ? <Navigate to={`/data-export/${defaultSiteId}`} /> : null}
        />
        
        <Route 
          path="/ppm/:site_id/:section_id" 
          element={
            <ProtectedRoute allowedRoles={[1, 2, 3, 4]}>
              <PPM />
            </ProtectedRoute>
          } 
        />
        <Route
          path="/ppm"
          element={defaultSiteId ? <Navigate to={`/ppm/${defaultSiteId}`} /> : null}
        />
        
        <Route 
          path="/engineer-page/:site_id" 
          element={
            <ProtectedRoute allowedRoles={[3, 4]}>
              <EngineerPage />
            </ProtectedRoute>
          } 
        />
        <Route
          path="/engineer-page"
          element={defaultSiteId ? <Navigate to={`/engineer-page/${defaultSiteId}`} /> : null}
        />
        
        <Route 
          path="/site-dashboard/:site_id" 
          element={
            <ProtectedRoute allowedRoles={[1, 2, 3]}>
              <SiteDashboard />
            </ProtectedRoute>
          } 
        />
        <Route
          path="/site-dashboard"
          element={defaultSiteId ? <Navigate to={`/site-dashboard/${defaultSiteId}`} /> : null}
        />
        
        <Route 
          path="/ppm/section-list/:site_id" 
          element={
            <ProtectedRoute allowedRoles={[1, 2, 3, 4]}>
              <SectionListPage />
            </ProtectedRoute>
          } 
        />
        <Route
          path="/ppm/section-list"
          element={defaultSiteId ? <Navigate to={`/ppm/section-list/${defaultSiteId}`} /> : null}
        />

        {/* Admin Routes */}
        <Route
          path="/admin/users"
          element={
            <ProtectedRoute allowedRoles={[3]}>
              <UserManagement />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/companies"
          element={
            <ProtectedRoute allowedRoles={[3]}>
              <CompanyManagement />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/sites"
          element={
            <ProtectedRoute allowedRoles={[3]}>
              <SiteManagement />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/gateways"
          element={
            <ProtectedRoute allowedRoles={[3]}>
              <GatewayManagement />
            </ProtectedRoute>
          }
        />
        
        <Route path="*" element={<Navigate to="/site-dashboard" />} />
      </Routes>
    </ThemeProvider>
  );
}