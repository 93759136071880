import React, { useState, useEffect } from "react";
import {
  Card,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Drawer,
  Alert,
  Snackbar,
  Box,
  Chip,
  Autocomplete,
} from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";
import VuiTypography from "components/VuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Table from "examples/Tables/Table";
import VuiPagination from "components/VuiPagination";
import Icon from "@mui/material/Icon";

function UserManagement() {
  const [userData, setUserData] = useState([]);
  const [roles, setRoles] = useState([]); // New state for roles
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(12);
  const [addUserOpen, setAddUserOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });
  const [userSites, setUserSites] = useState({});
  const [userCompanies, setUserCompanies] = useState({});
  const [availableSites, setAvailableSites] = useState([]);
  const [selectedSites, setSelectedSites] = useState([]);
  const [newUserData, setNewUserData] = useState({
    email: "",
    first_name: "",
    last_name: "",
    password: "",
    role_id: "",
  });
  const token = localStorage.getItem("token");

  // Calculate pagination variables
  const totalPages = Math.ceil(userData.length / rowsPerPage);
  const displayedRows = userData.slice((page - 1) * rowsPerPage, page * rowsPerPage);

  // Table columns definition
  const columns = [
    { name: "User ID", align: "left" },
    { name: "Name", align: "left" },
    { name: "Email", align: "left" },
    { name: "Role", align: "center" },
    { name: "Sites", align: "left" },
    { name: "Status", align: "center" },
    { name: "Actions", align: "center" },
  ];

  useEffect(() => {
    fetchRoles(); // Fetch roles first
    fetchUsers();
  }, []);

  const fetchRoles = async () => {
    try {
      const response = await fetch(
        "https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/user/roles",
        {
          method: "GET",
          headers: { Authorization: `${token}` },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      // Ensure data is an array before setting it
      if (Array.isArray(data)) {
        setRoles(data);

        // Set default role_id to the first role if available
        if (data.length > 0) {
          setNewUserData((prev) => ({ ...prev, role_id: data[0].role_id }));
        }
      } else {
        console.error("Roles data is not an array:", data);
        showSnackbar("Error: Invalid roles data format", "error");
      }
    } catch (error) {
      console.error("Error fetching roles:", error);
      showSnackbar("Error fetching roles", "error");
    }
  };

  const getRoleName = (roleId) => {
    if (!Array.isArray(roles)) return "Unknown Role";

    const role = roles.find((r) => r.role_id === roleId);
    return role ? role.role_name : "Unknown Role";
  };

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        "https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/admin/users",
        {
          method: "GET",
          headers: { Authorization: `${token}` },
        }
      );
      const data = await response.json();

      const formattedData = data.map((user) => ({
        id: user[0],
        email: user[1],
        role_id: user[2],
        first_name: user[3],
        last_name: user[4],
        status: "Active",
      }));

      setUserData(formattedData);

      // Fetch sites and companies for each user
      for (const user of formattedData) {
        await fetchUserSites(user.id);
        await fetchUserCompanies(user.id);
      }
    } catch (error) {
      showSnackbar("Error fetching users", "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUserSites = async (userId) => {
    try {
      const response = await fetch(
        `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/user/sites?user_id=${userId}`,
        {
          method: "GET",
          headers: { Authorization: `${token}` },
        }
      );
      const data = await response.json();
      setUserSites((prev) => ({ ...prev, [userId]: data }));
    } catch (error) {
      console.error("Error fetching user sites:", error);
    }
  };

  const fetchUserCompanies = async (userId) => {
    try {
      const response = await fetch(
        `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/user/companies?user_id=${userId}`,
        {
          method: "GET",
          headers: { Authorization: `${token}` },
        }
      );
      const data = await response.json();
      setUserCompanies((prev) => ({ ...prev, [userId]: data }));

      // Fetch sites for each company
      for (const company of data) {
        await fetchCompanySites(company.company_id);
      }
    } catch (error) {
      console.error("Error fetching user companies:", error);
    }
  };

  const fetchCompanySites = async (companyId) => {
    try {
      const response = await fetch(
        `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/company/sites?company_id=${companyId}`,
        {
          method: "GET",
          headers: { Authorization: `${token}` },
        }
      );
      const data = await response.json();
      setAvailableSites((prev) => {
        const uniqueSites = new Map();
        [...prev, ...data].forEach((site) => uniqueSites.set(site.site_id, site));
        return Array.from(uniqueSites.values());
      });
    } catch (error) {
      console.error("Error fetching company sites:", error);
    }
  };

  const handleEditClick = async (user) => {
    setSelectedUser({
      id: user.id,
      email: user.email,
      first_name: user.first_name,
      last_name: user.last_name,
      role_id: user.role_id,
    });

    try {
      const response = await fetch(
        `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/user/sites?user_id=${user.id}`,
        {
          method: "GET",
          headers: { Authorization: `${token}` },
        }
      );
      const sites = await response.json();
      setSelectedSites(sites || []);
    } catch (error) {
      console.error("Error fetching user sites:", error);
    }

    setDrawerOpen(true);
  };

  const handleDeleteClick = (user) => {
    setSelectedUser({
      id: user.id,
      first_name: user.first_name,
      last_name: user.last_name,
    });
    setDeleteConfirmOpen(true);
  };

  const handleUpdateUserSites = async () => {
    try {
      const originalSites = userSites[selectedUser.id] || [];
      const originalSiteIds = originalSites.map((site) => site.site_id);
      const newSiteIds = selectedSites.map((site) => site.site_id);

      const addSites = newSiteIds.filter((id) => !originalSiteIds.includes(id));
      const removeSites = originalSiteIds.filter((id) => !newSiteIds.includes(id));

      if (addSites.length === 0 && removeSites.length === 0) {
        showSnackbar("No sites selected to update", "warning");
        return;
      }

      const response = await fetch(
        `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/admin/usersites/update`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify({
            user_id: selectedUser.id,
            add_sites: addSites,
            remove_sites: removeSites,
          }),
        }
      );

      const responseData = await response.json();
      console.log("API Response:", responseData); // Log API response

      if (response.ok && responseData.message) {
        showSnackbar("User sites updated successfully", "success");
        await fetchUserSites(selectedUser.id); // Re-fetch updated sites after the update
      } else {
        showSnackbar(responseData.message || "Failed to update user sites", "error");
      }
    } catch (error) {
      console.error("Error updating user sites:", error);
      showSnackbar("Error updating user sites", "error");
    }
  };
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };
  const handleAddUser = async () => {
    if (!validateEmail(newUserData.email)) {
      showSnackbar("Please enter a valid email address", "error");
      return;
    }
    try {
      const userToAdd = {
        ...newUserData,
        email: newUserData.email.toLowerCase(), // Convert to lowercase before sending
      };
  
      const response = await fetch(
        "https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/user/create",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify(userToAdd),
        }
      );
  
      if (response.ok) {
        showSnackbar("User added successfully", "success");
        setAddUserOpen(false);
        setNewUserData({
          email: "",
          first_name: "",
          last_name: "",
          password: "",
          role_id: "",
        });
        await fetchUsers();
      } else {
        showSnackbar("Failed to add user", "error");
      }
    } catch (error) {
      showSnackbar("Error adding user", "error");
    }
  };
  const showSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  const handleUpdateUser = async () => {
    try {
      // Create payload with lowercase email if it's being updated
      const payload = {
        id: selectedUser.id,
        first_name: selectedUser.first_name,
        last_name: selectedUser.last_name,
        role_id: selectedUser.role_id,
        ...(selectedUser.email && { 
          new_email: selectedUser.email.toLowerCase() // Convert to lowercase and use new_email field
        })
      };

      const response = await fetch(
        `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/user/update/profile`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        showSnackbar("User updated successfully", "success");
        setDrawerOpen(false);
        await fetchUsers();
      } else {
        const errorData = await response.json();
        showSnackbar(errorData.error || "Failed to update user", "error");
      }
    } catch (error) {
      showSnackbar("Error updating user", "error");
    }
  };

  const handleDeleteUser = async () => {
    try {
      const response = await fetch(
        `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/admin/users/delete?user_id=${selectedUser.id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `${token}`,
          },
        }
      );

      if (response.ok) {
        showSnackbar("User deleted successfully", "success");
        setDeleteConfirmOpen(false);
        await fetchUsers();
      } else {
        showSnackbar("Failed to delete user", "error");
      }
    } catch (error) {
      showSnackbar("Error deleting user", "error");
    }
  };

  const rows = displayedRows.map((user) => ({
    "User ID": user.id,
    Name: `${user.first_name} ${user.last_name}`,
    Email: user.email,
    Role: getRoleName(user.role_id), // Updated to use dynamic role names
    Sites: (
      <VuiBox display="flex" gap={1} flexWrap="wrap">
        {userSites[user.id]?.map((site) => (
          <Chip key={site.site_id} label={site.name} size="small" color="primary" />
        ))}
      </VuiBox>
    ),
    Status: (
      <Box
        sx={{
          backgroundColor: "success.main",
          borderRadius: "8px",
          px: 2,
          py: 0.5,
          display: "inline-block",
        }}
      >
        <VuiTypography color="white" variant="button">
          {user.status}
        </VuiTypography>
      </Box>
    ),
    Actions: (
      <VuiBox display="flex" gap={1} justifyContent="flex-end">
        <VuiButton
          color="info"
          size="small"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleEditClick(user);
          }}
        >
          <Icon>edit</Icon>
        </VuiButton>
        <VuiButton
          color="error"
          size="small"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleDeleteClick(user);
          }}
        >
          <Icon>delete</Icon>
        </VuiButton>
      </VuiBox>
    ),
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <Card>
          <VuiBox display="flex" justifyContent="space-between" alignItems="center" p={2}>
            <VuiTypography variant="lg" color="white">
              User Management
            </VuiTypography>
            <VuiButton color="info" onClick={() => setAddUserOpen(true)}>
              Add User
            </VuiButton>
          </VuiBox>

          {loading ? (
            <VuiBox display="flex" justifyContent="center" py={4}>
              <CircularProgress />
            </VuiBox>
          ) : (
            <Table columns={columns} rows={rows} />
          )}

          <VuiBox display="flex" justifyContent="center" p={2}>
            <VuiPagination>
              <VuiPagination item onClick={() => setPage(page - 1)} disabled={page === 1}>
                <Icon>keyboard_arrow_left</Icon>
              </VuiPagination>
              {Array.from({ length: totalPages }, (_, index) => (
                <VuiPagination
                  key={index + 1}
                  item
                  active={page === index + 1}
                  onClick={() => setPage(index + 1)}
                >
                  {index + 1}
                </VuiPagination>
              ))}
              <VuiPagination item onClick={() => setPage(page + 1)} disabled={page === totalPages}>
                <Icon>keyboard_arrow_right</Icon>
              </VuiPagination>
            </VuiPagination>
          </VuiBox>
        </Card>
      </VuiBox>

      {/* Add User Dialog */}
      <Dialog open={addUserOpen} onClose={() => setAddUserOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Add New User</DialogTitle>
        <DialogContent>
          <VuiBox component="form" display="flex" flexDirection="column" gap={2} p={2}>
            <TextField
              label="Email"
              value={newUserData.email}
              onChange={(e) => setNewUserData({ ...newUserData, email: e.target.value })}
              fullWidth
            />
            <TextField
              label="First Name"
              value={newUserData.first_name}
              onChange={(e) => setNewUserData({ ...newUserData, first_name: e.target.value })}
              fullWidth
            />
            <TextField
              label="Last Name"
              value={newUserData.last_name}
              onChange={(e) => setNewUserData({ ...newUserData, last_name: e.target.value })}
              fullWidth
            />
            <TextField
              label="Password"
              type="password"
              value={newUserData.password}
              onChange={(e) => setNewUserData({ ...newUserData, password: e.target.value })}
              fullWidth
            />
            <FormControl fullWidth>
              <InputLabel>Role</InputLabel>
              <Select
                value={newUserData.role_id}
                onChange={(e) => setNewUserData({ ...newUserData, role_id: e.target.value })}
              >
                {roles.map((role) => (
                  <MenuItem key={role.role_id} value={role.role_id}>
                    {role.role_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </VuiBox>
        </DialogContent>
        <DialogActions>
          <VuiButton color="error" onClick={() => setAddUserOpen(false)}>
            Cancel
          </VuiButton>
          <VuiButton color="info" onClick={handleAddUser}>
            Add User
          </VuiButton>
        </DialogActions>
      </Dialog>

      {/* Updated Edit User Drawer */}
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => {
          setDrawerOpen(false);
          setSelectedUser(null);
          setSelectedSites([]);
        }}
        PaperProps={{
          sx: { width: "400px" },
        }}
      >
        {selectedUser && (
          <VuiBox p={3}>
            <VuiTypography variant="h6" mb={3}>
              Edit User
            </VuiTypography>
            <VuiBox display="flex" flexDirection="column" gap={2}>
              <TextField
                label="Email"
                value={selectedUser.email}
                onChange={(e) => setSelectedUser({ ...selectedUser, email: e.target.value })}
                fullWidth
              />
              <TextField
                label="First Name"
                value={selectedUser.first_name}
                onChange={(e) => setSelectedUser({ ...selectedUser, first_name: e.target.value })}
                fullWidth
              />
              <TextField
                label="Last Name"
                value={selectedUser.last_name}
                onChange={(e) => setSelectedUser({ ...selectedUser, last_name: e.target.value })}
                fullWidth
              />
              <FormControl fullWidth>
                <InputLabel>Role</InputLabel>
                <Select
                  value={selectedUser.role_id}
                  onChange={(e) => setSelectedUser({ ...selectedUser, role_id: e.target.value })}
                >
                  {roles.map((role) => (
                    <MenuItem key={role.role_id} value={role.role_id}>
                      {role.role_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Autocomplete
                multiple
                value={selectedSites}
                onChange={(event, newValue) => setSelectedSites(newValue)}
                options={availableSites}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField {...params} label="Assigned Sites" placeholder="Select sites" />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip label={option.name} {...getTagProps({ index })} key={option.site_id} />
                  ))
                }
              />
              <VuiBox display="flex" gap={2} mt={2}>
                <VuiButton
                  color="error"
                  fullWidth
                  onClick={() => {
                    setDrawerOpen(false);
                    setSelectedUser(null);
                    setSelectedSites([]);
                  }}
                >
                  Cancel
                </VuiButton>
                <VuiButton
                  color="info"
                  fullWidth
                  onClick={() => {
                    handleUpdateUser();
                    handleUpdateUserSites();
                  }}
                >
                  Save Changes
                </VuiButton>
              </VuiBox>
            </VuiBox>
          </VuiBox>
        )}
      </Drawer>

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteConfirmOpen} onClose={() => setDeleteConfirmOpen(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <VuiTypography>
            Are you sure you want to delete user {selectedUser?.first_name}{" "}
            {selectedUser?.last_name}?
          </VuiTypography>
        </DialogContent>
        <DialogActions>
          <VuiButton color="info" onClick={() => setDeleteConfirmOpen(false)}>
            Cancel
          </VuiButton>
          <VuiButton color="error" onClick={handleDeleteUser}>
            Delete
          </VuiButton>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          severity={snackbar.severity}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </DashboardLayout>
  );
}

export default UserManagement;
