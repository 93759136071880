import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import VuiTypography from "components/VuiTypography";

const CircularProgressWithCircle = ({ progress, title, value, color, size }) => {
  const filledSize = size - 20; // Adjust the size of the filled inner circle based on the outer size
  const filledColor = color; // Color for the filled circle
  const fontSize = Math.max(size / 8, 12); // Minimum font size is 12px

  // Function to format the value with appropriate units
  const formatValueWithUnits = (title, val) => {
    switch (title) {
      case 'Electricity':
        return val < 9999 ? `${val} KW` : `${(val / 1000).toFixed(1)} MW`;
      case 'Fuel':
        return val < 9999 ? `${Math.round(val)} L` : `${(val / 1000).toFixed(1)} kL`;
        case 'Unused Agg.':
        return `${parseFloat(val).toFixed(2)} t`;
        default:
        return val;
    }
  };

  return (
    <Box
      position="relative"
      display="inline-flex"
      justifyContent="center"
      alignItems="center"
      width={size}
      height={size}
    >
      {/* Filled Circle */}
      <Box
        sx={{
          position: 'absolute',
          borderRadius: '50%',
          backgroundColor: filledColor,
          width: filledSize,
          height: filledSize,
        }}
      />
      {/* Circular Progress (around the filled circle) */}
      <CircularProgress
        variant="determinate"
        value={progress}
        size={size}
        thickness={1} // Set thickness of the progress bar to 3
        sx={{
          color: color,
          position: 'absolute',
        }}
      />
      {/* Title and Percentage Text */}
      <VuiTypography
        variant="body2"
        component="div"
        color="white"
        sx={{
          position: 'absolute',
          textAlign: 'center',
          fontSize: fontSize,
        }}
      >
        {title}
        <br />
        {formatValueWithUnits(title, value)}
      </VuiTypography>
    </Box>
  );
};

export default CircularProgressWithCircle;