import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  CircularProgress,
  Chip,
  IconButton,
} from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";
import VuiTypography from "components/VuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import VuiPagination from "components/VuiPagination";
import Icon from "@mui/material/Icon";
import { Edit, Delete } from "@mui/icons-material";

function SectionListPage() {
  const { site_id } = useParams();
  const navigate = useNavigate();
  const [sections, setSections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentSection, setCurrentSection] = useState(null);
  const [sectionName, setSectionName] = useState("");

  // Get user role from localStorage
  const roleId = parseInt(localStorage.getItem("role_id"));

  const fetchSections = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/sections?site_id=${site_id}`
      );
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to fetch sections");
      }
      const data = await response.json();
      setSections(data);
    } catch (error) {
      console.error("Error fetching sections:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchSections();
  }, [site_id]);

  const handleSectionClick = (sectionId) => {
    // Check if user is an engineer (role_id = 4)
    if (roleId === 4) {
      navigate(`/engineer-page/${site_id}/${sectionId}`);
    } else {
      // Default behavior for other roles
      navigate(`/ppm/${site_id}/${sectionId}`);
    }
  };

  // ... rest of the component remains the same ...
  const handleOpenDialog = (section = null) => {
    setCurrentSection(section);
    setSectionName(section?.section_name || "");
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCurrentSection(null);
    setSectionName("");
  };

  const handleSaveSection = async () => {
    try {
      const url = currentSection
        ? `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/sections/update`
        : `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/sections/create`;

      const method = currentSection ? "PUT" : "POST";

      const body = currentSection
        ? { section_id: currentSection.section_id, section_name: sectionName }
        : { section_name: sectionName, site_id };

      const response = await fetch(url, {
        method,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      });

      if (!response.ok) throw new Error("Failed to save section");

      fetchSections();
      handleCloseDialog();
    } catch (error) {
      console.error("Error saving section:", error);
    }
  };

  const handleDeleteSection = async (sectionId) => {
    if (window.confirm("Are you sure you want to delete this section?")) {
      try {
        const response = await fetch(
          `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/sections/delete?section_id=${sectionId}`,
          {
            method: "DELETE",
            headers: { "Content-Type": "application/json" },
          }
        );

        if (!response.ok) throw new Error("Failed to delete section");

        fetchSections();
      } catch (error) {
        console.error("Error deleting section:", error);
      }
    }
  };

  // Columns for the table
  const columns = [
    { name: "Section Name", align: "left" },
    { name: "Total Tasks", align: "center" },
    { name: "Completed Tasks", align: "center" },
    { name: "Overdue Tasks", align: "center" },
    { name: "Completion", align: "center" },
    { name: "Actions", align: "center" },
  ];

  // Rows for the table - modified to make section name clickable
  const rows = sections.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((section) => {
    const completionPercentage =
      section.total_tasks > 0
        ? Math.round((section.completed_tasks / section.total_tasks) * 100)
        : 0;

    return {
      "Section Name": (
        <Button
          onClick={() => handleSectionClick(section.section_id)}
          style={{ textTransform: "none", color: "inherit", textAlign: "left" }}
        >
          {section.section_name}
        </Button>
      ),
      "Total Tasks": section.total_tasks || 0,
      "Completed Tasks": section.completed_tasks || 0,
      "Overdue Tasks": (
        <Chip
          label={section.overdue_tasks || 0}
          color={section.overdue_tasks > 0 ? "error" : "default"}
          size="small"
        />
      ),
      Completion: `${completionPercentage}%`,
      Actions: (
        <>
          <IconButton color="info" onClick={() => handleOpenDialog(section)}>
            <Edit />
          </IconButton>
          <IconButton color="error" onClick={() => handleDeleteSection(section.section_id)}>
            <Delete />
          </IconButton>
        </>
      ),
    };
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <Card>
          <VuiBox display="flex" justifyContent="space-between" alignItems="center" p={2}>
            <VuiTypography variant="lg" color="white">
              Sections
            </VuiTypography>
            <VuiButton variant="contained" color="primary" onClick={() => handleOpenDialog()}>
              <Icon>add</Icon>&nbsp; Add Section
            </VuiButton>
          </VuiBox>

          {loading ? (
            <VuiBox display="flex" justifyContent="center" py={4}>
              <CircularProgress />
            </VuiBox>
          ) : (
            <Table columns={columns} rows={rows} />
          )}

          {sections.length > 0 && (
            <VuiBox display="flex" justifyContent="center" p={2}>
              <VuiPagination>
                <VuiPagination
                  item
                  onClick={() => setPage(Math.max(1, page - 1))}
                  disabled={page === 1}
                >
                  <Icon>keyboard_arrow_left</Icon>
                </VuiPagination>
                {Array.from({ length: Math.ceil(sections.length / rowsPerPage) }, (_, i) => (
                  <VuiPagination
                    key={i + 1}
                    item
                    active={page === i + 1}
                    onClick={() => setPage(i + 1)}
                  >
                    {i + 1}
                  </VuiPagination>
                ))}
                <VuiPagination
                  item
                  onClick={() =>
                    setPage(Math.min(Math.ceil(sections.length / rowsPerPage), page + 1))
                  }
                  disabled={page === Math.ceil(sections.length / rowsPerPage)}
                >
                  <Icon>keyboard_arrow_right</Icon>
                </VuiPagination>
              </VuiPagination>
            </VuiBox>
          )}
        </Card>
      </VuiBox>

      {/* Add/Edit Section Dialog */}
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        <DialogTitle>{currentSection ? "Edit Section" : "Add New Section"}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Section Name"
            fullWidth
            value={sectionName}
            onChange={(e) => setSectionName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleSaveSection} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Footer />
    </DashboardLayout>
  );
}

export default SectionListPage;