import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CircularProgress } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import {
  ExclamationCircleOutlined,
  ClockCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import axios from "axios";

const AreaPpmSummary = ({ userSites }) => {
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState({ overdue: 0, upcoming: 0, completed: 0 });
  const navigate = useNavigate();
  const userToken = localStorage.getItem("token");

  useEffect(() => {
    const fetchAllTaskStats = async () => {
      try {
        setLoading(true);
        let totalStats = { overdue: 0, upcoming: 0, completed: 0 };

        if (!userSites || userSites.length === 0) return;

        const requests = userSites.map((site) =>
          axios.get(
            `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/sections?site_id=${site.id}`,
            { headers: { Authorization: `${userToken}` } }
          )
        );

        const responses = await Promise.all(requests);
        responses.forEach(({ data }) => {
          const sectionStats = data.reduce(
            (acc, section) => ({
              overdue: acc.overdue + (section.overdue_tasks || 0),
              upcoming:
                acc.upcoming +
                ((section.total_tasks || 0) -
                  (section.completed_tasks || 0) -
                  (section.overdue_tasks || 0)),
              completed: acc.completed + (section.completed_tasks || 0),
            }),
            { overdue: 0, upcoming: 0, completed: 0 }
          );

          totalStats.overdue += sectionStats.overdue;
          totalStats.upcoming += sectionStats.upcoming;
          totalStats.completed += sectionStats.completed;
        });

        setStats(totalStats);
      } catch (error) {
        console.error("Error fetching task stats:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllTaskStats();
  }, [userSites]);

  const statusData = [
    {
      type: "overdue",
      label: "Overdue Tasks",
      color: "#FF4D4F",
      bgColor: "rgba(255, 77, 79, 0.2)",
      icon: <ExclamationCircleOutlined />,
    },
    {
      type: "upcoming",
      label: "Upcoming Tasks",
      color: "#FFC53D",
      bgColor: "rgba(255, 197, 61, 0.2)",
      icon: <ClockCircleOutlined />,
    },
    {
      type: "completed",
      label: "Completed Tasks",
      color: "#73D13D",
      bgColor: "rgba(115, 209, 61, 0.2)",
      icon: <CheckCircleOutlined />,
    },
  ];

  return (
    <VuiBox display="flex" flexDirection="column" alignItems="center" mt={4} pb={2}>
      <VuiBox textAlign="center"  mb={4}>
        <VuiBox
          sx={{
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            width: "60px",
            height: "60px",
            borderRadius: "50%",
            backgroundColor: statusData[0].bgColor,
          }}
        >
          <VuiTypography variant="h4" color={statusData[0].color}>
            {statusData[0].icon}
          </VuiTypography>
        </VuiBox>
        <VuiTypography variant="h6" color="white" mt={1}>
          {statusData[0].label}
        </VuiTypography>
        <VuiTypography variant="h3" fontWeight="bold" color={statusData[0].color}>
          {stats.overdue}
        </VuiTypography>
      </VuiBox>
      <VuiBox mt="auto" display="flex" justifyContent="space-around" width="100%">
        {statusData.slice(1).map(({ type, label, color, bgColor, icon }) => (
          <VuiBox key={type} textAlign="center" width="45%">
            <VuiBox
              sx={{
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                width: "50px",
                height: "50px",
                borderRadius: "50%",
                backgroundColor: bgColor,
              }}
            >
              <VuiTypography variant="h4" color={color}>
                {icon}
              </VuiTypography>
            </VuiBox>
            <VuiTypography variant="h6" color="white" mt={1}>
              {label}
            </VuiTypography>
            <VuiTypography variant="h3" fontWeight="bold" color={color}>
              {stats[type]}
            </VuiTypography>
          </VuiBox>
        ))}
      </VuiBox>
    </VuiBox>
  );
};

export default AreaPpmSummary;
