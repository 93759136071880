import React, { useState, useEffect } from "react";
import {
  Card,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Drawer,
  Alert,
  Snackbar,
  Box,
} from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";
import VuiTypography from "components/VuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Table from "examples/Tables/Table";
import VuiPagination from "components/VuiPagination";
import Icon from "@mui/material/Icon";

function CompanyManagement() {
  const [companyData, setCompanyData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(12);
  const [addCompanyOpen, setAddCompanyOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });
  const [newCompanyData, setNewCompanyData] = useState({
    name: "",
    street: "",
    address_county: "",
    address_postcode: "",
    address: "",
  });
  const token = localStorage.getItem("token");

  // Calculate pagination variables
  const totalPages = Math.ceil(companyData.length / rowsPerPage);
  const displayedRows = Array.isArray(companyData) 
  ? companyData.slice((page - 1) * rowsPerPage, page * rowsPerPage) 
  : [];

  // Table columns definition
  const columns = [
    { name: "Company ID", align: "left" },
    { name: "Name", align: "left" },
    { name: "Street", align: "left" },
    { name: "County", align: "left" },
    { name: "Postcode", align: "center" },
    { name: "Actions", align: "center" },
  ];

  useEffect(() => {
    fetchCompanies();
  }, []);

  const fetchCompanies = async () => {
    try {
      setLoading(true);
      const response = await fetch("https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/admin/companies", {
        method: "GET",
        headers: { Authorization: `${token}` },
      });
      const data = await response.json();
  
      if (Array.isArray(data)) {
        setCompanyData(data);
      } else {
        console.error("Unexpected API response format:", data);
        setCompanyData([]); // Fallback to empty array
      }
    } catch (error) {
      console.error("Error fetching companies:", error);
      showSnackbar("Error fetching companies", "error");
      setCompanyData([]); // Prevent crash
    } finally {
      setLoading(false);
    }
  };
  

  const handleEditClick = (company) => {
    setSelectedCompany(company);
    setDrawerOpen(true);
  };

  const handleDeleteClick = (company) => {
    setSelectedCompany(company);
    setDeleteConfirmOpen(true);
  };

  const showSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  const handleAddCompany = async () => {
    try {
      const response = await fetch("https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/company/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify(newCompanyData),
      });

      if (response.ok) {
        showSnackbar("Company added successfully", "success");
        setAddCompanyOpen(false);
        setNewCompanyData({
          name: "",
          street: "",
          address_county: "",
          address_postcode: "",
          address: "",
        });
        await fetchCompanies();
      } else {
        showSnackbar("Failed to add company", "error");
      }
    } catch (error) {
      showSnackbar("Error adding company", "error");
    }
  };

  const handleUpdateCompany = async () => {
    try {
      const response = await fetch(`https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/company/update`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify(selectedCompany),
      });

      if (response.ok) {
        showSnackbar("Company updated successfully", "success");
        setDrawerOpen(false);
        await fetchCompanies();
      } else {
        showSnackbar("Failed to update company", "error");
      }
    } catch (error) {
      showSnackbar("Error updating company", "error");
    }
  };

  const handleDeleteCompany = async () => {
    try {
      const response = await fetch(`https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/admin/companies/delete?company_id=${selectedCompany.company_id}`, {
        method: "DELETE",
        headers: {
          Authorization: `${token}`,
        },
      });

      if (response.ok) {
        showSnackbar("Company deleted successfully", "success");
        setDeleteConfirmOpen(false);
        await fetchCompanies();
      } else {
        showSnackbar("Failed to delete company", "error");
      }
    } catch (error) {
      showSnackbar("Error deleting company", "error");
    }
  };

  const rows = displayedRows.map((company) => ({
    "Company ID": company.company_id,
    Name: company.name,
    Street: company.street,
    County: company.address_county,
    Postcode: company.address_postcode,
    Actions: (
      <VuiBox display="flex" gap={1} justifyContent="flex-end">
        <VuiButton
          color="info"
          size="small"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleEditClick(company);
          }}
        >
          <Icon>edit</Icon>
        </VuiButton>
        <VuiButton
          color="error"
          size="small"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleDeleteClick(company);
          }}
        >
          <Icon>delete</Icon>
        </VuiButton>
      </VuiBox>
    ),
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <Card>
          <VuiBox display="flex" justifyContent="space-between" alignItems="center" p={2}>
            <VuiTypography variant="lg" color="white">
              Company Management
            </VuiTypography>
            <VuiButton color="info" onClick={() => setAddCompanyOpen(true)}>
              Add Company
            </VuiButton>
          </VuiBox>

          {loading ? (
            <VuiBox display="flex" justifyContent="center" py={4}>
              <CircularProgress />
            </VuiBox>
          ) : (
            <Table columns={columns} rows={rows} />
          )}

          <VuiBox display="flex" justifyContent="center" p={2}>
            <VuiPagination>
              <VuiPagination item onClick={() => setPage(page - 1)} disabled={page === 1}>
                <Icon>keyboard_arrow_left</Icon>
              </VuiPagination>
              {Array.from({ length: totalPages }, (_, index) => (
                <VuiPagination
                  key={index + 1}
                  item
                  active={page === index + 1}
                  onClick={() => setPage(index + 1)}
                >
                  {index + 1}
                </VuiPagination>
              ))}
              <VuiPagination item onClick={() => setPage(page + 1)} disabled={page === totalPages}>
                <Icon>keyboard_arrow_right</Icon>
              </VuiPagination>
            </VuiPagination>
          </VuiBox>
        </Card>
      </VuiBox>

      {/* Add Company Dialog */}
      <Dialog open={addCompanyOpen} onClose={() => setAddCompanyOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Add New Company</DialogTitle>
        <DialogContent>
          <VuiBox component="form" display="flex" flexDirection="column" gap={2} p={2}>
            <TextField
              label="Name"
              value={newCompanyData.name}
              onChange={(e) => setNewCompanyData({ ...newCompanyData, name: e.target.value })}
              fullWidth
            />
            <TextField
              label="Street"
              value={newCompanyData.street}
              onChange={(e) => setNewCompanyData({ ...newCompanyData, street: e.target.value })}
              fullWidth
            />
            <TextField
              label="County"
              value={newCompanyData.address_county}
              onChange={(e) => setNewCompanyData({ ...newCompanyData, address_county: e.target.value })}
              fullWidth
            />
            <TextField
              label="Postcode"
              value={newCompanyData.address_postcode}
              onChange={(e) => setNewCompanyData({ ...newCompanyData, address_postcode: e.target.value })}
              fullWidth
            />
            <TextField
              label="Address"
              value={newCompanyData.address}
              onChange={(e) => setNewCompanyData({ ...newCompanyData, address: e.target.value })}
              fullWidth
            />
          </VuiBox>
        </DialogContent>
        <DialogActions>
          <VuiButton color="error" onClick={() => setAddCompanyOpen(false)}>Cancel</VuiButton>
          <VuiButton color="info" onClick={handleAddCompany}>
            Add Company
          </VuiButton>
        </DialogActions>
      </Dialog>

      {/* Edit Company Drawer */}
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => {
          setDrawerOpen(false);
          setSelectedCompany(null);
        }}
        PaperProps={{
          sx: { width: "400px" }
        }}
      >
        {selectedCompany && (
          <VuiBox p={3}>
            <VuiTypography variant="h6" mb={3}>
              Edit Company
            </VuiTypography>
            <VuiBox display="flex" flexDirection="column" gap={2}>
              <TextField
                label="Name"
                value={selectedCompany.name}
                onChange={(e) => setSelectedCompany({ ...selectedCompany, name: e.target.value })}
                fullWidth
              />
              <TextField
                label="Street"
                value={selectedCompany.street}
                onChange={(e) => setSelectedCompany({ ...selectedCompany, street: e.target.value })}
                fullWidth
              />
              <TextField
                label="County"
                value={selectedCompany.address_county}
                onChange={(e) => setSelectedCompany({ ...selectedCompany, address_county: e.target.value })}
                fullWidth
              />
              <TextField
                label="Postcode"
                value={selectedCompany.address_postcode}
                onChange={(e) => setSelectedCompany({ ...selectedCompany, address_postcode: e.target.value })}
                fullWidth
              />
              <TextField
                label="Address"
                value={selectedCompany.address}
                onChange={(e) => setSelectedCompany({ ...selectedCompany, address: e.target.value })}
                fullWidth
              />
              <VuiBox display="flex" gap={2} mt={2}>
                <VuiButton
                  color="error"
                  fullWidth
                  onClick={() => {
                    setDrawerOpen(false);
                    setSelectedCompany(null);
                  }}
                >
                  Cancel
                </VuiButton>
                <VuiButton color="info" fullWidth onClick={handleUpdateCompany}>
                  Save Changes
                </VuiButton>
              </VuiBox>
            </VuiBox>
          </VuiBox>
        )}
      </Drawer>

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteConfirmOpen} onClose={() => setDeleteConfirmOpen(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <VuiTypography>
            Are you sure you want to delete company {selectedCompany?.name}?
          </VuiTypography>
        </DialogContent>
        <DialogActions>
          <VuiButton color="info" onClick={() => setDeleteConfirmOpen(false)}>
            Cancel
          </VuiButton>
          <VuiButton color="error" onClick={handleDeleteCompany}>
            Delete
          </VuiButton>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity={snackbar.severity} onClose={() => setSnackbar({ ...snackbar, open: false })}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </DashboardLayout>
  );
}

export default CompanyManagement;