import { useState, useEffect } from "react";
import { jwtDecode } from 'jwt-decode';
import { Link } from "react-router-dom";

// React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import GradientBorder from "examples/GradientBorder";

// Assets
import radialGradient from "assets/theme/functions/radialGradient";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgSignIn from "assets/images/BackGround.png";

import axios from "axios";
import { useNavigate } from "react-router-dom";

function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // Function to redirect based on role
  const redirectBasedOnRole = (roleId) => {
    switch(roleId) {
      case 1: // Area Manager
        navigate("/area-dashboard");
        break;
      case 2: // Site Manager
        navigate("/site-dashboard");
        break;
      case 3: // Admin
        navigate("/admin/users");
        break;
      case 4: // Engineer
        navigate("/section-list");
        break;
      default:
        // If no valid role, clear storage and show error
        localStorage.removeItem("token");
        localStorage.removeItem("email");
        localStorage.removeItem("user_id");
        localStorage.removeItem("is_admin");
        localStorage.removeItem("role_id");
        setError("Invalid user role. Please contact support.");
    }
  };

  // Check if the user is already logged in
  useEffect(() => {
    const token = localStorage.getItem("token");
    const roleId = localStorage.getItem("role_id");
  
    if (!token || !roleId) {
      // No token or role_id, stay on login page
      return;
    }
  
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
  
      if (decodedToken.exp > currentTime) {
        redirectBasedOnRole(parseInt(roleId));
      } else {
        localStorage.clear(); // Token expired, clear storage
      }
    } catch (err) {
      localStorage.clear(); // Invalid token, clear storage
    }
  }, [navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(null);
    
    // Basic validation
    if (!email || !password) {
      setError("Please enter both email and password");
      return;
    }

    try {
      // Convert email to lowercase before sending to API
      const lowercaseEmail = email.toLowerCase();
      
      const response = await axios.post(
        "https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/user/login",
        { email: lowercaseEmail, password }
      );
  
      if (response.data.token) {
        // Store user data with lowercase email
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("email", lowercaseEmail);
        localStorage.setItem("user_id", response.data.user_id);
        localStorage.setItem("is_admin", response.data.is_admin ? "true" : "false");
        
        // Store role_id and redirect based on role
        if (response.data.role_id) {
          localStorage.setItem("role_id", response.data.role_id);
          redirectBasedOnRole(response.data.role_id);
        } else {
          setError("No role assigned. Please contact support.");
        }
      }
    } catch (err) {
      setError(err.response?.data?.error || "Invalid login credentials");
    }
  };

  return (
    <CoverLayout title="Nice to see you again!" color="white" image={bgSignIn}>
      <VuiBox component="form" role="form" onSubmit={handleLogin}>
        <VuiBox mb={2}>
          <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
            Email
          </VuiTypography>
          <VuiInput
            type="email"
            placeholder="Your email..."
            fontWeight="500"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </VuiBox>
        <VuiBox mb={2}>
          <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
            Password
          </VuiTypography>
          <VuiInput
            type="password"
            placeholder="Your password..."
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </VuiBox>
        {error && (
          <VuiBox mb={2}>
            <VuiTypography
              variant="button"
              color="error"
              fontWeight="medium"
              style={{ textAlign: "center" }}
            >
              {error}
            </VuiTypography>
          </VuiBox>
        )}
        <VuiBox mt={4} mb={1}>
          <VuiButton type="submit" color="info" fullWidth>
            SIGN IN
          </VuiButton>
        </VuiBox>
        <VuiBox mt={2} textAlign="center">
          <VuiTypography variant="caption" color="white">
            Forgot your password?{" "}
            <Link to="/forgot-password" style={{ color: "white", textDecoration: "none" }}>
              Reset it here
            </Link>
          </VuiTypography>
        </VuiBox>
      </VuiBox>
    </CoverLayout>
  );
}

export default SignIn;