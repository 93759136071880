import React, { useState, useEffect } from "react";
import {
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  IconButton,
  ImageList,
  ImageListItem,
  Grid,
} from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";
import VuiTypography from "components/VuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useParams } from "react-router-dom";
import Icon from "@mui/material/Icon";

function EngineerPage() {
  const { site_id } = useParams();
  const [maintenanceItems, setMaintenanceItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentItem, setCurrentItem] = useState(null);
  const [taskCompletions, setTaskCompletions] = useState({});
  const [currentPhotos, setCurrentPhotos] = useState([]);
  const [currentComments, setCurrentComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [editingComment, setEditingComment] = useState(null);
  const [editedCommentText, setEditedCommentText] = useState("");
  const [uploadingPhoto, setUploadingPhoto] = useState(false);
  const [siteName, setSiteName] = useState("");

  const email = localStorage.getItem("email");
  const userName = localStorage.getItem("name");

  const fetchSiteData = async () => {
    try {
      const response = await fetch(
        `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/site/profile?site_id=${site_id}`
      );
      const data = await response.json();
      setSiteName(data.name);
    } catch (error) {
      console.error("Error fetching site data:", error.message);
    }
  };

  const fetchTasks = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/sites/tasks?site_id=${site_id}`
      );
      if (!response.ok) throw new Error("Failed to fetch tasks");
      const data = await response.json();
      
      const transformedData = data.map((task) => ({
        id: task.task_id,
        item: task.task_name,
        description: task.task_description,
        status: task.status,
        created_at: task.created_at,
        completed_at: task.completed_at,
        photo_count: task.photo_count || 0,
        comment_count: task.comment_count || 0,
        taskitem_count: task.taskitem_count || 0,
        completed_items: task.completed_items || 0,
      }));
      
      setMaintenanceItems(transformedData);
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
    setLoading(false);
  };

  const fetchComments = async (taskId) => {
    try {
      const response = await fetch(
        `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/tasks/comments?task_id=${taskId}`
      );
      if (!response.ok) throw new Error("Failed to fetch comments");
      const data = await response.json();
      setCurrentComments(data);
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };

  const fetchPhotos = async (taskId) => {
    try {
      const response = await fetch(
        `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/tasks/photos?task_id=${taskId}`
      );
      if (!response.ok) throw new Error("Failed to fetch photos");
      const data = await response.json();
      setCurrentPhotos(data || []);
    } catch (error) {
      console.error("Error fetching photos:", error);
      setCurrentPhotos([]);
    }
  };

  useEffect(() => {
    fetchSiteData();
    fetchTasks();
  }, [site_id]);

  const handleOpenTaskDialog = async (taskId) => {
    try {
      // Fetch task items
      const response = await fetch(
        `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/task-items?task_id=${taskId}`
      );
      if (!response.ok) throw new Error("Failed to fetch task items");
      const taskItems = await response.json();

      // Find the task in maintenanceItems to get other details
      const task = maintenanceItems.find(item => item.id === taskId);
      
      // Initialize task completions
      const completions = taskItems.reduce((acc, item) => ({
        ...acc,
        [item.item_id]: item.is_completed
      }), {});

      setCurrentItem({
        ...task,
        id: taskId,
        items: taskItems,
        completionPercentage: taskItems.length > 0 
          ? (taskItems.filter(item => item.is_completed).length / taskItems.length) * 100 
          : 0
      });
      
      setTaskCompletions(completions);
      
      // Fetch comments and photos
      await fetchComments(taskId);
      await fetchPhotos(taskId);
    } catch (error) {
      console.error("Error opening task dialog:", error);
    }
  };

  const handleUploadPhoto = async (event) => {
    const file = event.target.files[0];
    if (!file || !currentItem) return;

    setUploadingPhoto(true);
    try {
      // Get presigned URL
      const urlResponse = await fetch(
        "https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/tasks/photos/generate-presigned-url",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            task_id: currentItem.id,
            site_id: site_id,
            file_name: file.name,
          }),
        }
      );

      const { upload_url, photo_id, s3_key } = await urlResponse.json();

      // Upload file to S3
      const uploadResult = await fetch(upload_url, {
        method: "PUT",
        body: file,
        headers: { "Content-Type": file.type },
      });

      if (!uploadResult.ok) throw new Error("Upload failed");

      // Create photo record
      const recordResponse = await fetch(
        "https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/tasks/photos/create-photo-record",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            photo_id,
            task_id: currentItem.id,
            s3_key,
            file_name: file.name,
          }),
        }
      );

      const newPhoto = await recordResponse.json();
      setCurrentPhotos((prev) => [newPhoto, ...prev]);
      
      // Update photo count in maintenance items
      setMaintenanceItems(prevItems =>
        prevItems.map(item =>
          item.id === currentItem.id
            ? { ...item, photo_count: (item.photo_count || 0) + 1 }
            : item
        )
      );
    } catch (error) {
      console.error("Upload failed:", error);
      alert("Upload failed: " + error.message);
    } finally {
      setUploadingPhoto(false);
    }
  };

  const handleDeletePhoto = async (photoId) => {
    try {
      const response = await fetch(
        `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/tasks/photos/delete`,
        {
          method: "DELETE",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ photo_id: photoId }),
        }
      );

      if (!response.ok) throw new Error("Failed to delete photo");
      
      // Remove photo from state
      setCurrentPhotos(prev => prev.filter(photo => photo.photo_id !== photoId));
      
      // Update photo count in maintenance items
      if (currentItem) {
        setMaintenanceItems(prevItems =>
          prevItems.map(item =>
            item.id === currentItem.id && item.photo_count > 0
              ? { ...item, photo_count: item.photo_count - 1 }
              : item
          )
        );
      }
    } catch (error) {
      console.error("Error deleting photo:", error);
    }
  };

  const handleAddComment = async () => {
    if (!newComment.trim() || !currentItem) return;

    try {
      const response = await fetch(
        "https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/tasks/comments/add",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            task_id: currentItem.id,
            comment_text: newComment,
            created_by: userName || email,
          }),
        }
      );

      const data = await response.json();
      const newCommentWithDetails = {
        ...data,
        comment_text: newComment,
        created_by: userName || email,
        created_at: data.created_at || new Date().toISOString(),
      };

      setCurrentComments([newCommentWithDetails, ...currentComments]);
      setNewComment("");
      
      // Update comment count
      setMaintenanceItems(prevItems =>
        prevItems.map(item =>
          item.id === currentItem.id
            ? { ...item, comment_count: (item.comment_count || 0) + 1 }
            : item
        )
      );
    } catch (error) {
      console.error("Error adding comment:", error);
    }
  };

  const handleEditComment = (comment) => {
    setEditingComment(comment.comment_id);
    setEditedCommentText(comment.comment_text);
  };

  const handleUpdateComment = async (commentId) => {
    try {
      const response = await fetch(
        "https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/tasks/comments/update",
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            comment_id: commentId,
            comment_text: editedCommentText,
          }),
        }
      );

      if (!response.ok) throw new Error("Failed to update comment");

      setCurrentComments(prev =>
        prev.map(comment =>
          comment.comment_id === commentId
            ? { ...comment, comment_text: editedCommentText }
            : comment
        )
      );
      
      setEditingComment(null);
      setEditedCommentText("");
    } catch (error) {
      console.error("Error updating comment:", error);
    }
  };

  const handleDeleteComment = async (commentId) => {
    try {
      await fetch(
        `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/tasks/comments/delete`,
        {
          method: "DELETE",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ comment_id: commentId }),
        }
      );

      setCurrentComments(prev => prev.filter(comment => comment.comment_id !== commentId));
      
      // Update comment count
      if (currentItem) {
        setMaintenanceItems(prevItems =>
          prevItems.map(item =>
            item.id === currentItem.id && item.comment_count > 0
              ? { ...item, comment_count: item.comment_count - 1 }
              : item
          )
        );
      }
    } catch (error) {
      console.error("Error deleting comment:", error);
    }
  };

  const handleToggleTaskItemCompletion = async (itemId, isCompleted) => {
    try {
      const response = await fetch(
        `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/task-items/update`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            item_id: itemId,
            is_completed: !isCompleted,
          }),
        }
      );

      if (!response.ok) throw new Error("Failed to update task item");

      // Update local state
      setTaskCompletions(prev => ({
        ...prev,
        [itemId]: !isCompleted,
      }));

      // Update currentItem completion percentage
      if (currentItem) {
        const updatedItems = currentItem.items.map(item =>
          item.item_id === itemId ? { ...item, is_completed: !isCompleted } : item
        );
        
        const completedCount = updatedItems.filter(item => item.is_completed).length;
        const newPercentage = updatedItems.length > 0 
          ? (completedCount / updatedItems.length) * 100 
          : 0;
        
        setCurrentItem(prev => ({
          ...prev,
          items: updatedItems,
          completionPercentage: newPercentage,
        }));
      }

      // Update maintenance items count
      setMaintenanceItems(prevItems =>
        prevItems.map(item =>
          item.id === currentItem?.id
            ? {
                ...item,
                completed_items: isCompleted 
                  ? (item.completed_items || 0) - 1 
                  : (item.completed_items || 0) + 1,
              }
            : item
        )
      );
    } catch (error) {
      console.error("Error updating task item:", error);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <Card>
          <VuiBox display="flex" justifyContent="space-between" alignItems="center" p={2}>
            <VuiTypography variant="lg" color="white">
              Engineer Tasks - {siteName}
            </VuiTypography>
          </VuiBox>

          {loading ? (
            <VuiBox display="flex" justifyContent="center" py={4}>
              <CircularProgress />
            </VuiBox>
          ) : (
            <List>
              {maintenanceItems.map((item) => (
                <ListItem key={item.id}>
                  <ListItemText
                    primary={item.item}
                    secondary={item.description}
                    sx={{ "& .MuiTypography-root": { color: "white !important" } }}
                  />
                  <VuiTypography variant="body2" color="text" mr={2}>
                    {item.taskitem_count ? `${Math.round((item.completed_items / item.taskitem_count) * 100)}% Complete` : "No Items"}
                  </VuiTypography>
                  <VuiButton color="info" onClick={() => handleOpenTaskDialog(item.id)}>
                    View Task
                  </VuiButton>
                </ListItem>
              ))}
            </List>
          )}
        </Card>
      </VuiBox>

      {/* Task Dialog */}
      <Dialog
        open={Boolean(currentItem)}
        onClose={() => setCurrentItem(null)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {currentItem?.item}
          {currentItem?.completionPercentage !== undefined && (
            <VuiTypography variant="body2" color="text">
              {Math.round(currentItem.completionPercentage)}% Complete
            </VuiTypography>
          )}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            {/* Task Items Column */}
            <Grid item xs={12} md={6}>
              <VuiTypography variant="h6" mb={2}>
                Task Checklist
              </VuiTypography>
              <List>
                {currentItem?.items?.length > 0 ? (
                  currentItem.items.map((task) => (
                    <ListItem key={task.item_id}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={!!taskCompletions[task.item_id]}
                            onChange={() =>
                              handleToggleTaskItemCompletion(
                                task.item_id,
                                taskCompletions[task.item_id]
                              )
                            }
                            color="primary"
                          />
                        }
                        label={
                          <ListItemText
                            primary={task.item_text}
                            style={{
                              textDecoration: taskCompletions[task.item_id]
                                ? "line-through"
                                : "none",
                            }}
                          />
                        }
                      />
                    </ListItem>
                  ))
                ) : (
                  <VuiTypography variant="body2" color="text">
                    No checklist items for this task.
                  </VuiTypography>
                )}
              </List>
            </Grid>

            {/* Photos Column */}
            <Grid item xs={12} md={6}>
              <VuiTypography variant="h6" mb={2}>
                Photos ({currentPhotos.length})
              </VuiTypography>
              {currentPhotos.length > 0 ? (
                <ImageList cols={2} gap={8}>
                  {currentPhotos.map((photo) => (
                    <ImageListItem key={photo.photo_id}>
                      <img src={photo.url} alt={photo.file_name} />
                      <IconButton
                        color="error"
                        onClick={() => handleDeletePhoto(photo.photo_id)}
                        sx={{ position: "absolute", top: 5, right: 5 }}
                      >
                        <Icon>delete</Icon>
                      </IconButton>
                    </ImageListItem>
                  ))}
                </ImageList>
              ) : (
                <VuiTypography variant="body2" color="text">
                  No photos available.
                </VuiTypography>
              )}
              <Button
                variant="contained"
                component="label"
                fullWidth
                color="primary"
                disabled={uploadingPhoto}
                startIcon={<Icon>add_a_photo</Icon>}
                sx={{ mt: 2 }}
              >
                {uploadingPhoto ? "Uploading..." : "Upload Photo"}
                <input
                  type="file"
                  hidden
                  onChange={handleUploadPhoto}
                  accept="image/*"
                  disabled={uploadingPhoto}
                />
              </Button>
            </Grid>

            {/* Comments Section - Full Width */}
            <Grid item xs={12}>
              <VuiTypography variant="h6" mb={2}>
                Comments ({currentComments.length})
              </VuiTypography>
              <List>
                {currentComments.map((comment) => (
                  <ListItem key={comment.comment_id}>
                    {editingComment === comment.comment_id ? (
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={10}>
                          <TextField
                            fullWidth
                            value={editedCommentText}
                            onChange={(e) => setEditedCommentText(e.target.value)}
                            multiline
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleUpdateComment(comment.comment_id)}
                            size="small"
                          >
                            Save
                          </Button>
                          <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() => setEditingComment(null)}
                            size="small"
                            sx={{ mt: 1 }}
                          >
                            Cancel
                          </Button>
                        </Grid>
                      </Grid>
                    ) : (
                      <>
                        <ListItemText
                          primary={comment.comment_text}
                          secondary={`By ${comment.created_by} at ${new Date(comment.created_at).toLocaleString()}`}
                        />
                        <IconButton
                          onClick={() => handleEditComment(comment)}
                          color="primary"
                          size="small"
                        >
                          <Icon>edit</Icon>
                        </IconButton>
                        <IconButton
                          onClick={() => handleDeleteComment(comment.comment_id)}
                          color="error"
                          size="small"
                        >
                          <Icon>delete</Icon>
                        </IconButton>
                      </>
                    )}
                  </ListItem>
                ))}
              </List>
              <TextField
                fullWidth
                multiline
                rows={3}
                variant="outlined"
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                placeholder="Add new comment..."
                sx={{ mt: 2 }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddComment}
                fullWidth
                sx={{ mt: 2 }}
              >
                Add Comment
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCurrentItem(null)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Footer />
    </DashboardLayout>
  );
}

export default EngineerPage;