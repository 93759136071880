import { useEffect, useState, useCallback } from "react";
import { Card } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';

function SiteInformation({ siteId, timestamp }) {
  const [lastUpdate, setLastUpdate] = useState("");
  const [siteAddress, setSiteAddress] = useState(null);
  const [mapLoaded, setMapLoaded] = useState(false);

  // Function to properly parse and format the timestamp
  const formatTimestamp = (timestamp) => {
    if (!timestamp) return "";
    
    try {
      // Parse the input timestamp (yyyy-mm-dd hh:mm:ss)
      // Replace space with 'T' to create ISO 8601 format
      const isoString = timestamp.replace(' ', 'T') + 'Z';
      const date = new Date(isoString);
      
      // Check if the date is valid
      if (isNaN(date.getTime())) {
        console.error("Invalid date:", timestamp);
        return timestamp;
      }
      
      // Format for UK timezone (Europe/London)
      return date.toLocaleString("en-GB", {
        timeZone: "Europe/London",
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        timeZoneName: "short"
      });
    } catch (error) {
      console.error("Error formatting timestamp:", error);
      return timestamp;
    }
  };

  const initializeMap = useCallback(() => {
    if (siteAddress && window.google) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: siteAddress }, (results, status) => {
        if (status === "OK") {
          const map = new window.google.maps.Map(document.getElementById("map"), {
            center: results[0].geometry.location,
            zoom: 13,
          });

          const marker = new window.google.maps.Marker({
            position: results[0].geometry.location,
            map: map,
            title: `Site Location: ${siteAddress}`,
          });

          const infowindow = new window.google.maps.InfoWindow({
            content: `Site Location: ${siteAddress}`,
          });

          marker.addListener("click", () => {
            infowindow.open(map, marker);
          });
        } else {
          console.error("Geocode was not successful for the following reason:", status);
        }
      });
    }
  }, [siteAddress]);

  useEffect(() => {
    const fetchSiteData = async () => {
      try {
        const response = await fetch(
          `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/site/profile?site_id=${siteId}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch site data");
        }
        const data = await response.json();
        setSiteAddress(`${data.street}, ${data.address_postcode}`);
      } catch (error) {
        console.error("Error fetching site data:", error);
      }
    };

    const updateLastUpdateTime = () => {
      const currentDateTime = new Date();
      const formattedDateTime = currentDateTime.toLocaleString("en-GB", {
        timeZone: "Europe/London",
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        timeZoneName: "short"
      });
      setLastUpdate(formattedDateTime);
    };

    fetchSiteData();
    updateLastUpdateTime();
  }, [siteId]);

  useEffect(() => {
    if (!window.google) {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBlE514dTiL8nQSdTid4KrHfhI-U4lDR80`;
      script.async = true;
      script.defer = true;
      script.onload = () => {
        setMapLoaded(true);
      };
      document.head.appendChild(script);
    } else {
      setMapLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (siteAddress && mapLoaded) {
      initializeMap();
    }
  }, [siteAddress, mapLoaded, initializeMap]);

  return (
    <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <VuiBox display="flex" flexDirection="column" height="100%" p={2}>
        <VuiTypography variant="lg" color="white" fontWeight="bold">
          Site Information
        </VuiTypography>
        <VuiTypography variant="caption" color="text" p={1}>
          Last Update: {formatTimestamp(timestamp)}
        </VuiTypography>

        <VuiBox
          id="map"
          sx={{
            flex: 1,
            width: "100%",
            borderRadius: "10px",
            overflow: "hidden",
            height: {
              xs: "200px",
              sm: "250px",
              md: "300px",
              lg: "350px",
              xl: "400px",
            },
          }}
        />
      </VuiBox>
    </Card>
  );
}

export default SiteInformation;