import React from 'react';
import { Card, useMediaQuery, useTheme } from '@mui/material';
import { PieChart } from '@mui/x-charts/PieChart';
import VuiBox from 'components/VuiBox';
import VuiTypography from 'components/VuiTypography';
import colors from 'assets/theme/base/colors';

const AnnualPlantStatus = ({ Production_Hrs, Idle_Hrs, Breakdown_Hrs, Offline_Hrs }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { gradients } = colors;
  const { cardContent } = gradients;

  const data = [
    { id: 0, label: 'Offline Hours', value: Offline_Hrs, color: '#2D4491' },
    { id: 1, label: 'Production Hours', value: Production_Hrs, color: '#0077C0' },
    { id: 2, label: 'Idle Hours', value: Idle_Hrs, color: '#BDC3FF' },
    { id: 3, label: 'Breakdown Hours', value: Breakdown_Hrs, color: '#C7EEFF' },
  ];

  return (
    <Card sx={{ height: '100%', padding: 2 }}>
      <VuiBox display="flex" flexDirection="column" height="100%" p={2}>
        <VuiTypography variant="lg" color="white" fontWeight="bold">
          Annual Plant Status
        </VuiTypography>
        <VuiBox
          display="flex"
          flexDirection={isSmallScreen ? 'column' : 'row'}
          justifyContent="space-between"
          alignItems={isSmallScreen ? 'center' : 'flex-start'}
          mt={3}
        >
          {/* Pie Chart */}
          <VuiBox flex={1} sx={{ width: isSmallScreen ? '100%' : '50%', textAlign: 'center' }}>
            <PieChart
              series={[
                {
                  data: data.map((item) => ({
                    value: item.value,
                    label: item.label,
                    color: item.color,
                  })),
                },
              ]}
              slotProps={{
                legend: { hidden: true },
              }}
              height={isSmallScreen ? 200 : 150} // Adjust height dynamically
              width={isSmallScreen ? 300 : 200} // Adjust width dynamically
            />
          </VuiBox>

          {/* Custom Legend */}
          <VuiBox display="flex" flexDirection="column" flex={1} p={1} mt={isSmallScreen ? 2 : 0}>
            {data.map((item) => (
              <VuiBox display="flex" alignItems="center" mb={1} key={item.id}>
                <VuiBox
                  sx={{
                    width: '15px',
                    height: '15px',
                    borderRadius: '50%',
                    backgroundColor: item.color,
                    mr: 2,
                  }}
                />
                <VuiTypography variant="caption" color="text">
                  {item.label}: {item.value} hours
                </VuiTypography>
              </VuiBox>
            ))}
          </VuiBox>
        </VuiBox>
      </VuiBox>
    </Card>
  );
};

export default AnnualPlantStatus;
