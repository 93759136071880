import React from 'react';

const SemiCircleProgress = ({
  strokeWidth = 6,
  percentage = 0,
  value = 0,
  unitType = 'kWh',
  size = { width: 120, height: 60 },
  strokeColor = '#0075FF',
  bgStrokeColor = `${strokeColor}33`, // Default semi-transparent version of strokeColor
  fontStyle = { fontSize: "12px", fontFamily: "Arial", fill: "white" },
}) => {
  const formatValue = (val, type) => {
    switch (type) {
      case 'Electricity':
        return `${parseFloat(val).toFixed(2)} kWh`;
      case 'Fuel':
        return val < 9999 ? `${parseFloat(val).toFixed(2)} L` : `${(val / 1000).toFixed(2)} kL`;
      case 'Gas':
        return val < 9999 ? `${parseFloat(val).toFixed(2)} L` : `${(val / 1000).toFixed(2)} kL`;
      default:
        return `${val} ${unitType}`;
    }
  };

  const radius = 50 - strokeWidth / 2;
  const circumference = 1.1 * Math.PI * radius;
  const strokeDashoffset = circumference - (percentage / 100) * circumference;
  const bgStrokeDashoffset = circumference - 1 * circumference;

  return (
    <svg width={size.width} height={size.height} viewBox="0 0 100 100" fill="none">
      {/* Background path */}
      <path
        d="M5,64 a1,1 0 0,1 90,0"
        style={{
          stroke: bgStrokeColor,
          strokeWidth: strokeWidth,
          strokeDasharray: circumference,
          strokeDashoffset: bgStrokeDashoffset,
          fill: 'none'
        }}
      />
      {/* Foreground/progress path */}
      <path
        d="M5,64 a1,1 0 0,1 90,0"
        style={{
          stroke: strokeColor,
          strokeWidth: strokeWidth,
          strokeDasharray: circumference,
          strokeDashoffset: strokeDashoffset,
          fill: 'none',
          transition: "stroke-dashoffset 0.35s",
        }}
      />
      <text
        x="50%"
        y="50%"
        dominantBaseline="middle"
        textAnchor="middle"
        style={fontStyle}
      >
        {formatValue(value, unitType)}
      </text>
    </svg>
  );
};

export default SemiCircleProgress;