import React, { useState, useEffect } from "react";
import {
  Card,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  IconButton,
  ImageList,
  ImageListItem,
} from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";
import VuiTypography from "components/VuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import VuiPagination from "components/VuiPagination";
import Icon from "@mui/material/Icon";
import { useParams } from "react-router-dom";
import { green, grey } from "@mui/material/colors";

function MaintenanceTracker() {
  const { site_id, section_id } = useParams();
  const [sectionName, setSectionName] = useState("");
  const [maintenanceItems, setMaintenanceItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [taskDialogOpen, setTaskDialogOpen] = useState(false);
  const [pictureDialogOpen, setPictureDialogOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [taskCompletions, setTaskCompletions] = useState({});
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [currentPhotos, setCurrentPhotos] = useState([]);
  const [siteName, setSiteName] = useState("");
  const [newTaskItems, setNewTaskItems] = useState([""]);
  const [commentsDialogOpen, setCommentsDialogOpen] = useState(false);
  const [currentComments, setCurrentComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [editingPart, setEditingPart] = useState(null);
  const [editingTaskItem, setEditingTaskItem] = useState(null);
  const [editedTaskItemText, setEditedTaskItemText] = useState("");
  const [newTaskItemText, setNewTaskItemText] = useState("");
  const [newItem, setNewItem] = useState({
    task_name: "",
    task_description: "",
    hours_usage: 0,
    maintenance_interval: 0,
    task_items: [],
  });
  const [uploadingPhoto, setUploadingPhoto] = useState(false);
  const [editingField, setEditingField] = useState({ id: null, field: null });
  const [partsDialogOpen, setPartsDialogOpen] = useState(false);
  const [currentParts, setCurrentParts] = useState([]);
  const [newPart, setNewPart] = useState({
    part_name: "",
    part_description: "",
    quantity: 1,
  });

  const email = localStorage.getItem("email");
  const userName = localStorage.getItem("name");

  // Function to check and send maintenance notifications
  const checkAndSendMaintenanceNotification = async (task) => {
    try {
      const maintenanceInterval = task.maintenance_interval;
      const hoursUsage = task.hours_usage;
      
      if (maintenanceInterval > 0 && hoursUsage >= maintenanceInterval * 0.8) {
        await fetch(
          `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/SES-API/send-maintenance-notification`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              email: email,
              equipment_name: task.item,
              usage_percentage: Math.round((hoursUsage / maintenanceInterval) * 100),
              task_id: task.id,
              site_id: site_id,
            }),
          }
        );
      }
    } catch (error) {
      console.error("Error sending maintenance notification:", error);
    }
  };

  // Fetch site data
  const fetchSiteData = async () => {
    try {
      // Fetch site name
      const siteResponse = await fetch(
        `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/site/profile?site_id=${site_id}`
      );
      const siteData = await siteResponse.json();
      setSiteName(siteData.name);

      // Fetch section name if section_id exists
      if (section_id) {
        const sectionResponse = await fetch(
          `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/sections/profile?section_id=${section_id}`
        );
        const sectionData = await sectionResponse.json();
        setSectionName(sectionData.section_name);
      }
    } catch (error) {
      console.error("Error fetching site/section data:", error.message);
    } finally {
      setLoading(false);
    }
  };

  // Fetch tasks
  const fetchTasks = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/sites/tasks?site_id=${site_id}`
      );
      if (!response.ok) throw new Error("Failed to fetch tasks");
      const data = await response.json();

      const transformedData = data.map((task) => ({
        id: task.task_id,
        item: task.task_name,
        hours_usage: task.hours_usage,
        maintenance_interval: task.maintenance_interval,
        description: task.task_description,
        status: task.status,
        created_at: task.created_at,
        completed_at: task.completed_at,
        items: task.item || [],
        photo_count: task.photo_count || 0,
        part_count: task.part_count || 0,
        comment_count: task.comment_count || 0,
        taskitem_count: task.taskitem_count || 0,
        completed_items: task.completed_items || 0,
      }));
      
      setMaintenanceItems(transformedData);
      
      // Check and send notifications for all tasks after loading
      transformedData.forEach(task => {
        checkAndSendMaintenanceNotification(task);
      });
      
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchSiteData();
    fetchTasks();
  }, [site_id]);

  // Handle field edit
  const handleFieldEdit = (id, field) => {
    setEditingField({ id, field });
  };

  // Handle field update
  const handleFieldUpdate = async (id, field, value) => {
    try {
      const item = maintenanceItems.find((item) => item.id === id);
      const updatedValue = Number(value);

      await fetch(`https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/tasks/update`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          task_id: id,
          [field]: updatedValue,
        }),
      });

      // Check if we need to send maintenance notification
      if (field === "hours_usage") {
        await checkAndSendMaintenanceNotification({
          ...item,
          hours_usage: updatedValue
        });
      }

      await fetchTasks();
    } catch (error) {
      console.error("Error updating task:", error);
    } finally {
      setEditingField({ id: null, field: null });
    }
  };

  // Handle complete task
  const handleCompleteTask = async (taskId, currentStatus) => {
    try {
      const newStatus = currentStatus === "completed" ? "pending" : "completed";
  
      const response = await fetch(
        "https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/tasks/update",
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ task_id: taskId, status: newStatus }),
        }
      );
  
      if (!response.ok) throw new Error("Failed to update task status");
  
      await fetchTasks();
    } catch (error) {
      console.error("Error updating task status:", error);
    }
  };

  // Handle delete task
  const handleDeleteTask = async (taskId) => {
    if (window.confirm("Are you sure you want to delete this task?")) {
      try {
        await fetch(
          `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/tasks/delete?task_id=${taskId}`,
          {
            method: "DELETE",
          }
        );
        fetchTasks();
      } catch (error) {
        console.error("Error deleting task:", error);
      }
    }
  };

  // Handle add task
  const handleAddItem = async () => {
    try {
      const filteredTaskItems = newTaskItems.filter((item) => item.trim() !== "");

      const response = await fetch(
        `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/sites/tasks/create?site_id=${site_id}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            task_name: newItem.task_name,
            task_description: newItem.task_description,
            hours_usage: Number(newItem.hours_usage),
            maintenance_interval: Number(newItem.maintenance_interval),
            task_items: filteredTaskItems,
          }),
        }
      );

      if (!response.ok) throw new Error("Failed to create task");

      setAddDialogOpen(false);
      await fetchTasks();
      setNewItem({
        task_name: "",
        task_description: "",
        hours_usage: 0,
        maintenance_interval: 0,
      });
      setNewTaskItems([""]);
    } catch (error) {
      console.error("Error creating task:", error);
    }
  };

  // Handle add task item
  const handleAddTaskItem = async () => {
    if (!currentItem || !newTaskItemText.trim()) return;

    try {
      const response = await fetch(
        `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/task-items/add`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            task_id: currentItem.id,
            item_text: newTaskItemText,
          }),
        }
      );

      if (!response.ok) throw new Error("Failed to add task item");

      const newTaskItem = await response.json();

      setCurrentItem((prev) => ({
        ...prev,
        items: [...prev.items, newTaskItem],
      }));

      setNewTaskItemText("");

      setMaintenanceItems((prevItems) =>
        prevItems.map((item) =>
          item.id === currentItem.id
            ? { ...item, taskitem_count: (item.taskitem_count || 0) + 1 }
            : item
        )
      );
    } catch (error) {
      console.error("Error adding task item:", error);
    }
  };

  // Handle upload photo
  const handleUploadPhoto = async (event) => {
    const file = event.target.files[0];
    if (!file || !currentItem) return;

    setUploadingPhoto(true);
    try {
      const urlResponse = await fetch(
        "https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/tasks/photos/generate-presigned-url",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            task_id: currentItem.id,
            site_id: site_id,
            file_name: file.name,
          }),
        }
      );

      const { upload_url, photo_id, s3_key } = await urlResponse.json();

      const uploadResult = await fetch(upload_url, {
        method: "PUT",
        body: file,
        headers: { "Content-Type": file.type },
      });

      if (!uploadResult.ok) throw new Error("Upload failed");

      const recordResponse = await fetch(
        "https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/tasks/photos/create-photo-record",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            photo_id,
            task_id: currentItem.id,
            s3_key,
            file_name: file.name,
          }),
        }
      );

      const newPhoto = await recordResponse.json();
      setCurrentPhotos((prev) => [newPhoto, ...prev]);
    } catch (error) {
      console.error("Upload failed:", error);
      alert("Upload failed: " + error.message);
    } finally {
      setUploadingPhoto(false);
    }
  };

  const handleDeletePhoto = async (photoId) => {
    try {
      const response = await fetch(
        `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/tasks/photos/delete`,
        {
          method: "DELETE",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ photo_id: photoId }),
        }
      );

      if (!response.ok) throw new Error("Failed to delete photo");
      
      setCurrentPhotos((prevPhotos) =>
        prevPhotos.filter((photo) => photo.photo_id !== photoId)
      );
    } catch (error) {
      console.error("Error deleting photo:", error);
    }
  };

  // Handle add comment
  const handleAddComment = async () => {
    if (!currentItem) {
      console.error("No task selected for comment");
      return;
    }

    try {
      const response = await fetch(
        "https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/tasks/comments/add",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            task_id: currentItem.id,
            comment_text: newComment,
            created_by: userName || email,
          }),
        }
      );

      const data = await response.json();

      const newFormattedComment = {
        ...data,
        comment_text: newComment,
        created_by: userName || email,
        created_at: data.created_at || new Date().toISOString(),
      };

      setCurrentComments([newFormattedComment, ...currentComments]);
      setNewComment("");
      setMaintenanceItems((prevItems) =>
        prevItems.map((item) =>
          item.id === currentItem.id
            ? { ...item, comment_count: (item.comment_count || 0) + 1 }
            : item
        )
      );
    } catch (error) {
      console.error("Error adding comment:", error);
    }
  };

  // Handle open task dialog
  const handleOpenTaskDialog = async (taskId) => {
    try {
      const response = await fetch(
        `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/task-items?task_id=${taskId}`
      );
      if (!response.ok) throw new Error("Failed to fetch task items");
      const data = await response.json();

      const completions = data.reduce((acc, task) => {
        acc[task.item_id] = task.is_completed;
        return acc;
      }, {});

      const completedItems = data.filter((item) => item.is_completed).length;
      const totalItems = data.length;
      const completionPercentage = totalItems > 0 ? (completedItems / totalItems) * 100 : 0;

      setCurrentItem({
        id: taskId,
        items: data,
        completionPercentage: completionPercentage,
      });

      setTaskCompletions(completions);
      setTaskDialogOpen(true);
    } catch (error) {
      console.error("Error fetching task items:", error);
    }
  };

  // Handle open picture dialog
  const handleOpenPictureDialog = async (itemId) => {
    const item = maintenanceItems.find((item) => item.id === itemId);
    if (item) {
      setCurrentItem(item);
      setPictureDialogOpen(true);
      try {
        const response = await fetch(
          `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/tasks/photos?task_id=${item.id}`
        );
        if (!response.ok) throw new Error("Failed to fetch photos");
        const data = await response.json();
        setCurrentPhotos(data || []);
      } catch (error) {
        console.error("Error fetching photos:", error);
        setCurrentPhotos([]);
      }
    }
  };

  // Handle open parts dialog
  const handleOpenPartsDialog = async (taskId) => {
    const item = maintenanceItems.find((item) => item.id === taskId);
    if (item) {
      setCurrentItem(item);
      setPartsDialogOpen(true);
      try {
        const response = await fetch(
          `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/tasks/parts?task_id=${item.id}`
        );
        if (!response.ok) throw new Error("Failed to fetch parts");
        const data = await response.json();
        setCurrentParts(data || []);
      } catch (error) {
        console.error("Error fetching parts:", error);
        setCurrentParts([]);
      }
    }
  };

  // Handle add part
  const handleAddPart = async () => {
    try {
      const response = await fetch(
        "https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/tasks/parts/add",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            task_id: currentItem.id,
            ...newPart,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to add part");
      }

      const data = await response.json();

      if (
        !data.part_id ||
        !data.part_name ||
        !data.part_description ||
        data.quantity === undefined
      ) {
        throw new Error("Invalid part data received from server");
      }

      setCurrentParts((prevParts) => [data, ...prevParts]);

      setNewPart({
        part_name: "",
        part_description: "",
        quantity: 1,
      });
    } catch (error) {
      console.error("Error adding part:", error);
      alert("Failed to add part: " + error.message);
    }
  };

  // Handle delete part
  const handleDeletePart = async (partId) => {
    try {
      await fetch(`https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/tasks/parts/delete`, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          part_id: partId,
        }),
      });
      setCurrentParts((prev) => prev.filter((part) => part.part_id !== partId));
    } catch (error) {
      console.error("Error deleting part:", error);
    }
  };

  // Handle update part
  const handleUpdatePart = async () => {
    try {
      const response = await fetch(
        "https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/tasks/parts/update",
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            part_id: editingPart.part_id,
            part_name: editingPart.part_name,
            part_description: editingPart.part_description,
            quantity: editingPart.quantity,
          }),
        }
      );

      if (!response.ok) throw new Error("Failed to update part");

      setCurrentParts((prev) =>
        prev.map((part) => (part.part_id === editingPart.part_id ? editingPart : part))
      );
      setEditingPart(null);
    } catch (error) {
      console.error("Error updating part:", error);
    }
  };

  const handleOpenCommentsDialog = async (itemId) => {
    const item = maintenanceItems.find((item) => item.id === itemId);
    if (item) {
      setCurrentItem(item);
      setCommentsDialogOpen(true);
      try {
        const response = await fetch(
          `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/tasks/comments?task_id=${item.id}`
        );
        if (!response.ok) throw new Error("Failed to fetch comments");
        const data = await response.json();
        setCurrentComments(data || []);
      } catch (error) {
        console.error("Error fetching comments:", error);
        setCurrentComments([]);
      }
    }
  };

  const handleDeleteComment = async (commentId) => {
    try {
      await fetch(
        `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/tasks/comments/delete`,
        {
          method: "DELETE",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            comment_id: commentId,
          }),
        }
      );

      setCurrentComments((prev) => prev.filter((comment) => comment.comment_id !== commentId));

      if (currentItem) {
        setMaintenanceItems((prevItems) =>
          prevItems.map((item) =>
            item.id === currentItem.id && item.comment_count > 0
              ? { ...item, comment_count: item.comment_count - 1 }
              : item
          )
        );
      }
    } catch (error) {
      console.error("Error deleting comment:", error);
    }
  };

  const handleEditTaskItem = (taskId, itemId, itemText) => {
    setEditingTaskItem(itemId);
    setEditedTaskItemText(itemText);
  };

  const handleEditPart = (part) => {
    setEditingPart(part);
  };
  const handleCancelEdit = () => {
    setEditingPart(null);
  };
  const handleSaveTaskItem = async (taskId, itemId) => {
    try {
      const response = await fetch(
        `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/task-items/update`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            item_id: itemId,
            item_text: editedTaskItemText,
          }),
        }
      );

      if (!response.ok) throw new Error("Failed to update task item");

      setCurrentItem((prev) => ({
        ...prev,
        items: prev.items.map((item) =>
          item.item_id === itemId ? { ...item, item_text: editedTaskItemText } : item
        ),
      }));

      setEditingTaskItem(null);
      setEditedTaskItemText("");
    } catch (error) {
      console.error("Error updating task item:", error);
    }
  };

  const handleDeleteTaskItem = async (taskId, itemId) => {
    if (window.confirm("Are you sure you want to delete this task item?")) {
      try {
        await fetch(
          `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/task-items/delete`,
          {
            method: "DELETE",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              item_id: itemId,
            }),
          }
        );

        setCurrentItem((prev) => ({
          ...prev,
          items: prev.items.filter((item) => item.item_id !== itemId),
        }));

        if (currentItem) {
          setMaintenanceItems((prevItems) =>
            prevItems.map((item) =>
              item.id === currentItem.id && item.item_count > 0
                ? { ...item, item_count: item.item_count - 1 }
                : item
            )
          );
        }
      } catch (error) {
        console.error("Error deleting task item:", error);
      }
    }
  };

  const handleToggleTaskItemCompletion = async (taskId, itemId, isCompleted) => {
    try {
      const response = await fetch(
        `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/task-items/update`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            item_id: itemId,
            is_completed: !isCompleted,
          }),
        }
      );

      if (!response.ok) throw new Error("Failed to update task item completion");

      setTaskCompletions((prev) => ({
        ...prev,
        [itemId]: !isCompleted,
      }));

      setCurrentItem((prev) => ({
        ...prev,
        items: prev.items.map((item) =>
          item.item_id === itemId ? { ...item, is_completed: !isCompleted } : item
        ),
      }));

      setMaintenanceItems((prevItems) =>
        prevItems.map((item) =>
          item.id === taskId
            ? {
                ...item,
                completed_items: item.completed_items + (isCompleted ? -1 : 1),
              }
            : item
        )
      );
    } catch (error) {
      console.error("Error updating task item completion:", error);
    }
  };

  // Columns for the table
  const columns = [
    { name: "Item", align: "left" },
    { name: "Hours", align: "center" },
    { name: "Maintenance Interval", align: "center" },
    { name: "Completion", align: "center" },
    { name: "Comments", align: "center" },
    { name: "Task List", align: "center" },
    { name: "Pictures", align: "center" },
    { name: "Parts", align: "center" },
    { name: "Actions", align: "center" },
  ];

  // Rows for the table
  const rows = maintenanceItems.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((item) => {
    const completion =
      (item.taskitem_count || 0) > 0
        ? `${Math.round(((item.completed_items || 0) / item.taskitem_count) * 100)}%`
        : "N/A";

    return {
      Item: item.item,
      Hours:
        editingField.id === item.id && editingField.field === "hours_usage" ? (
          <TextField
            type="number"
            defaultValue={item.hours_usage}
            onBlur={(e) => handleFieldUpdate(item.id, "hours_usage", e.target.value)}
            autoFocus
          />
        ) : (
          <span onClick={() => handleFieldEdit(item.id, "hours_usage")}>{item.hours_usage}</span>
        ),
      "Maintenance Interval":
        editingField.id === item.id && editingField.field === "maintenance_interval" ? (
          <TextField
            type="number"
            defaultValue={item.maintenance_interval}
            onBlur={(e) => handleFieldUpdate(item.id, "maintenance_interval", e.target.value)}
            autoFocus
          />
        ) : (
          <span onClick={() => handleFieldEdit(item.id, "maintenance_interval")}>
            {item.maintenance_interval}
          </span>
        ),
      Completion: completion,
      Comments: (
        <VuiButton color="info" onClick={() => handleOpenCommentsDialog(item.id)}>
          {item.comment_count || 0} Comments
        </VuiButton>
      ),
      "Task List": (
        <VuiButton color="info" onClick={() => handleOpenTaskDialog(item.id)}>
          {item.taskitem_count || 0} Items
        </VuiButton>
      ),
      Pictures: (
        <VuiButton color="info" onClick={() => handleOpenPictureDialog(item.id)}>
          {item.photo_count || 0} Photos
        </VuiButton>
      ),
      Parts: (
        <VuiButton color="info" onClick={() => handleOpenPartsDialog(item.id)}>
          {item.part_count || 0} Parts
        </VuiButton>
      ),
      Actions: (
        <>
          <Checkbox
            checked={item.status === "completed"}
            onChange={() => handleCompleteTask(item.id, item.status)}
            color="success"
            disableRipple
            sx={{
              border: "1px solid grey",
              borderRadius: "5px",
              "&.Mui-checked": {
                border: "1px solid green",
              },
            }}
          />
          <IconButton color="error" onClick={() => handleDeleteTask(item.id)}>
            <Icon>delete</Icon>
          </IconButton>
        </>
      ),
    };
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <Card>
          <VuiBox display="flex" justifyContent="space-between" alignItems="center" p={2}>
            <VuiTypography variant="lg" color="white">
              Maintenance Tasks - {sectionName || siteName}
            </VuiTypography>
            <VuiButton variant="contained" color="primary" onClick={() => setAddDialogOpen(true)}>
              <Icon>add</Icon>&nbsp; Add Task
            </VuiButton>
          </VuiBox>

          {loading ? (
            <VuiBox display="flex" justifyContent="center" py={4}>
              <CircularProgress />
            </VuiBox>
          ) : (
            <Table columns={columns} rows={rows} />
          )}

          {maintenanceItems.length > 0 && (
            <VuiBox display="flex" justifyContent="center" p={2}>
              <VuiPagination>
                <VuiPagination
                  item
                  onClick={() => setPage(Math.max(1, page - 1))}
                  disabled={page === 1}
                >
                  <Icon>keyboard_arrow_left</Icon>
                </VuiPagination>
                {Array.from(
                  { length: Math.ceil(maintenanceItems.length / rowsPerPage) },
                  (_, i) => (
                    <VuiPagination
                      key={i + 1}
                      item
                      active={page === i + 1}
                      onClick={() => setPage(i + 1)}
                    >
                      {i + 1}
                    </VuiPagination>
                  )
                )}
                <VuiPagination
                  item
                  onClick={() =>
                    setPage(Math.min(Math.ceil(maintenanceItems.length / rowsPerPage), page + 1))
                  }
                  disabled={page === Math.ceil(maintenanceItems.length / rowsPerPage)}
                >
                  <Icon>keyboard_arrow_right</Icon>
                </VuiPagination>
              </VuiPagination>
            </VuiBox>
          )}
        </Card>
      </VuiBox>

      {/* Add Task Dialog */}
      <Dialog open={addDialogOpen} onClose={() => setAddDialogOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>Add New Maintenance Task</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Task Name"
                margin="dense"
                value={newItem.task_name}
                onChange={(e) => setNewItem({ ...newItem, task_name: e.target.value })}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField
                fullWidth
                type="number"
                label="Hours/Usage"
                margin="dense"
                value={newItem.hours_usage}
                onChange={(e) => setNewItem({ ...newItem, hours_usage: e.target.value })}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField
                fullWidth
                type="number"
                label="Maintenance Interval"
                margin="dense"
                value={newItem.maintenance_interval}
                onChange={(e) => setNewItem({ ...newItem, maintenance_interval: e.target.value })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={3}
                label="Task Description"
                margin="dense"
                value={newItem.task_description}
                onChange={(e) => setNewItem({ ...newItem, task_description: e.target.value })}
              />
            </Grid>
            <Grid item xs={12}>
              <VuiTypography variant="subtitle2" mt={2} mb={1}>
                Task Checklist Items
              </VuiTypography>
              {newTaskItems.map((item, index) => (
                <Grid container spacing={1} key={index} alignItems="center" mb={1}>
                  <Grid item xs={10}>
                    <TextField
                      fullWidth
                      size="small"
                      label={`Checklist Item ${index + 1}`}
                      value={item}
                      onChange={(e) => {
                        const updatedItems = [...newTaskItems];
                        updatedItems[index] = e.target.value;
                        setNewTaskItems(updatedItems);
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      color="error"
                      onClick={() => {
                        const updatedItems = newTaskItems.filter((_, i) => i !== index);
                        setNewTaskItems(updatedItems);
                      }}
                      disabled={newTaskItems.length === 1}
                    >
                      <Icon>delete</Icon>
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
              <Button
                variant="outlined"
                color="primary"
                startIcon={<Icon>add</Icon>}
                onClick={handleAddTaskItem}
                fullWidth
                sx={{ mt: 1 }}
              >
                Add Checklist Item
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddDialogOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleAddItem}
            color="primary"
            disabled={!newItem.task_name || newTaskItems.every((item) => item.trim() === "")}
          >
            Create Task
          </Button>
        </DialogActions>
      </Dialog>

      {/* Task Checklist Dialog */}
      <Dialog
        open={taskDialogOpen}
        onClose={() => setTaskDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>{currentItem?.item} Checklist</DialogTitle>
        <DialogContent>
          {currentItem?.description && (
            <VuiBox mb={3}>
              <VuiTypography variant="body2" color="text">
                {currentItem.description}
              </VuiTypography>
            </VuiBox>
          )}
          <List>
            {currentItem?.items && currentItem.items.length > 0 ? (
              currentItem.items.map((task) => (
                <ListItem key={task.item_id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!!taskCompletions[task.item_id]}
                        onChange={() =>
                          handleToggleTaskItemCompletion(
                            currentItem.id,
                            task.item_id,
                            taskCompletions[task.item_id]
                          )
                        }
                        color="primary"
                      />
                    }
                    label={
                      editingTaskItem === task.item_id ? (
                        <TextField
                          fullWidth
                          value={editedTaskItemText}
                          onChange={(e) => setEditedTaskItemText(e.target.value)}
                          onBlur={() => handleSaveTaskItem(currentItem.id, task.item_id)}
                          autoFocus
                        />
                      ) : (
                        <ListItemText
                          primary={task.item_text}
                          style={{
                            textDecoration: taskCompletions[task.item_id] ? "line-through" : "none",
                          }}
                        />
                      )
                    }
                  />
                  <IconButton
                    onClick={() => handleEditTaskItem(currentItem.id, task.item_id, task.item_text)}
                    color="primary"
                  >
                    <Icon>edit</Icon>
                  </IconButton>
                  <IconButton
                    onClick={() => handleDeleteTaskItem(currentItem.id, task.item_id)}
                    color="error"
                  >
                    <Icon>delete</Icon>
                  </IconButton>
                </ListItem>
              ))
            ) : (
              <VuiBox textAlign="center" py={2}>
                <VuiTypography variant="body2" color="text">
                  No checklist items for this task.
                </VuiTypography>
              </VuiBox>
            )}
          </List>
          <TextField
            fullWidth
            variant="outlined"
            label="New Task Item"
            value={newTaskItemText}
            onChange={(e) => setNewTaskItemText(e.target.value)}
            sx={{ mt: 2 }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddTaskItem}
            fullWidth
            sx={{ mt: 2 }}
          >
            Add New Task Item
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setTaskDialogOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Photos Dialog */}
      <Dialog
        open={pictureDialogOpen}
        onClose={() => setPictureDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>{currentItem?.item} Photos</DialogTitle>
        <DialogContent>
          {currentPhotos.length > 0 ? (
            <ImageList>
              {currentPhotos.map((photo) => (
                <ImageListItem key={photo.photo_id}>
                  <img src={photo.url} alt={photo.file_name} />
                  <IconButton
                    color="error"
                    onClick={() => handleDeletePhoto(photo.photo_id)}
                    sx={{ position: "absolute", top: 5, right: 5 }}
                  >
                    <Icon>delete</Icon>
                  </IconButton>
                </ImageListItem>
              ))}
            </ImageList>
          ) : (
            <VuiBox textAlign="center" py={4}>
              <VuiTypography variant="body2" color="text">
                No photos available for this task.
              </VuiTypography>
            </VuiBox>
          )}
          <VuiBox mt={3}>
            <Button
              variant="contained"
              component="label"
              fullWidth
              color="primary"
              disabled={uploadingPhoto}
              startIcon={<Icon>add_a_photo</Icon>}
            >
              {uploadingPhoto ? "Uploading..." : "Upload Photo"}
              <input
                type="file"
                hidden
                onChange={handleUploadPhoto}
                accept="image/*"
                disabled={uploadingPhoto}
              />
            </Button>
          </VuiBox>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPictureDialogOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Parts Dialog */}
      <Dialog
        open={partsDialogOpen}
        onClose={() => setPartsDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>{currentItem?.item} Parts</DialogTitle>
        <DialogContent>
          {currentParts.length > 0 ? (
            <List>
              {currentParts.map((part) => (
                <ListItem key={part.part_id}>
                  {editingPart?.part_id === part.part_id ? (
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12} md={4}>
                        <TextField
                          fullWidth
                          label="Part Name"
                          value={editingPart.part_name}
                          onChange={(e) =>
                            setEditingPart({ ...editingPart, part_name: e.target.value })
                          }
                          required
                          margin="dense"
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          fullWidth
                          label="Part Description"
                          value={editingPart.part_description}
                          onChange={(e) =>
                            setEditingPart({ ...editingPart, part_description: e.target.value })
                          }
                          margin="dense"
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <TextField
                          fullWidth
                          type="number"
                          label="Quantity"
                          value={editingPart.quantity}
                          onChange={(e) =>
                            setEditingPart({ ...editingPart, quantity: e.target.value })
                          }
                          required
                          margin="dense"
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleUpdatePart}
                          startIcon={<Icon>save</Icon>}
                        >
                          Save
                        </Button>
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={handleCancelEdit}
                          startIcon={<Icon>cancel</Icon>}
                          sx={{ mt: 1 }}
                        >
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  ) : (
                    <>
                      <ListItemText
                        primary={part.part_name}
                        secondary={`Quantity: ${part.quantity} - ${part.part_description}`}
                      />
                      <IconButton
                        onClick={() => handleEditPart(part)}
                        color="primary"
                        size="small"
                        sx={{ mr: 1 }}
                      >
                        <Icon>edit</Icon>
                      </IconButton>
                      <IconButton
                        onClick={() => handleDeletePart(part.part_id)}
                        color="error"
                        size="small"
                      >
                        <Icon>delete</Icon>
                      </IconButton>
                    </>
                  )}
                </ListItem>
              ))}
            </List>
          ) : (
            <VuiBox textAlign="center" py={4}>
              <VuiTypography variant="body2" color="text">
                No parts available for this task.
              </VuiTypography>
            </VuiBox>
          )}
          <VuiBox mt={3}>
            <TextField
              fullWidth
              label="Part Name"
              margin="dense"
              value={newPart.part_name}
              onChange={(e) => setNewPart({ ...newPart, part_name: e.target.value })}
            />
            <TextField
              fullWidth
              label="Part Description"
              margin="dense"
              value={newPart.part_description}
              onChange={(e) => setNewPart({ ...newPart, part_description: e.target.value })}
            />
            <TextField
              fullWidth
              type="number"
              label="Quantity"
              margin="dense"
              value={newPart.quantity}
              onChange={(e) => setNewPart({ ...newPart, quantity: e.target.value })}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddPart}
              fullWidth
              sx={{ mt: 2 }}
            >
              Add Part
            </Button>
          </VuiBox>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPartsDialogOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Comments Dialog */}
      <Dialog
        open={commentsDialogOpen}
        onClose={() => setCommentsDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Task Comments</DialogTitle>
        <DialogContent>
          <List>
            {currentComments.map((comment) => (
              <ListItem key={comment.comment_id}>
                <ListItemText
                  primary={comment.comment_text}
                  secondary={`At ${new Date(comment.created_at).toLocaleString()} By ${comment.created_by}`}
                  />
                <IconButton
                  onClick={() => handleDeleteComment(comment.comment_id)}
                  color="error"
                  size="small"
                >
                  <Icon>delete</Icon>
                </IconButton>
              </ListItem>
            ))}
          </List>
          <TextField
            fullWidth
            multiline
            rows={2}
            variant="outlined"
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder="Add new comment..."
          />
          <Button variant="contained" color="primary" onClick={handleAddComment} sx={{ mt: 2 }}>
            Add Comment
          </Button>
        </DialogContent>
      </Dialog>

      <Footer />
    </DashboardLayout>
  );
}

export default MaintenanceTracker;
