import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Card, Box, Typography, CircularProgress } from "@mui/material";
import Link from "@mui/material/Link";
import ReactApexChart from "react-apexcharts";
import { TimestreamQueryClient, QueryCommand } from "@aws-sdk/client-timestream-query";

import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProductionCardRAP from "layouts/site-dashboard/components/ProductionCardRAP";
import ProductionCardAsp from "layouts/site-dashboard/components/ProductionCardAsp";
import AnnualPlantStatus from "layouts/site-dashboard/components/AnnualPlantStatus";
import LivePlantStatus from "layouts/site-dashboard/components/LivePlantStatus";
import SiteInformation from "layouts/site-dashboard/components/SiteInformation";
import UsageCard from "layouts/site-dashboard/components/UsageCard";
import StockedMaterials from "layouts/site-dashboard/components/StockedMaterials";
import ConditionMonitoring from "layouts/site-dashboard/components/ConditionMonitoring";
import AsphaltBatchQuality from "layouts/site-dashboard/components/AsphaltBatchQuality";
import { useParams } from "react-router-dom";
import fetchAnnualTotalisersData from "./data/fetchAnnualTotalisersData";
import AnnualTotalisersChart from "./data/AnnualTotalisersChart";
import ProductionCardGraded from "layouts/site-dashboard/components/ProductionCardGraded";
import ProductionCardRawMat from "layouts/site-dashboard/components/ProductionCardRawMat";
import ProductionCardSand from "layouts/site-dashboard/components/ProductionCardSand";
import ProductionCardShipLoad from "layouts/site-dashboard/components/ProductionCardShipLoad";
import ProductionCardShipUnload from "layouts/site-dashboard/components/ProductionCardShipUnload";
import ProductionCardTrainLoad from "layouts/site-dashboard/components/ProductionCardTrainLoad";
import PpmSummary from "layouts/site-dashboard/components/PpmSummary";
import { StarBorder } from "@mui/icons-material";

function Dashboard() {
  const [dashboardConfig, setDashboardConfig] = useState(null);
  const [annualTotalisersData, setAnnualTotalisersData] = useState(null);
  const [stockedMaterialsData, setStockedMaterialsData] = useState([]);
  const [siteData, setSiteData] = useState(null);
  const [timestamp, setTimestamp] = useState("");
  const [rapData, setRapData] = useState({
    dayProgress: 0,
    weekProgress: 0,
    monthProgress: 0,
    yearProgress: 0,
  });
  const [annualPlantStatusData, setannualPlantStatusData] = useState({
    Production_Hrs: 0,
    Idle_Hrs: 0,
    Breakdown_Hrs: 0,
    Offline_Hrs: 0,
  });
  const [livePlantStatusData, setlivePlantStatusData] = useState(null);

  const [asphaltData, setAsphaltData] = useState({
    dayProgress: 0,
    weekProgress: 0,
    monthProgress: 0,
    yearProgress: 0,
  });
  const [RawMaterialData, setRawMaterialData] = useState({
    dayProgress: 0,
    weekProgress: 0,
    monthProgress: 0,
    yearProgress: 0,
  });
  const [SandData, setSandData] = useState({
    dayProgress: 0,
    weekProgress: 0,
    monthProgress: 0,
    yearProgress: 0,
  });
  const [GradedData, setGradedData] = useState({
    dayProgress: 0,
    weekProgress: 0,
    monthProgress: 0,
    yearProgress: 0,
  });
  const [TrainLoadData, setTrainLoad] = useState({
    weekProgress: 0,
    monthProgress: 0,
    yearProgress: 0,
  });
  const [usageData, setUsageData] = useState({
    unusedAgg: 0,
    gas: 0,
    burnerIdleFuel: 0,
    fuel: 0,
    electricityOffline: 0,
    electricity: 0,
  });

  const [batchQualityData, setBatchQualityData] = useState({
    Day_Good_Quality: 0,
    Day_Bad_Quality: 0,
    Week_Good_Quality: 0,
    Week_Bad_Quality: 0,
    Month_Good_Quality: 0,
    Month_Bad_Quality: 0,
    Year_Good_Quality: 0,
    Year_Bad_Quality: 0,
  });
  const [loading, setLoading] = useState(true);
  const { site_id } = useParams();

  // Fetch site data
  useEffect(() => {
    setLoading(true); // Start loading when site_id changes
    const fetchSiteData = async () => {
      try {
        const response = await fetch(
          `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/site/profile?site_id=${site_id}`
        );
        const data = await response.json();
        setSiteData(data);
        setDashboardConfig(data.dashboard_config || []);
        console.log(data.dashboard_config);
        // Store the dashboard configuration
      } catch (error) {
        console.error("Error fetching site data:", error.message);
      }
    };

    const fetchGatewayName = async () => {
      try {
        const response = await fetch(
          `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/gateway/profile?site_id=${site_id}`
        );
        const data = await response.json();
        return data[0].name;
      } catch (error) {
        console.error("Error fetching gateway name:", error.message);
        return null;
      }
    };

    const fetchTimestreamData = async () => {
      const gatewayName = await fetchGatewayName();

      if (!gatewayName) {
        console.error("Failed to fetch gateway name");
        return;
      }
      console.log("Gateway name:", gatewayName);
      const client = new TimestreamQueryClient({
        region: "us-east-1",
        credentials: {
          accessKeyId: "AKIARUIVQDAN4QY4OFNB",
          secretAccessKey: "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",
        },
      });

      const combinedQuery = `
        SELECT * FROM "Flexy205-dbv2"."gatewaydata" 
        WHERE ThingName = '${gatewayName}'
        ORDER BY time DESC
        LIMIT 10
      `;

      let nextToken = null;
      let completeData = [];

      try {
        do {
          const response = await client.send(
            new QueryCommand({
              QueryString: combinedQuery,
              NextToken: nextToken,
            })
          );

          console.log("Timestream Response:", response);

          if (response.Rows && response.Rows.length > 0) {
            const rows = response.Rows;
            rows.forEach((row) => {
              const columnData = row.Data.reduce((acc, column, index) => {
                const columnName = response.ColumnInfo[index].Name;
                acc[columnName] = column.ScalarValue;
                return acc;
              }, {});

              completeData.push(columnData);
            });
          }

          nextToken = response.NextToken;
        } while (nextToken);

        // Process the complete data after all pages are fetched
        if (completeData.length > 0) {
          const materials = [];
          const uniqueIndexes = new Set(); // To keep track of processed indexes
          completeData.forEach((columnData) => {
            Object.keys(columnData).forEach((key) => {
              if (key.startsWith("SM_Name_")) {
                const index = key.split("_")[2];
                const totalKey = `SM_Total_${index}`;
                if (columnData[totalKey] !== undefined) {
                  const name = columnData[key];
                  if (name !== "N/A" && !uniqueIndexes.has(index)) {
                    materials.push({
                      name: name,
                      total: columnData[totalKey],
                      index: index,
                    });
                    uniqueIndexes.add(index); // Mark the index as processed
                  }
                }
              }
            });
          });

          materials.sort((a, b) => a.index - b.index);
          setStockedMaterialsData(materials);

          const rapColumnData = completeData[0];
          const plantStatus =
            rapColumnData["Plant_Status"] !== undefined
              ? Number(rapColumnData["Plant_Status"])
              : null;
          setlivePlantStatusData(plantStatus);
          setRapData({
            dayProgress: rapColumnData["RAP_Utilisation_Day"] ?? 0,
            weekProgress: rapColumnData["RAP_Utilisation_Week"] ?? 0,
            monthProgress: rapColumnData["RAP_Utilisation_Month"] ?? 0,
            yearProgress: rapColumnData["RAP_Utilisation_Year"] ?? 0,
            maxDayValue: rapColumnData["RAP_Total_Day"] ?? 0,
            maxWeekValue: rapColumnData["RAP_Total_Week"] ?? 0,
            maxMonthValue: rapColumnData["RAP_Total_Month"] ?? 0,
            maxYearValue: rapColumnData["RAP_Total_Year"] ?? 0,
          });

          setAsphaltData({
            dayProgress: rapColumnData["Asphalt_Day"] ?? 0,
            weekProgress: rapColumnData["Asphalt_Week"] ?? 0,
            monthProgress: rapColumnData["Asphalt_Month"] ?? 0,
            yearProgress: rapColumnData["Asphalt_Year"] ?? 0,
          });

          setRawMaterialData({
            dayProgress: rapColumnData["Raw_Material_Day"] ?? 0,
            weekProgress: rapColumnData["Raw_Material_Week"] ?? 0,
            monthProgress: rapColumnData["Raw_Material_Month"] ?? 0,
            yearProgress: rapColumnData["Raw_Material_Total"] ?? 0,
          });

          setSandData({
            dayProgress: rapColumnData["Sand_Day"] ?? 0,
            weekProgress: rapColumnData["Sand_Week"] ?? 0,
            monthProgress: rapColumnData["Sand_Month"] ?? 0,
            yearProgress: rapColumnData["Sand_Total"] ?? 0,
          });

          setGradedData({
            dayProgress: rapColumnData["Graded_Day"] ?? 0,
            weekProgress: rapColumnData["Graded_Week"] ?? 0,
            monthProgress: rapColumnData["Graded_Month"] ?? 0,
            yearProgress: rapColumnData["Graded_Total"] ?? 0,
          });

          setUsageData({
            unusedAgg: rapColumnData["Wasted_Agg"] ?? 0,
            gas: rapColumnData["Gas_Per_Ton"] ?? 0,
            burnerIdleFuel: rapColumnData["Wasted_Fuel"] ?? 0,
            fuel: rapColumnData["Fuel_Per_Ton"] ?? 0,
            electricityOffline: rapColumnData["Wasted_Elec"] ?? 0,
            electricity: rapColumnData["Elec_Per_Ton"] ?? 0,
          });

          setannualPlantStatusData({
            Production_Hrs: rapColumnData["Production_Hrs"] ?? 0,
            Idle_Hrs: rapColumnData["Idle_Hrs"] ?? 0,
            Breakdown_Hrs: rapColumnData["Breakdown_Hrs"] ?? 0,
            Offline_Hrs: rapColumnData["Offline_Hrs"] ?? 0,
          });

          setTrainLoad({
            Ship_Week: TrainLoadData["Ship_Week"] ?? 0,
            Ship_Month: TrainLoadData["Ship_Month"] ?? 0,
            Ship_Total: TrainLoadData["Ship_Total"] ?? 0,
          });

          setBatchQualityData({
            Day_Good_Quality: rapColumnData["Day_Good_Quality"] ?? 0,
            Day_Bad_Quality: rapColumnData["Day_Bad_Quality"] ?? 0,
            Week_Good_Quality: rapColumnData["Week_Good_Quality"] ?? 0,
            Week_Bad_Quality: rapColumnData["Week_Bad_Quality"] ?? 0,
            Month_Good_Quality: rapColumnData["Month_Good_Quality"] ?? 0,
            Month_Bad_Quality: rapColumnData["Month_Bad_Quality"] ?? 0,
            Year_Good_Quality: rapColumnData["Year_Good_Quality"] ?? 0,
            Year_Bad_Quality: rapColumnData["Year_Bad_Quality"] ?? 0,
          });

          setTimestamp(new Date(rapColumnData["time"]).toLocaleString());
        } else {
          console.error("No data found in response.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchData = async () => {
      const data = await fetchAnnualTotalisersData();
      setAnnualTotalisersData(data);
    };

    fetchData();
    fetchSiteData();
    fetchTimestreamData();

    // Set up interval for auto-refresh (every 2 minutes)
    const intervalId = setInterval(() => {
      setLoading(true); // Start loading
      fetchData(); // Your data fetching function
      fetchSiteData(); // Fetch site data
      fetchTimestreamData(); // Fetch Timestream data
    }, 1000000); // 300,000 ms = 5 minutes

    // Clean up interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [site_id]);
  // Helper function to calculate grid sizes based on visible elements
  const calculateProductionCardGridSize = (visibleItems) => {
    if (!dashboardConfig) return 4; // Default size

    // Count how many items in this section are visible
    const count = visibleItems.filter((item) => dashboardConfig.includes(item)).length;

    if (count === 0) return 0; // No items to show
    if (count === 1) return 12; // One item takes full width
    if (count === 2) return 6; // Two items take half width each
    if (count === 3) return 4; // Three items take a third each
    if (count === 4) return 3; // Four items take a quarter each
    return 4; // Default size for other cases
  };

  // Get array of visible production cards
  const visibleProductionCards = [
    "Asphalt",
    "RAP",
    "Raw Material",
    "Sand",
    "Graded",
    "Batch Quality",
  ];

  // Calculate grid size for production cards
  const productionCardSize = calculateProductionCardGridSize(visibleProductionCards);

  // Get array of visible usage cards
  const visibleUsageCards = [
    "Unused Agg.",
    "Gas Per Tonnage",
    "Burner Idle Fuel",
    "Fuel per Tonnage",
    "Electricity per Tonnage",
    "Electricity Offline",
  ];

  // Calculate how many usage cards are visible
  const visibleUsageCount = visibleUsageCards.filter((item) =>
    dashboardConfig?.includes(item)
  ).length;

  // Calculate grid size for usage cards
  const usageCardSize = visibleUsageCount > 0 ? Math.floor(12 / visibleUsageCount) : 2;

  // Calculate the last row of cards (Ship/Train)
  const visibleShipTrainCards = dashboardConfig?.includes("Train Loading") ? 3 : 0;
  const shipTrainCardSize = visibleShipTrainCards > 0 ? Math.floor(12 / visibleShipTrainCards) : 4;

  return (
    <DashboardLayout>
      {/* Loading Spinner Overlay */}
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="inherit" />
        </div>
      )}

      {/* Navbar */}
      <div style={{ zIndex: 10000, position: "relative" }}>
        <DashboardNavbar />
      </div>

      <div style={{ filter: loading ? "blur(5px)" : "none", transition: "filter 0.3s ease" }}>
        {/* Top Row */}
        <VuiBox mb={1}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={3}>
              <SiteInformation siteId={site_id} siteData={siteData} timestamp={timestamp} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <AnnualPlantStatus
                Production_Hrs={annualPlantStatusData.Production_Hrs}
                Idle_Hrs={annualPlantStatusData.Idle_Hrs}
                Breakdown_Hrs={annualPlantStatusData.Breakdown_Hrs}
                Offline_Hrs={annualPlantStatusData.Offline_Hrs}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <PpmSummary siteId={site_id} isIncluded={dashboardConfig?.includes("PPM Summary")} />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <LivePlantStatus
                plantState={
                  livePlantStatusData === 0
                    ? "offline"
                    : livePlantStatusData === 1
                    ? "breakdown"
                    : livePlantStatusData === 2
                    ? "idle"
                    : livePlantStatusData === 3
                    ? "production"
                    : "offline"
                }
              />
            </Grid>
          </Grid>
        </VuiBox>

        <VuiBox mb={1}>
          <Grid container spacing={1}>
            {dashboardConfig?.includes("Batch Quality") && (
              <Grid item xs={12} sm={6} md={productionCardSize}>
                <AsphaltBatchQuality batchQualityData={batchQualityData} />
              </Grid>
            )}

            {productionCardSize > 0 && (
              <>
                {dashboardConfig?.includes("Asphalt") && (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={productionCardSize}
                    sx={{
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <ProductionCardAsp
                      site_id={site_id}
                      title="Asphalt"
                      dayProgress={asphaltData.dayProgress}
                      weekProgress={asphaltData.weekProgress}
                      monthProgress={asphaltData.monthProgress}
                      yearProgress={asphaltData.yearProgress}
                      color="#0075FF"
                      unit="tons"
                      productionCardSize={productionCardSize}
                    />
                  </Grid>
                )}

                {dashboardConfig?.includes("RAP") && (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={productionCardSize}
                    sx={{
                      display: "flex",
                      minWidth: 0, // Allow item to shrink
                      overflow: "hidden", // Prevent content overflow
                    }}
                  >
                    <ProductionCardRAP
                      title="RAP"
                      RAP_Utilisation_Day={rapData.dayProgress}
                      RAP_Utilisation_Week={rapData.weekProgress}
                      RAP_Utilisation_Month={rapData.monthProgress}
                      RAP_Utilisation_Year={rapData.yearProgress}
                      color="#D5ED9F"
                      unit="T"
                      maxDayValue={rapData.maxDayValue}
                      maxWeekValue={rapData.maxWeekValue}
                      maxMonthValue={rapData.maxMonthValue}
                      maxYearValue={rapData.maxYearValue}
                      productionCardSize={productionCardSize}
                    />
                  </Grid>
                )}

                {dashboardConfig?.includes("Raw Material") && (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={productionCardSize}
                    sx={{
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <ProductionCardRawMat
                      title="Raw Material"
                      Raw_Material_Day={RawMaterialData.dayProgress}
                      Raw_Material_Week={RawMaterialData.weekProgress}
                      Raw_Material_Month={RawMaterialData.monthProgress}
                      Raw_Material_Total={RawMaterialData.yearProgress}
                      color="#A020F0"
                      unit="T"
                      maxDayValue={RawMaterialData.maxDayValue}
                      maxWeekValue={RawMaterialData.maxWeekValue}
                      maxMonthValue={RawMaterialData.maxMonthValue}
                      maxYearValue={RawMaterialData.maxYearValue}
                      productionCardSize={productionCardSize}
                    />
                  </Grid>
                )}

                {dashboardConfig?.includes("Sand") && (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={productionCardSize}
                    sx={{
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <ProductionCardSand
                      title="Sand"
                      Sand_Day={SandData.dayProgress}
                      Sand_Week={SandData.weekProgress}
                      Sand_Month={SandData.monthProgress}
                      Sand_Total={SandData.yearProgress}
                      color="#C2B280"
                      unit="T"
                      maxDayValue={SandData.maxDayValue}
                      maxWeekValue={SandData.maxWeekValue}
                      maxMonthValue={SandData.maxMonthValue}
                      maxYearValue={SandData.maxYearValue}
                      productionCardSize={productionCardSize}
                    />
                  </Grid>
                )}

                {dashboardConfig?.includes("Graded") && (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={productionCardSize}
                    sx={{
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <ProductionCardGraded
                      title="Graded"
                      Graded_Day={GradedData.dayProgress}
                      Graded_Week={GradedData.weekProgress}
                      Graded_Month={GradedData.monthProgress}
                      Graded_Total={GradedData.yearProgress}
                      color="#FFA500"
                      unit="T"
                      maxDayValue={GradedData.maxDayValue}
                      maxWeekValue={GradedData.maxWeekValue}
                      maxMonthValue={GradedData.maxMonthValue}
                      maxYearValue={GradedData.maxYearValue}
                      productionCardSize={productionCardSize}
                    />
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </VuiBox>

        {/* Usage Cards Section - Using dynamic grid sizing */}
        {visibleUsageCount > 0 && (
          <VuiBox mb={1}>
            <Grid container spacing={1}>
              {dashboardConfig?.includes("Unused Agg.") && (
                <Grid item xs={12} sm={6} md={usageCardSize}>
                  <UsageCard
                    title="Unused Agg."
                    color="#5E5CE6"
                    value={Number(usageData.unusedAgg).toFixed(2)}
                    maxValue={0.1}
                    unit="T / yr"
                  />
                </Grid>
              )}
              {dashboardConfig?.includes("Gas Per Tonnage") && (
                <Grid item xs={12} sm={6} md={usageCardSize}>
                  <UsageCard
                    title="Gas Per Tonnage"
                    color="#73CAC5"
                    value={Number(usageData.gas).toFixed(2)}
                    maxValue={0.1}
                    unit="kWh"
                  />
                </Grid>
              )}
              {dashboardConfig?.includes("Burner Idle Fuel") && (
                <Grid item xs={12} sm={6} md={usageCardSize}>
                  <UsageCard
                    title="Burner Idle Fuel"
                    color="#C63C51"
                    value={Number(usageData.burnerIdleFuel).toFixed(2)}
                    maxValue={0.1}
                    unit="Litres"
                  />
                </Grid>
              )}
              {dashboardConfig?.includes("Fuel per Tonnage") && (
                <Grid item xs={12} sm={6} md={usageCardSize}>
                  <UsageCard
                    title="Fuel per Tonnage"
                    color="#CAB773"
                    value={Number(usageData.fuel).toFixed(2)}
                    maxValue={0.1}
                    unit="Litres"
                  />
                </Grid>
              )}
              {dashboardConfig?.includes("Electricity per Tonnage") && (
                <Grid item xs={12} sm={6} md={usageCardSize}>
                  <UsageCard
                    title="Electricity per Tonnage"
                    color="#F0F477"
                    value={Number(usageData.electricity).toFixed(2)}
                    maxValue={0.1}
                    unit="kWh"
                  />
                </Grid>
              )}
              {dashboardConfig?.includes("Electricity Offline") && (
                <Grid item xs={12} sm={6} md={usageCardSize}>
                  <UsageCard
                    title="Electricity Offline"
                    color="#F0A8D0"
                    value={Number(usageData.electricityOffline).toFixed(2)}
                    maxValue={0.1}
                    unit="kWh"
                  />
                </Grid>
              )}
            </Grid>
          </VuiBox>
        )}

        {/* Line Chart Section */}
        <VuiBox mb={1}>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={12} xl={6}>
              <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
                <VuiBox display="flex" flexDirection="column" height="100%" p={2}>
                  <VuiTypography variant="lg" color="white" fontWeight="bold">
                    Annual Totalisers
                  </VuiTypography>
                  <VuiBox display="flex" alignItems="center" mb="40px">
                    {/* Content here */}
                  </VuiBox>
                  <VuiBox sx={{ height: "100%" }}>
                    <AnnualTotalisersChart data={annualTotalisersData} />
                  </VuiBox>
                </VuiBox>
              </Card>
            </Grid>

            <Grid item xs={12} lg={12} xl={3} sx={{ minHeight: "350px" }}>
              <ConditionMonitoring />
            </Grid>
            <Grid item xs={12} lg={12} xl={3} sx={{ minHeight: "350px" }}>
              <StockedMaterials materials={stockedMaterialsData} />
            </Grid>
          </Grid>
        </VuiBox>

        {/* Ship/Train Section - Only show if Train Loading is enabled */}
        {dashboardConfig?.includes("Train Loading") && (
          <VuiBox mb={1}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} lg={shipTrainCardSize}>
                <ProductionCardShipUnload
                  title="Ship Unloading"
                  Ship_Week={TrainLoadData.weekProgress}
                  Ship_Month={TrainLoadData.monthProgress}
                  Ship_Total={TrainLoadData.yearProgress}
                  color="#FFA500"
                  unit="T"
                  maxWeekValue={TrainLoadData.maxWeekValue}
                  maxMonthValue={TrainLoadData.maxMonthValue}
                  maxYearValue={TrainLoadData.maxYearValue}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={shipTrainCardSize}>
                <ProductionCardShipLoad
                  title="Ship Loading"
                  Ship_Week={TrainLoadData.weekProgress}
                  Ship_Month={TrainLoadData.monthProgress}
                  Ship_Total={TrainLoadData.yearProgress}
                  color="#FFA500"
                  unit="T"
                  maxWeekValue={TrainLoadData.maxWeekValue}
                  maxMonthValue={TrainLoadData.maxMonthValue}
                  maxYearValue={TrainLoadData.maxYearValue}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={shipTrainCardSize}>
                <ProductionCardTrainLoad
                  title="Train Loading"
                  Ship_Week={TrainLoadData.weekProgress}
                  Ship_Month={TrainLoadData.monthProgress}
                  Ship_Total={TrainLoadData.yearProgress}
                  color="#FFA500"
                  unit="T"
                  maxWeekValue={TrainLoadData.maxWeekValue}
                  maxMonthValue={TrainLoadData.maxMonthValue}
                  maxYearValue={TrainLoadData.maxYearValue}
                />
              </Grid>
            </Grid>
          </VuiBox>
        )}

        <Footer />
      </div>
    </DashboardLayout>
  );
}

export default Dashboard;
