

/** 
  All of the routes are added here,
  You can add a new route, customize the routes and delete the routes here.

*/

//  React layouts
import Dashboard from "layouts/site-dashboard";
import DataExport from "layouts/data-export";
import Settings from "layouts/settings";
import AreaDashboard from "layouts/area-dashboard";
import PPM from "./layouts/PPM";
import SignIn from "layouts/authentication/sign-in";
import SignOut from "layouts/authentication/sign-out";
import SignUp from "layouts/authentication/sign-up";
import ForgotPassword from "layouts/authentication/forgot-password";
import ResetPassword from "layouts/authentication/reset-password";
import UserManagement from "./layouts/admin/UserManagement";
import CompanyManagement from "./layouts/admin/CompanyManagement";
import SiteManagement from "./layouts/admin/SiteManagement";
import GatewayManagement from "./layouts/admin/GatewayManagement";
import AdminProtectedRoute from "./AdminProtectedRoute"; // Add this import
import EngineerPage from "./layouts/PPM/EngineerPage"; 
import BreakdownTracker from "./layouts/Breakdown"
import SectionListPage from "./layouts/PPM/SectionListPage";

//  React icons
import { IoRocketSharp } from "react-icons/io5";
import { IoIosDocument } from "react-icons/io";
import { BsFillPersonFill } from "react-icons/bs";
import { IoBuild } from "react-icons/io5";
import { BsCreditCardFill } from "react-icons/bs";
import { IoStatsChart } from "react-icons/io5";
import { IoHome } from "react-icons/io5";
import HardwareIcon from '@mui/icons-material/Hardware';
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import HomeIcon from '@mui/icons-material/Home';
import StorageIcon from '@mui/icons-material/Storage';
import SettingsIcon from '@mui/icons-material/Settings';
import LoginIcon from '@mui/icons-material/Login';
import ViewListIcon from '@mui/icons-material/ViewList';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { Engineering } from "@mui/icons-material";
const routes = [
  {
    type: "collapse",
    name: "Site Dashboard",
    key: "site-dashboard",
    route: "/site-dashboard",
    icon: <HomeIcon size="15px" color="inherit" />,
    component: Dashboard,
    noCollapse: true,
    allowedRoles: [1, 2, 3], // Area Managers, Site Managers, and Admin

  },
  {
    type: "collapse",
    name: "Data Export",
    key: "data-export",
    route: "/data-export",
    icon: <StorageIcon size="15px" color="inherit" />,
    component: DataExport,
    noCollapse: true,
    allowedRoles: [1, 2, 3, 4], // Area Managers, Site Managers, and Admin

  },
  {
    type: "collapse",
    name: "Area Dashboard",
    key: "AreaDashboard",
    route: "/area-dashboard",
    icon: <CalendarViewMonthIcon size="15px" color="inherit" />,
    component: AreaDashboard,
    noCollapse: true,
    allowedRoles: [1, 3], // Area Managers and Admin

  },
  {
    type: "collapse",
    name: "PPM",
    key: "PPM",
    route: "/ppm",
    icon: <CalendarViewMonthIcon size="15px" color="inherit" />,
    component: PPM,
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "Breakdown",
    key: "breakdown",
    route: "/breakdown",
    icon: <HardwareIcon size="15px" color="inherit" />,
    component: BreakdownTracker,
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "Engineer Page",
    key: "engineer-page",
    route: "/engineer-page",
    icon: <Engineering size="15px" color="inherit" />,
    component: EngineerPage,
    noCollapse: true,
    allowedRoles: [4,3], // Only Engineers

  },
  
  {
    type: "collapse",
    name: "Section List",
    key: "section-list",
    route: "/ppm/section-list",
    icon: <ViewListIcon size="15px" color="inherit" />,
    component: SectionListPage,
    noCollapse: true,
    allowedRoles: [1,2,3,4], 

  },
  { type: "title", title: "Account Pages", key: "account-pages" },

  {
    type: "collapse",
    name: "Settings",
    key: "settings",
    route: "/settings",
    icon: <SettingsIcon size="15px" color="inherit" />,
    component: Settings,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Sign Out",
    key: "sign-out",
    route: "/sign-out",
    icon: <LoginIcon size="15px" color="inherit" />,
    component: SignOut,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    route: "/sign-in",
    icon: <LoginIcon size="15px" color="inherit" />,
    component: SignIn,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Forgot Password",
    key: "forgot-password",
    route: "/forgot-password",
    component: ForgotPassword,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Reset Password",
    key: "reset-password",
    route: "/reset-password",
    component: ResetPassword,
    noCollapse: true,
  },
  // Admin Routes (Protected)
  {
    type: "collapse",
    name: "Admin Users",
    key: "admin-users",
    route: "/admin/users",
    icon: <AdminPanelSettingsIcon size="15px" color="inherit" />,
    component: () => (
      <AdminProtectedRoute>
        <UserManagement />
      </AdminProtectedRoute>
    ),
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Admin Companies",
    key: "admin-companies",
    route: "/admin/companies",
    icon: <AdminPanelSettingsIcon size="15px" color="inherit" />,
    component: () => (
      <AdminProtectedRoute>
        <CompanyManagement />
      </AdminProtectedRoute>
    ),
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Admin Sites",
    key: "admin-sites",
    route: "/admin/sites",
    icon: <AdminPanelSettingsIcon size="15px" color="inherit" />,
    component: () => (
      <AdminProtectedRoute>
        <SiteManagement />
      </AdminProtectedRoute>
    ),
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Admin Gateways",
    key: "admin-gateways",
    route: "/admin/gateways",
    icon: <AdminPanelSettingsIcon size="15px" color="inherit" />,
    component: () => (
      <AdminProtectedRoute>
        <GatewayManagement />
      </AdminProtectedRoute>
    ),
    noCollapse: true,
  },
];

export default routes;
