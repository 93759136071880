import React, { useEffect, useState } from "react";
import { Card, Grid } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import SemiCircleProgress from "./SemiCircleProgress";
import CircularProgressWithCircle from "./CircularProgressWithCircle";
import PieChart from "./PieChart";
import ReactApexChart from "react-apexcharts";
import { groupBarChartOptions } from "../../data/groupBarChartOptions";
import { TimestreamQueryClient, QueryCommand } from "@aws-sdk/client-timestream-query";

const DynamicSiteCard = ({ site }) => {
  // Calculate percentages based on the site data
  const electricityPercentage = (site.Wasted_Elec / 10) * 100;
  const fuelPercentage = (site.Wasted_Fuel / 10) * 100;
  const unusedAggPercentage = (site.Wasted_Agg / 10000) * 100;

  // Grouped Bar Chart Series Data
  const [groupBarChartData, setGroupBarChartData] = useState({ series: [{ name: "", data: [] }] });
  useEffect(() => {
    const fetchTimestreamData = async () => {
      const timestreamClient = new TimestreamQueryClient({
        region: "us-east-1",
        credentials: {
          accessKeyId: "AKIARUIVQDAN4QY4OFNB",
          secretAccessKey: "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",
        },
      });

      const query = `
        SELECT 
          month(time) AS month,
          MAX(Bit_Total) AS Bit_Total,
          MAX(Asphalt_Month) AS Asphalt_Month,
          MAX(RAP_Total_Month) AS RAP_Total_Month,
          MAX(Fuel_Total) AS Fuel_Total
        FROM "Flexy205-dbv2"."gatewaydata"
        WHERE ThingName = '${site.gatewayName}' 
        GROUP BY month(time)
        ORDER BY month(time)
      `;

      try {
        const response = await timestreamClient.send(new QueryCommand({ QueryString: query }));
        const rows = response.Rows;

        const data = {
          Bit_Total: [],
          Asphalt_Month: [],
          RAP_Total_Month: [],
          Fuel_Total: [],
        };

        rows.forEach((row) => {
          const values = row.Data.map((d) => parseFloat(d?.ScalarValue || 0));
          data.Bit_Total.push(values[1] || 0);
          data.Asphalt_Month.push(values[2] || 0);
          data.RAP_Total_Month.push(values[3] || 0);
          data.Fuel_Total.push(values[4] || 0);
        });

        setGroupBarChartData({
          series: [
            { name: "Bitumen", data: data.Bit_Total },
            { name: "Asphalt", data: data.Asphalt_Month },
            { name: "RAP", data: data.RAP_Total_Month },
            { name: "Fuel", data: data.Fuel_Total },
          ],
        });
      } catch (error) {
        console.error("Error fetching Timestream data:", error.message);
      }
    };

    fetchTimestreamData();
  }, [site.gatewayName]);

  return (
    <Card>
      <VuiBox p={1}>
        {/* Row 1 */}
        <Grid container spacing={0} sx={{ marginBottom: 0 }}>
          <Grid item xs={12}>
            <VuiTypography variant="h6" color="white">
              {site.name}
            </VuiTypography>
          </Grid>
          <Grid container item xs={12} spacing={1} sx={{ marginBottom: 0 }}>
            {/* Asphalt */}
            <Grid item xs={3}>
              <VuiTypography variant="body2" style={{ color: "#2C7DA0" }} align="center">
                Asphalt
              </VuiTypography>
              <VuiTypography color="white" align="center">
                {parseFloat(site.Asphalt_Day).toFixed(2)}{" "}
                <VuiTypography variant="caption" color="#747474" display="inline">
                  t
                </VuiTypography>{" "}
              </VuiTypography>
            </Grid>
            {/* RAP */}
            <Grid item xs={3}>
              <VuiTypography variant="body2" style={{ color: "#5A6ACF" }} align="center">
                RAP
              </VuiTypography>
              <VuiTypography color="white" align="center">
                {site.RAP_Total_Day}{" "}
                <VuiTypography variant="caption" color="#747474" display="inline">
                  t
                </VuiTypography>{" "}
              </VuiTypography>
            </Grid>
            {/* Fuel */}
            <Grid item xs={3}>
              <VuiTypography variant="body2" style={{ color: "#89C2D9" }} align="center">
                Fuel
              </VuiTypography>
              <VuiTypography color="white" align="center">
                {site.Fuel_Total > 9999
                  ? `${(site.Fuel_Total / 1000).toFixed(2)}`
                  : site.Fuel_Total}{" "}
                <VuiTypography variant="caption" color="#747474" display="inline">
                  {site.Fuel_Total > 9999 ? "kL" : "L"}
                </VuiTypography>
              </VuiTypography>
            </Grid>
            {/* Gas Per Ton */}
            <Grid item xs={3}>
              <VuiTypography variant="body2" style={{ color: "#BBD0FF" }} align="center">
                Gas Per Ton
              </VuiTypography>
              <VuiTypography color="white" align="center">
                {site.Gas_Per_Ton}{" "}
                <VuiTypography variant="caption" color="#747474" display="inline">
                  L
                </VuiTypography>{" "}
              </VuiTypography>
            </Grid>
          </Grid>
        </Grid>

        {/* Row 2 */}
        <Grid container spacing={0} sx={{ marginBottom: 0 }}>
          <Grid item xs={2} sm={4} md={4}>
            <VuiTypography variant="body2" style={{ color: "#CAB773" }} align="center">
              Electricity
            </VuiTypography>
            <VuiBox display="flex" justifyContent="center" sx={{ marginBottom: 1 }}>
              <SemiCircleProgress
                strokeWidth={6}
                percentage={site.Elec_Per_Ton}
                value={site.Elec_Per_Ton}
                unitType="Electricity"
                strokeColor="#CAB773"
                size={{ width: 120, height: 60 }}
              />
            </VuiBox>

            <VuiTypography variant="body2" style={{ color: "#C63C51" }} align="center">
              Fuel
            </VuiTypography>
            <VuiBox display="flex" justifyContent="center" sx={{ marginBottom: 1 }}>
              <SemiCircleProgress
                strokeWidth={6}
                percentage={site.Fuel_Per_Ton}
                value={site.Fuel_Total}
                unitType="Fuel"
                strokeColor="#C63C51"
                size={{ width: 120, height: 60 }}
              />
            </VuiBox>

            <VuiTypography variant="body2" style={{ color: "#73CAC5" }} align="center">
              Gas
            </VuiTypography>
            <VuiBox display="flex" justifyContent="center" sx={{ marginBottom: 1 }}>
              <SemiCircleProgress
                strokeWidth={6}
                percentage={site.Gas_Per_Ton}
                value={site.Gas_Per_Ton}
                unitType="Gas"
                strokeColor="#73CAC5"
                size={{ width: 120, height: 60 }}
              />
            </VuiBox>
          </Grid>
          <Grid item xs={2} sm={4} md={4} position="relative">
            {/* Circle progress bars arranged with electricity and fuel aligned vertically */}
            <VuiBox position="relative" height={150} width={150}>
              <VuiBox position="absolute" top={0} left="30%" transform="translateX(-50%)">
                <CircularProgressWithCircle
                  progress={fuelPercentage}
                  title="Fuel"
                  value={site.Wasted_Fuel}
                  color="#F0A8D0"
                  size={100}
                />
              </VuiBox>

              <VuiBox position="absolute" top={0} left="0">
                <CircularProgressWithCircle
                  progress={electricityPercentage}
                  title="Electricity"
                  value={site.Wasted_Elec}
                  color="#C63CA8"
                  size={80}
                />
              </VuiBox>

              <VuiBox position="absolute" top="60%" left="10%" right="0">
                <CircularProgressWithCircle
                  progress={unusedAggPercentage}
                  title="Unused Agg."
                  value={site.Wasted_Agg}
                  color="#5E5CE6"
                  size={130}
                />
              </VuiBox>
            </VuiBox>
          </Grid>
          {/* Pie Chart */}
          <Grid item xs={2} sm={4} md={4} sx={{ height: "50%" }}>
            <PieChart
              Production_Hrs={site.Production_Hrs}
              Idle_Hrs={site.Idle_Hrs}
              Breakdown_Hrs={site.Breakdown_Hrs}
              Offline_Hrs={site.Offline_Hrs}
            />
          </Grid>
        </Grid>

        {/* Row 3: Grouped Bar Chart */}
        <Grid container spacing={0} sx={{ marginTop: 0 }}>
          <Grid item xs={12} sx={{ width: "100%" }}>
            <ReactApexChart
              options={groupBarChartOptions}
              series={groupBarChartData.series}
              type="bar"
            />
          </Grid>
        </Grid>
      </VuiBox>
    </Card>
  );
};

export default DynamicSiteCard;
