import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import VuiBox from 'components/VuiBox';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale, LinearScale } from 'chart.js';
import colors from 'assets/theme/base/colors';

// Register chart components
ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale, LinearScale);

const PieChart = ({ Production_Hrs, Idle_Hrs, Breakdown_Hrs, Offline_Hrs }) => {
  const { gradients } = colors;
  const { cardContent } = gradients;

  const data = {
    labels: ['Office Hours', 'Production Hours', 'Idle Hours', 'Breakdown Hours'],
    datasets: [{
      label: 'Hours',
      data: [Idle_Hrs, Breakdown_Hrs, Offline_Hrs, Production_Hrs],
      backgroundColor: ['#BDC3FF', '#C7EEFF', '#1F316F', '#0077C0'],
      borderColor: '#ffffff',
      borderWidth: 1,
    }],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 30,
        bottom: 10,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const label = context.label || '';
            const value = context.raw || 0;
            return `${label}: ${value} hours`;
          }
        }
      }
    }
  };

  // Media queries for responsiveness
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme => theme.breakpoints.between('sm', 'md'));

  return (
    <VuiBox sx={{
        width: '100%', // makes the Box take the full width
        height: '100%', // height will adjust based on content
        margin: '0 auto', // center the Box horizontally
      }}
    >
      <Pie data={data} options={options} />
    </VuiBox>
  );
};

export default PieChart;