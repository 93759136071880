import React from "react";
import { Card, useMediaQuery, useTheme } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import SemiCircleProgress from "./SemiCircleProgress";
import colors from "assets/theme/base/colors";
import linearGradient from "assets/theme/functions/linearGradient";

const ProductionCardShipUnload = ({
  title,
  Ship_Week,
  Ship_Month,
  Ship_Total,
  color,
  unit,
  maxDayValue,
  maxWeekValue,
  maxMonthValue,
  maxYearValue,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  const { gradients } = colors;
  const { cardContent } = gradients;

  const InfoBox = ({ progress, label, sx }) => (
    <VuiBox
      sx={{
        width: isSmallScreen ? "90px" : "120px",
        padding: isSmallScreen ? "4px 8px" : "8px 12px",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        borderRadius: "8px",
        background: linearGradient(cardContent.main, cardContent.state, cardContent.deg),
        ...sx,
      }}
    >
      <VuiTypography color="text" variant="caption" display="inline-block">
        0%
      </VuiTypography>
      <VuiBox
        flexDirection="column"
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ minWidth: isSmallScreen ? "30px" : "50px" }}
      >
        <VuiTypography color="white" variant="h5" sx={{ fontSize: { xs: "0.8rem", sm: "1rem" } }}>
          {Math.round(progress)} {unit}
        </VuiTypography>
        <VuiTypography color="text" variant="caption" display="inline-block">
          {label}
        </VuiTypography>
      </VuiBox>
      <VuiTypography color="text" variant="caption" display="inline-block">
        100%
      </VuiTypography>
    </VuiBox>
  );

  return (
    <Card
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        padding: 1,
        paddingBottom: 0,
      }}
    >
      <VuiBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
        p={1}
        sx={{ paddingBottom: 0 }}
      >
        <VuiTypography
          variant="sm"
          color="white"
          fontWeight="bold"
          sx={{
            mb: 0.5,
            fontSize: isSmallScreen ? "0.9rem" : isMediumScreen ? "1rem" : "1.2rem",
          }}
        >
          {title}
        </VuiTypography>

        {/* Single Row Layout for Week, Month, Year Progress with increased gaps */}
        <VuiBox
          display="flex"
          flexDirection={isSmallScreen ? "column" : "row"}
          justifyContent={isSmallScreen ? "center" : "space-around"}
          alignItems="center"
          gap={isSmallScreen ? 1 : 2}  // Increased gap between components
          sx={{ paddingBottom: 2, paddingTop: 1 }}
        >
          {/* Week Progress */}
          <VuiBox sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <SemiCircleProgress
              percentage={Math.round(Ship_Week)}
              size={{
                width: isSmallScreen ? 100 : 110,
                height: isSmallScreen ? 100 : 110,
              }}
              strokeWidth={6}
              strokeColor={color}
            />
            <InfoBox
              progress={Math.round(maxWeekValue) || 0}
              label="Week"
              sx={{ marginTop: isSmallScreen ? "-40px" : "-45px" }}
            />
          </VuiBox>

          {/* Month Progress */}
          <VuiBox sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <SemiCircleProgress
              percentage={Math.round(Ship_Month) || 0}
              size={{
                width: isSmallScreen ? 100 : 110,
                height: isSmallScreen ? 100 : 110,
              }}
              strokeWidth={6}
              strokeColor={color}
            />
            <InfoBox
              progress={Math.round(maxMonthValue) || 0}
              label="Month"
              sx={{ marginTop: isSmallScreen ? "-40px" : "-45px" }}
            />
          </VuiBox>

          {/* Year Progress */}
          <VuiBox sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <SemiCircleProgress
              percentage={Math.round(Ship_Total) || 0}
              size={{
                width: isSmallScreen ? 100 : 110,
                height: isSmallScreen ? 100 : 110,
              }}
              strokeWidth={6}
              strokeColor={color}
            />
            <InfoBox
              progress={Math.round(maxYearValue) || 0}
              label="Year"
              sx={{ marginTop: isSmallScreen ? "-40px" : "-45px" }}
            />
          </VuiBox>
        </VuiBox>
      </VuiBox>
    </Card>
  );
};


export default ProductionCardShipUnload;