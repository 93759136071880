import React, { useState, useEffect } from "react";
import {
  Card,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Snackbar,
  Alert,
  Box,
  Drawer,
} from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";
import VuiTypography from "components/VuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Table from "examples/Tables/Table";
import VuiPagination from "components/VuiPagination";
import Icon from "@mui/material/Icon";
import axios from "axios";

function GatewayManagement() {
  const [gateways, setGateways] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [addGatewayOpen, setAddGatewayOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [selectedGateway, setSelectedGateway] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });
  const [newGateway, setNewGateway] = useState({ name: "", site_id: "" });

  // Calculate pagination variables
  const totalPages = Math.ceil(gateways.length / rowsPerPage);
  const displayedRows = gateways.slice((page - 1) * rowsPerPage, page * rowsPerPage);

  useEffect(() => {
    fetchGateways();
  }, []);

  const fetchGateways = async () => {
    try {
      setLoading(true);
      const response = await axios.get("https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/admin/gateways");
      setGateways(response.data);
    } catch (error) {
      showSnackbar("Error fetching gateways", "error");
    } finally {
      setLoading(false);
    }
  };

  const showSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  const handleAddGateway = async () => {
    try {
      await axios.post("https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/gateway/create", newGateway);
      showSnackbar("Gateway added successfully", "success");
      setAddGatewayOpen(false);
      setNewGateway({ name: "", site_id: "" });
      fetchGateways();
    } catch (error) {
      showSnackbar("Error adding gateway", "error");
    }
  };

  const handleEditClick = (gateway) => {
    setSelectedGateway({ ...gateway });
    setDrawerOpen(true);
  };

  const handleUpdateGateway = async () => {
    try {
      await axios.put(
        `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/gateway/update`,
        {
          gateway_id: selectedGateway.gateway_id,
          name: selectedGateway.name,
          site_id: selectedGateway.site_id
        }
      );
      
      showSnackbar("Gateway updated successfully", "success");
      setDrawerOpen(false);
      fetchGateways();
    } catch (error) {
      showSnackbar("Error updating gateway", "error");
    }
  };

  const handleDeleteGateway = async () => {
    try {
      await axios.delete(`https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/admin/gateways/delete?gateway_id=${selectedGateway.gateway_id}`);
      showSnackbar("Gateway deleted successfully", "success");
      setDeleteConfirmOpen(false);
      fetchGateways();
    } catch (error) {
      showSnackbar("Error deleting gateway", "error");
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <Card>
          <VuiBox display="flex" justifyContent="space-between" alignItems="center" p={2}>
            <VuiTypography variant="lg" color="white">
              Gateway Management
            </VuiTypography>
            <VuiButton color="info" onClick={() => setAddGatewayOpen(true)}>
              Add Gateway
            </VuiButton>
          </VuiBox>

          {loading ? (
            <VuiBox display="flex" justifyContent="center" py={4}>
              <CircularProgress />
            </VuiBox>
          ) : (
            <Table
              columns={[
                { name: "Name", align: "left" },
                { name: "Site ID", align: "left" },
                { name: "Actions", align: "center" }
              ]}
              rows={displayedRows.map((gateway) => ({
                Name: gateway.name,
                "Site ID": gateway.site_id,
                Actions: (
                  <VuiBox display="flex" gap={1} justifyContent="flex-end">
                    <VuiButton
                      color="info"
                      size="small"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleEditClick(gateway);
                      }}
                    >
                      <Icon>edit</Icon>
                    </VuiButton>
                    <VuiButton
                      color="error"
                      size="small"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setSelectedGateway(gateway);
                        setDeleteConfirmOpen(true);
                      }}
                    >
                      <Icon>delete</Icon>
                    </VuiButton>
                  </VuiBox>
                ),
              }))}
            />
          )}

          <VuiBox display="flex" justifyContent="center" p={2}>
            <VuiPagination>
              <VuiPagination item onClick={() => setPage(page - 1)} disabled={page === 1}>
                <Icon>keyboard_arrow_left</Icon>
              </VuiPagination>
              {Array.from({ length: totalPages }, (_, index) => (
                <VuiPagination
                  key={index + 1}
                  item
                  active={page === index + 1}
                  onClick={() => setPage(index + 1)}
                >
                  {index + 1}
                </VuiPagination>
              ))}
              <VuiPagination item onClick={() => setPage(page + 1)} disabled={page === totalPages}>
                <Icon>keyboard_arrow_right</Icon>
              </VuiPagination>
            </VuiPagination>
          </VuiBox>
        </Card>
      </VuiBox>

      {/* Add Gateway Dialog */}
      <Dialog open={addGatewayOpen} onClose={() => setAddGatewayOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Add New Gateway</DialogTitle>
        <DialogContent>
          <VuiBox component="form" display="flex" flexDirection="column" gap={2} p={2}>
            <TextField
              label="Name"
              value={newGateway.name}
              onChange={(e) => setNewGateway({ ...newGateway, name: e.target.value })}
              fullWidth
            />
            <TextField
              label="Site ID"
              value={newGateway.site_id}
              onChange={(e) => setNewGateway({ ...newGateway, site_id: e.target.value })}
              fullWidth
            />
          </VuiBox>
        </DialogContent>
        <DialogActions>
          <VuiButton color="error" onClick={() => setAddGatewayOpen(false)}>
            Cancel
          </VuiButton>
          <VuiButton color="info" onClick={handleAddGateway}>
            Add Gateway
          </VuiButton>
        </DialogActions>
      </Dialog>

      {/* Edit Gateway Drawer */}
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => {
          setDrawerOpen(false);
          setSelectedGateway(null);
        }}
        PaperProps={{
          sx: { width: "400px" }
        }}
      >
        {selectedGateway && (
          <VuiBox p={3}>
            <VuiTypography variant="h6" mb={3}>
              Edit Gateway
            </VuiTypography>
            <VuiBox display="flex" flexDirection="column" gap={2}>
              <TextField
                label="Name"
                value={selectedGateway.name}
                onChange={(e) => setSelectedGateway({ ...selectedGateway, name: e.target.value })}
                fullWidth
              />
              <TextField
                label="Site ID"
                value={selectedGateway.site_id}
                onChange={(e) => setSelectedGateway({ ...selectedGateway, site_id: e.target.value })}
                fullWidth
              />
              <VuiBox display="flex" gap={2} mt={2}>
                <VuiButton
                  color="error"
                  fullWidth
                  onClick={() => {
                    setDrawerOpen(false);
                    setSelectedGateway(null);
                  }}
                >
                  Cancel
                </VuiButton>
                <VuiButton color="info" fullWidth onClick={handleUpdateGateway}>
                  Save Changes
                </VuiButton>
              </VuiBox>
            </VuiBox>
          </VuiBox>
        )}
      </Drawer>

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteConfirmOpen} onClose={() => setDeleteConfirmOpen(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <VuiTypography>
            Are you sure you want to delete gateway {selectedGateway?.name}?
          </VuiTypography>
        </DialogContent>
        <DialogActions>
          <VuiButton color="info" onClick={() => setDeleteConfirmOpen(false)}>
            Cancel
          </VuiButton>
          <VuiButton color="error" onClick={handleDeleteGateway}>
            Delete
          </VuiButton>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity={snackbar.severity} onClose={() => setSnackbar({ ...snackbar, open: false })}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </DashboardLayout>
  );
}

export default GatewayManagement;