import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  Chip,
  TextField as MuiTextField,
  Card,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Drawer,
  Alert,
  Snackbar,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
} from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";
import VuiTypography from "components/VuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Table from "examples/Tables/Table";
import VuiPagination from "components/VuiPagination";
import Icon from "@mui/material/Icon";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

// Add this near other icons
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
function SiteManagement() {
  const [siteData, setSiteData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(12);
  const [addSiteOpen, setAddSiteOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [selectedSite, setSelectedSite] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });
  const dashboardOptions = [
    "Asphalt",
    "RAP",
    "Raw Material",
    "Sand",
    "Graded",
    "Unused Agg.",
    "Gas Per Tonnage",
    "Burner Idle Fuel",
    "Fuel per Tonnage",
    "Electricity per Tonnage",
    "Electricity Offline",
    "Ship Unloading",
    "Ship Loading",
    "Train Loading",
    "Batch Quality",
    "PPM Summary"
  ];

  const [newSiteData, setNewSiteData] = useState({
    name: "",
    company_id: "",
    address: "",
    street: "",
    address_county: "",
    address_postcode: "",
    annual_total: "",
    work_days_per_week: "",
    dashboard_config: [], // Make sure it's initialized as empty array
  });

  const token = localStorage.getItem("token");

  // Calculate pagination variables
  const totalPages = Math.ceil(siteData.length / rowsPerPage);
  const displayedRows = siteData.slice((page - 1) * rowsPerPage, page * rowsPerPage);

  // Table columns definition
  const columns = [
    { name: "Site ID", align: "left" },
    { name: "Name", align: "left" },
    { name: "Address", align: "left" },
    { name: "County", align: "center" },
    { name: "Postcode", align: "center" },
    { name: "Annual Total", align: "center" },
    { name: "Work Days", align: "center" },
    { name: "Dashboard Config", align: "left" },
    { name: "Actions", align: "center" },
  ];

  useEffect(() => {
    fetchSites();
  }, []);

  const fetchSites = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/admin/sites`,
        {
          method: "GET",
          headers: { Authorization: `${token}` },
        }
      );
      const data = await response.json();
      const formattedData = data.map((site) => ({
        ...site,
        dashboard_config: Array.isArray(site.dashboard_config)
          ? site.dashboard_config
          : site.dashboard_config
          ? JSON.parse(site.dashboard_config)
          : [],
      }));
      setSiteData(formattedData);
    } catch (error) {
      showSnackbar("Error fetching sites", "error");
    } finally {
      setLoading(false);
    }
  };

  const handleEditClick = (site) => {
    // Make sure dashboard_config is properly formatted as an array
    setSelectedSite({
      ...site,
      dashboard_config: Array.isArray(site.dashboard_config)
        ? site.dashboard_config
        : site.dashboard_config
        ? JSON.parse(site.dashboard_config)
        : [],
    });
    setDrawerOpen(true);
  };

  const handleDeleteClick = (site) => {
    setSelectedSite(site);
    setDeleteConfirmOpen(true);
  };

  const showSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  const handleAddSite = async () => {
    try {
      // Ensure dashboard_config is correctly formatted for API
      const siteDataToSubmit = {
        ...newSiteData,
        dashboard_config: newSiteData.dashboard_config // Make sure it's properly formatted
      };
      
      const response = await fetch(
        "https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/site/create",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify(siteDataToSubmit),
        }
      );

      if (response.ok) {
        showSnackbar("Site added successfully", "success");
        setAddSiteOpen(false);
        setNewSiteData({
          name: "",
          company_id: "",
          address: "",
          street: "",
          address_county: "",
          address_postcode: "",
          annual_total: "",
          work_days_per_week: "",
          dashboard_config: [], // Reset to empty array
        });
        await fetchSites();
      } else {
        showSnackbar("Failed to add site", "error");
      }
    } catch (error) {
      showSnackbar("Error adding site", "error");
    }
  };

  const handleUpdateSite = async () => {
    try {
      const response = await fetch(
        `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/site/update`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify(selectedSite),
        }
      );

      if (response.ok) {
        showSnackbar("Site updated successfully", "success");
        setDrawerOpen(false);
        await fetchSites();
      } else {
        showSnackbar("Failed to update site", "error");
      }
    } catch (error) {
      showSnackbar("Error updating site", "error");
    }
  };

  const handleDeleteSite = async () => {
    try {
      const response = await fetch(
        `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/admin/sites/delete?site_id=${selectedSite.site_id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `${token}`,
          },
        }
      );

      if (response.ok) {
        showSnackbar("Site deleted successfully", "success");
        setDeleteConfirmOpen(false);
        await fetchSites();
      } else {
        showSnackbar("Failed to delete site", "error");
      }
    } catch (error) {
      showSnackbar("Error deleting site", "error");
    }
  };

  const rows = displayedRows.map((site) => ({
    "Site ID": site.site_id,
    Name: site.name,
    Address: `${site.address} ${site.street}`,
    County: site.address_county,
    Postcode: site.address_postcode,
    "Annual Total": site.annual_total,
    "Work Days": site.work_days_per_week,
    "Dashboard Config": Array.isArray(site.dashboard_config) && site.dashboard_config.length 
      ? site.dashboard_config.join(", ") 
      : "N/A",

    Actions: (
      <VuiBox display="flex" gap={1} justifyContent="flex-end">
        <VuiButton
          color="info"
          size="small"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleEditClick(site);
          }}
        >
          <Icon>edit</Icon>
        </VuiButton>
        <VuiButton
          color="error"
          size="small"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleDeleteClick(site);
          }}
        >
          <Icon>delete</Icon>
        </VuiButton>
      </VuiBox>
    ),
  }));

  // Custom styles for the select input - added to make checkbox items visible
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
// Update both dialog Select components to ensure proper checkbox display:
const renderDashboardSelect = (selectedValues, handleChange) => (
  <FormControl fullWidth>
    <InputLabel>Dashboard Configuration</InputLabel>
    <Select
      multiple
      value={selectedValues}
      onChange={handleChange}
      renderValue={(selected) => selected.join(", ")}
      MenuProps={{
        PaperProps: {
          style: {
            maxHeight: 300, // Ensure dropdown has scroll for many options
            backgroundColor: '#1a2035', // Match dark theme
            color: 'white'
          },
        },
      }}
    >
      {dashboardOptions.map((option) => (
        <MenuItem key={option} value={option}>
          <Checkbox 
            checked={selectedValues.includes(option)} 
            color="primary" 
            sx={{
              color: 'white',
              '&.Mui-checked': {
                color: 'rgb(0, 127, 255)',
              },
            }}
          />
          <ListItemText 
            primary={option} 
            primaryTypographyProps={{ color: 'white' }} 
          />
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <Card>
          <VuiBox display="flex" justifyContent="space-between" alignItems="center" p={2}>
            <VuiTypography variant="lg" color="white">
              Site Management
            </VuiTypography>
            <VuiButton color="info" onClick={() => setAddSiteOpen(true)}>
              Add Site
            </VuiButton>
          </VuiBox>

          {loading ? (
            <VuiBox display="flex" justifyContent="center" py={4}>
              <CircularProgress />
            </VuiBox>
          ) : (
            <Table columns={columns} rows={rows} />
          )}

          <VuiBox display="flex" justifyContent="center" p={2}>
            <VuiPagination>
              <VuiPagination item onClick={() => setPage(page - 1)} disabled={page === 1}>
                <Icon>keyboard_arrow_left</Icon>
              </VuiPagination>
              {Array.from({ length: totalPages }, (_, index) => (
                <VuiPagination
                  key={index + 1}
                  item
                  active={page === index + 1}
                  onClick={() => setPage(index + 1)}
                >
                  {index + 1}
                </VuiPagination>
              ))}
              <VuiPagination item onClick={() => setPage(page + 1)} disabled={page === totalPages}>
                <Icon>keyboard_arrow_right</Icon>
              </VuiPagination>
            </VuiPagination>
          </VuiBox>
        </Card>
      </VuiBox>

      {/* Add Site Dialog */}
      <Dialog open={addSiteOpen} onClose={() => setAddSiteOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Add New Site</DialogTitle>
        <DialogContent>
          <VuiBox component="form" display="flex" flexDirection="column" gap={2} p={2}>
            <TextField
              label="Name"
              value={newSiteData.name}
              onChange={(e) => setNewSiteData({ ...newSiteData, name: e.target.value })}
              fullWidth
            />
            <TextField
              label="Company ID"
              value={newSiteData.company_id}
              onChange={(e) => setNewSiteData({ ...newSiteData, company_id: e.target.value })}
              fullWidth
            />
            <TextField
              label="Address"
              value={newSiteData.address}
              onChange={(e) => setNewSiteData({ ...newSiteData, address: e.target.value })}
              fullWidth
            />
            <TextField
              label="Street"
              value={newSiteData.street}
              onChange={(e) => setNewSiteData({ ...newSiteData, street: e.target.value })}
              fullWidth
            />
            <TextField
              label="County"
              value={newSiteData.address_county}
              onChange={(e) => setNewSiteData({ ...newSiteData, address_county: e.target.value })}
              fullWidth
            />
            <TextField
              label="Postcode"
              value={newSiteData.address_postcode}
              onChange={(e) => setNewSiteData({ ...newSiteData, address_postcode: e.target.value })}
              fullWidth
            />
            <TextField
              label="Annual Total"
              type="number"
              value={newSiteData.annual_total}
              onChange={(e) => setNewSiteData({ ...newSiteData, annual_total: e.target.value })}
              fullWidth
            />
            <TextField
              label="Work Days Per Week"
              type="number"
              value={newSiteData.work_days_per_week}
              onChange={(e) =>
                setNewSiteData({ ...newSiteData, work_days_per_week: e.target.value })
              }
              fullWidth
            />
            <Autocomplete
        multiple
        options={dashboardOptions}
        value={newSiteData.dashboard_config}
        onChange={(_, newValue) => {
          setNewSiteData({ ...newSiteData, dashboard_config: newValue });
        }}
        disableCloseOnSelect
        renderInput={(params) => (
          <MuiTextField
            {...params}
            label="Dashboard Configuration"
            placeholder="Select options"
          />
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              {...getTagProps({ index })}
              key={option}
              label={option}
              sx={{ color: "white", bgcolor: "secondary.main" }}
            />
          ))
        }
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option}
          </li>
        )}
      />
          </VuiBox>
        </DialogContent>
        <DialogActions>
          <VuiButton color="error" onClick={() => setAddSiteOpen(false)}>
            Cancel
          </VuiButton>
          <VuiButton color="info" onClick={handleAddSite}>
            Add Site
          </VuiButton>
        </DialogActions>
      </Dialog>

      {/* Edit Site Drawer */}
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => {
          setDrawerOpen(false);
          setSelectedSite(null);
        }}
        PaperProps={{
          sx: { width: "400px" },
        }}
      >
        {selectedSite && (
          <VuiBox p={3}>
            <VuiTypography variant="h6" mb={3}>
              Edit Site
            </VuiTypography>
            <VuiBox display="flex" flexDirection="column" gap={2}>
              <TextField
                label="Name"
                value={selectedSite.name}
                onChange={(e) => setSelectedSite({ ...selectedSite, name: e.target.value })}
                fullWidth
              />
              <TextField
                label="Address"
                value={selectedSite.address}
                onChange={(e) => setSelectedSite({ ...selectedSite, address: e.target.value })}
                fullWidth
              />
              <TextField
                label="Street"
                value={selectedSite.street}
                onChange={(e) => setSelectedSite({ ...selectedSite, street: e.target.value })}
                fullWidth
              />
              <TextField
                label="County"
                value={selectedSite.address_county}
                onChange={(e) =>
                  setSelectedSite({ ...selectedSite, address_county: e.target.value })
                }
                fullWidth
              />
              <TextField
                label="Postcode"
                value={selectedSite.address_postcode}
                onChange={(e) =>
                  setSelectedSite({ ...selectedSite, address_postcode: e.target.value })
                }
                fullWidth
              />
              <TextField
                label="Annual Total"
                type="number"
                value={selectedSite.annual_total}
                onChange={(e) => setSelectedSite({ ...selectedSite, annual_total: e.target.value })}
                fullWidth
              />
              <TextField
                label="Work Days Per Week"
                type="number"
                value={selectedSite.work_days_per_week}
                onChange={(e) =>
                  setSelectedSite({ ...selectedSite, work_days_per_week: e.target.value })
                }
                fullWidth
              />
              <Autocomplete
        multiple
        options={dashboardOptions}
        value={selectedSite.dashboard_config}
        onChange={(_, newValue) => {
          setSelectedSite({ ...selectedSite, dashboard_config: newValue });
        }}
        disableCloseOnSelect
        renderInput={(params) => (
          <MuiTextField
            {...params}
            label="Dashboard Configuration"
            placeholder="Select options"
          />
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              {...getTagProps({ index })}
              key={option}
              label={option}
              sx={{ color: "white", bgcolor: "secondary.main" }}
            />
          ))
        }
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option}
          </li>
        )}
      />

              <VuiBox display="flex" gap={2} mt={2}>
                <VuiButton
                  color="error"
                  fullWidth
                  onClick={() => {
                    setDrawerOpen(false);
                    setSelectedSite(null);
                  }}
                >
                  Cancel
                </VuiButton>
                <VuiButton color="info" fullWidth onClick={handleUpdateSite}>
                  Save Changes
                </VuiButton>
              </VuiBox>
            </VuiBox>
          </VuiBox>
        )}
      </Drawer>

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteConfirmOpen} onClose={() => setDeleteConfirmOpen(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <VuiTypography>Are you sure you want to delete site {selectedSite?.name}?</VuiTypography>
        </DialogContent>
        <DialogActions>
          <VuiButton color="info" onClick={() => setDeleteConfirmOpen(false)}>
            Cancel
          </VuiButton>
          <VuiButton color="error" onClick={handleDeleteSite}>
            Delete
          </VuiButton>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          severity={snackbar.severity}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </DashboardLayout>
  );
}

export default SiteManagement;