import { Navigate } from "react-router-dom";

const AdminProtectedRoute = ({ children }) => {
  const roleId = parseInt(localStorage.getItem("role_id"), 10);

  if (roleId === 3) {
    return children; // Admin has access
  }

  // Redirect non-admin users based on their role
  switch (roleId) {
    case 1:
      return <Navigate to="/area-dashboard" replace />;
    case 2:
      return <Navigate to="/site-dashboard" replace />;
    case 4:
      return <Navigate to="/engineer-page" replace />;
    default:
      return <Navigate to="/sign-in" replace />;
  }
};

export default AdminProtectedRoute;