import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Avatar,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

function ProfileSettings() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [currentEmail, setCurrentEmail] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [userId, setUserId] = useState(""); // Added user ID state
  const [passwordValid, setPasswordValid] = useState({
    length: false,
    capital: false,
    specialChar: false,
  });
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const [originalData, setOriginalData] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });

  const token = localStorage.getItem("token");
  const storedEmail = localStorage.getItem("email");

  useEffect(() => {
    if (storedEmail && token) {
      setCurrentEmail(storedEmail);
      fetchProfile(storedEmail, token);
    } else {
      console.error("Email or token not found in localStorage");
      setLoading(false);
    }
  }, [storedEmail, token]);

  const fetchProfile = async (email, token) => {
    try {
      const response = await fetch(
        `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/user/profile?email=${email.toLowerCase()}`,
        {
          method: "GET",
          headers: { Authorization: `${token}` },
        }
      );
      const data = await response.json();

      if (data) {
        setFirstName(data.first_name || "");
        setLastName(data.last_name || "");
        setUserId(data.user_id || ""); // Set the user ID from response
        setOriginalData({
          firstName: data.first_name || "",
          lastName: data.last_name || "",
          email: email.toLowerCase(),
        });
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching profile:", error);
      setLoading(false);
    }
  };

  const updateUserProfile = async () => {
    if (!userId) {
      setModalMessage("User identification error. Please try again later.");
      setModalOpen(true);
      return;
    }

    // Validate passwords if they're being changed
    if (password) {
      if (password !== confirmPassword) {
        setModalMessage("Passwords do not match!");
        setModalOpen(true);
        return;
      }

      if (!(passwordValid.length && passwordValid.capital && passwordValid.specialChar)) {
        setModalMessage("Password doesn't meet the required criteria.");
        setModalOpen(true);
        return;
      }
    }

    // Check if any changes were made
    const isChangesMade =
      firstName !== originalData.firstName ||
      lastName !== originalData.lastName ||
      (newEmail && newEmail.toLowerCase() !== originalData.email) ||
      password !== "";

    if (!isChangesMade) {
      setModalMessage("No changes were made.");
      setModalOpen(true);
      return;
    }

    try {
      const payload = {
        id: userId, // Include user ID in the payload
        email: currentEmail.toLowerCase(), // Current email in lowercase
        first_name: firstName || undefined,
        last_name: lastName || undefined,
        ...(password && { password: password }),
        ...(newEmail && { new_email: newEmail.toLowerCase() }) // New email in lowercase
      };

      const response = await fetch(
        "https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/user/update/profile",
        {
          method: "PUT",
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      const data = await response.json();

      if (response.ok) {
        setModalMessage(data.message || "Profile updated successfully!");
        if (newEmail) {
          // Update stored email if it was changed
          localStorage.setItem("email", newEmail.toLowerCase());
          setCurrentEmail(newEmail.toLowerCase());
          setOriginalData(prev => ({
            ...prev,
            email: newEmail.toLowerCase()
          }));
          setNewEmail("");
        }
      } else {
        setModalMessage(data.error || "Failed to update profile.");
      }
      setModalOpen(true);
    } catch (error) {
      console.error("Error updating profile:", error);
      setModalMessage("An error occurred while updating your profile.");
      setModalOpen(true);
    }
  };

  const validatePassword = (password) => {
    const validations = {
      length: password.length >= 8,
      capital: /[A-Z]/.test(password),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    };
    setPasswordValid(validations);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <DashboardLayout sx={{ height: "100vh", width: "100vw" }}>
      <DashboardNavbar />
      <Container
        maxWidth="sm"
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <VuiBox
          component="form"
          role="form"
          sx={{ width: "100%" }}
          onSubmit={(e) => {
            e.preventDefault();
            updateUserProfile();
          }}
        >
          <VuiBox mb={2} textAlign="center">
            <Avatar
              alt="Profile Picture"
              src="/static/images/avatar/1.jpg"
              sx={{ width: 100, height: 100, margin: "0 auto" }}
            />
          </VuiBox>

          <VuiTypography variant="h4" gutterBottom textAlign="center" color="white">
            Profile Settings
          </VuiTypography>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <VuiBox mb={2}>
                <VuiTypography component="label" variant="button" fontWeight="light" color="white">
                  First Name
                </VuiTypography>
                <VuiInput
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </VuiBox>
            </Grid>

            <Grid item xs={12} sm={6}>
              <VuiBox mb={2}>
                <VuiTypography component="label" variant="button" fontWeight="light" color="white">
                  Last Name
                </VuiTypography>
                <VuiInput
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </VuiBox>
            </Grid>

            <Grid item xs={12}>
              <VuiBox mb={2}>
                <VuiTypography component="label" variant="button" fontWeight="light" color="white">
                  Current Email
                </VuiTypography>
                <VuiInput
                  type="email"
                  value={currentEmail}
                  disabled
                />
              </VuiBox>
            </Grid>

            <Grid item xs={12}>
              <VuiBox mb={2}>
                <VuiTypography component="label" variant="button" fontWeight="light" color="white">
                  New Email
                </VuiTypography>
                <VuiInput
                  type="email"
                  value={newEmail}
                  onChange={(e) => setNewEmail(e.target.value)}
                />
              </VuiBox>
            </Grid>

            <Grid item xs={12}>
              <VuiBox mb={2}>
                <VuiTypography component="label" variant="button" fontWeight="light" color="white">
                  New Password
                </VuiTypography>
                <VuiInput
                  type="password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    validatePassword(e.target.value);
                  }}
                />
                <Typography variant="caption" color="white">
                  <div style={{ color: passwordValid.length ? "green" : "red" }}>
                    At least 8 characters
                  </div>
                  <div style={{ color: passwordValid.capital ? "green" : "red" }}>
                    At least one capital letter
                  </div>
                  <div style={{ color: passwordValid.specialChar ? "green" : "red" }}>
                    At least one special character
                  </div>
                </Typography>
              </VuiBox>
            </Grid>

            <Grid item xs={12}>
              <VuiBox mb={2}>
                <VuiTypography component="label" variant="button" fontWeight="light" color="white">
                  Confirm Password
                </VuiTypography>
                <VuiInput
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </VuiBox>
            </Grid>
          </Grid>

          <VuiButton
            type="submit"
            variant="contained"
            color="info"
            fullWidth
            sx={{ mt: 3 }}
          >
            Update Profile
          </VuiButton>
        </VuiBox>
      </Container>

      <Dialog open={modalOpen} onClose={handleCloseModal}>
        <DialogTitle>Profile Update</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default ProfileSettings;