import { TimestreamQueryClient, QueryCommand } from "@aws-sdk/client-timestream-query";

// Utility to get current year dates
const getCurrentYearDates = () => {
  const year = new Date().getFullYear();
  const start = `${year}-01-01 00:00:00`;
  const end = `${year}-12-31 23:59:59`;
  return { start, end };
};

// Improved client configuration with environment variables
const client = new TimestreamQueryClient({
    region: "us-east-1",
    credentials: {
      accessKeyId: "AKIARUIVQDAN4QY4OFNB",
      secretAccessKey: "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",
    },
  });

// Main fetch function
const fetchAnnualTotalisersData = async () => {
  const { start, end } = getCurrentYearDates();
  
  const query = `
    SELECT
      date_trunc('month', time) AS month,
      SUM(CAST(Agg_Total AS double)) AS Agg_Total,
      SUM(CAST(Bit_Total AS double)) AS Bit_Total,
      SUM(CAST(Filler_Total AS double)) AS Filler_Total,
      SUM(CAST(Additive_Total AS double)) AS Additive_Total,
      SUM(CAST(RAP_Total AS double)) AS RAP_Total,
      SUM(CAST(Gas_Total AS double)) AS Gas_Total,
      SUM(CAST(Fuel_Total AS double)) AS Fuel_Total,
      SUM(CAST(Electric_Total AS double)) AS Electric_Total
    FROM "Flexy205-dbv2"."gatewaydata"
    WHERE time >= '${start}' AND time <= '${end}'
    GROUP BY date_trunc('month', time)
    ORDER BY date_trunc('month', time)`;

  try {
    const allRows = await paginatedQuery(query);
    return transformQueryResponse(allRows);
  } catch (error) {
    console.error("Fetch error:", error.message);
    return createEmptyDataStructure();
  }
};

// Dedicated pagination handler
const paginatedQuery = async (query) => {
  let allRows = [];
  let nextToken = null;

  do {
    const { Rows, NextToken } = await client.send(
      new QueryCommand({
        QueryString: query,
        MaxResults: 1000,
        NextToken: nextToken
      })
    );
    allRows = allRows.concat(Rows || []);
    nextToken = NextToken;
  } while (nextToken);

  return allRows;
};

// Improved transformer with type safety
const transformQueryResponse = (rows) => {
  const months = Array(12).fill().map((_, i) => {
    const date = new Date(Date.UTC(new Date().getFullYear(), i, 1));
    return date.toLocaleString('en-US', { month: 'short' });
  });

  const structure = {
    months,
    Agg_Total: Array(12).fill(0),
    Bit_Total: Array(12).fill(0),
    Filler_Total: Array(12).fill(0),
    Additive_Total: Array(12).fill(0),
    RAP_Total: Array(12).fill(0),
    Gas_Total: Array(12).fill(0),
    Fuel_Total: Array(12).fill(0),
    Electric_Total: Array(12).fill(0)
  };

  rows.forEach(({ Data }) => {
    const [monthCol, ...values] = Data;
    const monthIndex = new Date(monthCol.ScalarValue).getMonth();
    
    values.forEach(({ ScalarValue }, i) => {
      const key = Object.keys(structure)[i + 1];
      structure[key][monthIndex] = Math.round(parseFloat(ScalarValue)) || 0;
    });
  });

  return structure;
};

// Fallback for empty data
const createEmptyDataStructure = () => ({
  months: Array(12).fill().map((_, i) => 
    new Date(Date.UTC(new Date().getFullYear(), i, 1))
      .toLocaleString('en-US', { month: 'short' })),
  Agg_Total: Array(12).fill(0),
  Bit_Total: Array(12).fill(0),
  // ... other totals initialized to 0
});

export default fetchAnnualTotalisersData;