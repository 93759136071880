import React, { useState, useEffect } from "react";
import {
  Card,
  useMediaQuery,
  useTheme,
  IconButton,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import SemiCircleProgress from "./SemiCircleProgress";
import colors from "assets/theme/base/colors";
import linearGradient from "assets/theme/functions/linearGradient";
import VuiButton from "components/VuiButton";

const ProductionCardAsp = ({
  title,
  dayProgress,
  weekProgress,
  monthProgress,
  yearProgress,
  color,
  unit,
  site_id,
  productionCardSize,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [siteData, setSiteData] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [annualTotal, setAnnualTotal] = useState(0);
  const [workDaysPerWeek, setWorkDaysPerWeek] = useState(0);

  useEffect(() => {
    const fetchSiteData = async () => {
      try {
        const response = await fetch(
          `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/site/profile?site_id=${site_id}`
        );
        const data = await response.json();
        setSiteData(data);
        setAnnualTotal(data.annual_total || 0);
        setWorkDaysPerWeek(data.work_days_per_week || 0);
      } catch (error) {
        console.error("Error fetching site data:", error.message);
      }
    };

    fetchSiteData();
  }, [site_id]);

  const calculateTargetValues = () => {
    const weekTarget = annualTotal / 52;
    const monthTarget = annualTotal / 12;
    const dayTarget = annualTotal / (52 * workDaysPerWeek);
    return { weekTarget, monthTarget, dayTarget };
  };

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  const handleUpdate = async () => {
    const payload = {
      site_id: site_id,
      annual_total: annualTotal,
      work_days_per_week: workDaysPerWeek,
    };

    try {
      const response = await fetch(
        "https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/site/update",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      if (response.ok) {
        console.log("Data updated successfully");
        setOpenDialog(false);
      } else {
        console.error("Failed to update data");
      }
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const { gradients } = colors;
  const { cardContent } = gradients;

  const getSemiCircleSize = () => {
    switch (true) {
      case productionCardSize <= 3: // Smaller grid sizes
        return {
          day: { width: 200, height: 180 },
          secondary: { width: 100, height: 90 },
          infoBoxMargin: {
            day: "-40px",
            secondary: "-30px",
          },
        };
      case productionCardSize <= 6: // Medium grid sizes
        return {
          day: { width: 250, height: 220 },
          secondary: { width: 130, height: 110 },
          infoBoxMargin: {
            day: "-50px",
            secondary: "-35px",
          },
        };
      default: // Larger grid sizes
        return {
          day: { width: 280, height: 250 },
          secondary: { width: 150, height: 130 },
          infoBoxMargin: {
            day: "-60px",
            secondary: "-40px",
          },
        };
    }
  };

  const sizes = getSemiCircleSize();

  const InfoBox = ({ progress, label, sx, margin }) => (
    <VuiBox
      sx={{
        width: "90%",
        padding: "4px 8px",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        borderRadius: "8px",
        background: linearGradient(
          colors.gradients.cardContent.main,
          colors.gradients.cardContent.state,
          colors.gradients.cardContent.deg
        ),
        position: "relative",
        zIndex: 10,
        marginTop: margin || 0,
        ...sx,
      }}
    >
      <VuiTypography color="text" variant="caption" display="inline-block">
        0%
      </VuiTypography>
      <VuiBox
        flexDirection="column"
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ flex: 1, textAlign: "center" }}
      >
        <VuiTypography
          color="white"
          variant="h5"
          sx={{
            fontSize: {
              xs: "0.7rem",
              sm: "0.9rem",
              md: "1rem",
            },
          }}
        >
          {Math.round(progress)} {unit}
        </VuiTypography>
        <VuiTypography color="text" variant="caption" display="inline-block">
          {label}
        </VuiTypography>
      </VuiBox>
      <VuiTypography color="text" variant="caption" display="inline-block">
        100%
      </VuiTypography>
    </VuiBox>
  );

  return (
    <Card
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        padding: 1,
        overflow: "visible",
      }}
    >
      <IconButton
        sx={{ position: "absolute", top: 10, right: 10, color: "#fff", padding: 1 }}
        onClick={() => setOpenDialog(true)}
      >
        <EditIcon />
      </IconButton>
      <VuiBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
        p={1}
        sx={{ paddingBottom: 0 }}
      >
        <VuiTypography
          variant="sm"
          color="white"
          fontWeight="bold"
          sx={{
            mb: 0.5,
            fontSize: {
              xs: "0.8rem",
              sm: "0.9rem",
              md: "1rem",
              lg: "1.2rem",
            },
            textAlign: "center",
          }}
        >
          {title}
        </VuiTypography>

        <VuiBox
          display="flex"
          flexDirection={isSmallScreen ? "column" : "row"}
          justifyContent="center"
          alignItems="center"
          height="100%"
          width="100%"
          gap={1}
          sx={{
            paddingBottom: 0,
            flexWrap: "wrap",
          }}
        >
          {/* Day Progress */}
          <VuiBox
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              flex: "1 1 auto",
              width: "100%",
              maxWidth: "300px",
              position: "relative",
            }}
          >
            <SemiCircleProgress
              percentage={Math.round((dayProgress / (annualTotal / 52 / 5)) * 100) || 0}
              size={{
                width: sizes.day.width,
                height: sizes.day.height,
              }}
              strokeWidth={6}
              strokeColor={color}
            />
            <InfoBox
              progress={dayProgress || 0}
              label="Day"
              margin={sizes.infoBoxMargin.day}
              sx={{
                width: "90%",
                maxWidth: sizes.day.width * 0.9,
                marginTop: "-70px",
              }}
            />
          </VuiBox>

          {/* Week, Month, Year Progress Grid */}
          <VuiBox
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gridTemplateRows: "auto auto",
              justifyItems: "center",
              alignItems: "center",
              width: "100%",
              gap: 1,
            }}
          >
            {/* Week Progress */}
            <VuiBox
              sx={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <SemiCircleProgress
                percentage={Math.round((weekProgress / (annualTotal / 52)) * 100) || 0}
                size={sizes.secondary}
                strokeWidth={6}
                strokeColor={color}
              />
              <InfoBox
                progress={weekProgress || 0}
                label="Week"
                margin={sizes.infoBoxMargin.secondary}
                sx={{ width: "90%" }}
              />
            </VuiBox>

            {/* Month Progress */}
            <VuiBox
              sx={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <SemiCircleProgress
                percentage={Math.round((monthProgress / (annualTotal / 12)) * 100) || 0}
                size={sizes.secondary}
                strokeWidth={6}
                strokeColor={color}
              />
              <InfoBox
                progress={monthProgress || 0}
                label="Month"
                margin={sizes.infoBoxMargin.secondary}
                sx={{ width: "90%" }}
              />
            </VuiBox>

            {/* Year Progress */}
            <VuiBox
              sx={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <SemiCircleProgress
                percentage={Math.round((yearProgress / annualTotal) * 100) || 0}
                size={sizes.secondary}
                strokeWidth={6}
                strokeColor={color}
              />
              <InfoBox
                progress={yearProgress || 0}
                label="Year"
                margin={sizes.infoBoxMargin.secondary}
                sx={{ width: "90%" }}
              />
            </VuiBox>
          </VuiBox>
        </VuiBox>
      </VuiBox>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Edit Production Targets</DialogTitle>
        <DialogContent>
          <TextField
            label="Annual Total"
            type="number"
            fullWidth
            value={annualTotal}
            onChange={(e) => setAnnualTotal(Number(e.target.value))}
            sx={{ marginBottom: 2, marginTop: 2 }}
          />
          <TextField
            label="Work Days per Week"
            type="number"
            fullWidth
            value={workDaysPerWeek}
            onChange={(e) => setWorkDaysPerWeek(Number(e.target.value))}
            sx={{ marginBottom: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <VuiButton onClick={handleCloseDialog} color="secondary">
            Cancel
          </VuiButton>
          <VuiButton onClick={handleUpdate} color="primary">
            Update
          </VuiButton>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default ProductionCardAsp;
