// src/components/ProtectedRoute.js
import { Navigate } from "react-router-dom";
import {jwtDecode} from "jwt-decode";

const ProtectedRoute = ({ children, allowedRoles }) => {
  const token = localStorage.getItem("token");
  const roleId = parseInt(localStorage.getItem("role_id"));
  
  

  if (allowedRoles && !allowedRoles.includes(roleId)) {
    // Redirect to default route based on role
    switch(roleId) {
      case 1: // Area Manager
        return <Navigate to="/area-dashboard" replace />;
      case 2: // Site Manager
        return <Navigate to="/site-dashboard" replace />;
      case 3: // Admin
        return <Navigate to="/admin/users" replace />;
      case 4: // Engineer
        return <Navigate to="/engineer-page" replace />;
      default:
        return <Navigate to="/sign-in" replace />;
    }
  }

  return children;
};

export default ProtectedRoute;