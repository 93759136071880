import React, { useEffect, useState } from "react";
import { Card, Grid, useMediaQuery, useTheme, CircularProgress, Box } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

const getColor = (type) => (type === "good" ? "#4CAF50" : "#4CAF5033");

const CircularProgressWithLabel = ({ period, good, bad }) => {
  return (
    <Box position="relative" display="flex" justifyContent="center" alignItems="center">
      <CircularProgress
        variant="determinate"
        value={100}
        size={110}
        thickness={4}
        sx={{ color: getColor("bad"), position: "absolute" }}
      />
      <CircularProgress
        variant="determinate"
        value={good}
        size={110}
        thickness={4}
        sx={{ color: getColor("good") }}
      />
      <Box position="absolute" display="flex" flexDirection="column" alignItems="center">
        <VuiTypography variant="h6" color="white" sx={{ textTransform: "capitalize", textAlign: "center" }}>
          {period}
        </VuiTypography>
        <VuiTypography variant="body2" color="white" sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <span style={{ color: "#4CAF50", marginRight: 5 }}>{good}</span> 
          <span style={{ color: "#ea3546" }}>{bad}</span>
        </VuiTypography>
      </Box>
    </Box>
  );
};

const AsphaltBatchQuality = ({ batchQualityData }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // Calculate percentages from the raw data
  const calculatePercentages = (data) => {
    if (!data) return null;
    
    return {
      day: {
        good: Math.round((data.Day_Good_Quality / (data.Day_Good_Quality + data.Day_Bad_Quality)) * 100) || 0,
        bad: Math.round((data.Day_Bad_Quality / (data.Day_Good_Quality + data.Day_Bad_Quality)) * 100) || 0
      },
      week: {
        good: Math.round((data.Week_Good_Quality / (data.Week_Good_Quality + data.Week_Bad_Quality)) * 100) || 0,
        bad: Math.round((data.Week_Bad_Quality / (data.Week_Good_Quality + data.Week_Bad_Quality)) * 100) || 0
      },
      month: {
        good: Math.round((data.Month_Good_Quality / (data.Month_Good_Quality + data.Month_Bad_Quality)) * 100) || 0,
        bad: Math.round((data.Month_Bad_Quality / (data.Month_Good_Quality + data.Month_Bad_Quality)) * 100) || 0
      },
      year: {
        good: Math.round((data.Year_Good_Quality / (data.Year_Good_Quality + data.Year_Bad_Quality)) * 100) || 0,
        bad: Math.round((data.Year_Bad_Quality / (data.Year_Good_Quality + data.Year_Bad_Quality)) * 100) || 0
      }
    };
  };

  const [qualityData, setQualityData] = useState(null);

  useEffect(() => {
    if (batchQualityData) {
      const percentages = calculatePercentages(batchQualityData);
      setQualityData(percentages);
    }
  }, [batchQualityData]);

  if (!qualityData) {
    return (
      <Card sx={{ height: "100%", padding: 1 }}>
        <VuiBox display="flex" flexDirection="column" height="100%" p={2}>
          <VuiTypography
            variant="sm"
            color="white"
            fontWeight="bold"
            sx={{
              mb: 0.5,
              fontSize: {
                xs: "0.8rem",
                sm: "0.9rem",
                md: "1rem",
                lg: "1.2rem",
              },
              textAlign: "center",
            }}
          >
            Batch Quality
          </VuiTypography>
          <VuiBox display="flex" justifyContent="center" alignItems="center" height="100%">
            <CircularProgress color="inherit" />
          </VuiBox>
        </VuiBox>
      </Card>
    );
  }

  return (
    <Card sx={{ height: "100%", padding: 1 }}>
      <VuiBox display="flex" flexDirection="column" height="100%" p={2}>
        <VuiTypography
          variant="sm"
          color="white"
          fontWeight="bold"
          sx={{
            mb: 0.5,
            fontSize: {
              xs: "0.8rem",
              sm: "0.9rem",
              md: "1rem",
              lg: "1.2rem",
            },
            textAlign: "center",
          }}
        >
          Batch Quality
        </VuiTypography>
        <VuiBox display="flex" flexGrow={1} justifyContent="center" alignItems="center" mt={2}>
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            {Object.entries(qualityData).map(([period, values]) => (
              <Grid item xs={6} key={period}>
                <VuiBox display="flex" flexDirection="column" alignItems="center">
                  <CircularProgressWithLabel period={period} good={values.good} bad={values.bad} />
                </VuiBox>
              </Grid>
            ))}
          </Grid>
        </VuiBox>
      </VuiBox>
    </Card>
  );
};

export default AsphaltBatchQuality;