import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { Card, CircularProgress } from "@mui/material"; // Add CircularProgress for loading indicator
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import PieChart from "layouts/area-dashboard/components/Totals/PieChart"; // Import the pie chart component
import VerticalStackedBarChart from "layouts/area-dashboard/components/Totals/VerticalStackedBarChart"; // Import the stacked bar chart component
import DynamicSiteCard from "layouts/area-dashboard/components/SiteCard"; // Import the DynamicSiteCard
import AreaPpmSummary from "layouts/area-dashboard/components/AreaPpmSummary";
import useAuth from "../../useAuth"; // Import the custom hook
import axios from "axios";
import { useNavigate } from "react-router-dom";

// Virgin Avg Data
import LineChart from "examples/Charts/LineCharts/LineChart";
import { lineChartOptionsDashboard } from "layouts/area-dashboard/data/lineChartOptions";
import { TimestreamQueryClient, QueryCommand } from "@aws-sdk/client-timestream-query";

function AreaDashboard() {
  useAuth();
  const navigate = useNavigate();

  const [userSites, setUserSites] = useState([]);
  const [lineChartData, setLineChartData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true); // Track loading state
  const userToken = localStorage.getItem("token");
  const userId = localStorage.getItem("user_id");

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Start loading when site_id changes
      try {
        // Fetch user sites
        const sitesResponse = await axios.get(
          "https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/user/sites",
          {
            headers: { Authorization: `${userToken}`, "Content-Type": "application/json" },
            params: { user_id: userId },
          }
        );

        const sites = sitesResponse.data;

        // Fetch gateway names and Timestream data for each site
        const enrichedSites = await Promise.all(
          sites.map(async (site) => {
            try {
              const gatewayResponse = await fetch(
                `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/gateway/profile?site_id=${site.site_id}`,
                { headers: { Authorization: `${userToken}` } }
              );
              const response = await gatewayResponse.json();
              const gatewayName = response[0].name;

              // Timestream client
              const timestreamClient = new TimestreamQueryClient({
                region: "us-east-1",
                credentials: {
                  accessKeyId: "AKIARUIVQDAN4QY4OFNB",
                  secretAccessKey: "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",
                },
              });

              // Query strings
              const dailyQuery = `
                SELECT 
                  Asphalt_Day, RAP_Total_Day, Bit_Total, Fuel_Total, Elec_Per_Ton, Fuel_Per_Ton, 
                  Gas_Per_Ton, Wasted_Agg, Wasted_Elec, Wasted_Fuel, RAP_Total, Asphalt_Year, Breakdown_Hrs, 
                  Offline_Hrs, Idle_Hrs, Production_Hrs
                FROM "Flexy205-dbv2"."gatewaydata"
                WHERE ThingName = '${gatewayName}'
                ORDER BY time DESC
                LIMIT 10
              `;

              const monthlyQuery = `
                SELECT 
                  month(time) AS month,
                  MAX(RAP_Total) AS RAP_Total_Month,
                  MAX(Bit_Total) AS Bit_Total_Month
                FROM "Flexy205-dbv2"."gatewaydata"
                WHERE ThingName = '${gatewayName}' AND year(time) = year(current_date)
                GROUP BY month(time)
                ORDER BY month(time)
              `;

              // Fetch daily data with pagination
              const getDailyData = async (nextToken = null) => {
                const params = {
                  QueryString: dailyQuery,
                };
                if (nextToken) params.NextToken = nextToken;

                const data = await timestreamClient.send(new QueryCommand(params));
                return data;
              };

              const fetchAllDailyData = async () => {
                let allData = [];
                let nextToken = null;

                do {
                  const dailyData = await getDailyData(nextToken);
                  allData = [...allData, ...dailyData.Rows];
                  nextToken = dailyData.NextToken;
                } while (nextToken);

                return allData;
              };

              const dailyDataRows = await fetchAllDailyData();
              const dailyRow = dailyDataRows[0]?.Data || [];

              // Fetch monthly data (no pagination needed)
              const monthlyData = await timestreamClient.send(
                new QueryCommand({ QueryString: monthlyQuery })
              );
              const monthlyDataMap = Array(12).fill(0);
              const bitTotalMonthlyMap = Array(12).fill(0);
              monthlyData.Rows.forEach((row) => {
                const month = parseInt(row.Data[0]?.ScalarValue, 10);
                const rapTotal = parseFloat(row.Data[1]?.ScalarValue || 0);
                const bitTotal = parseFloat(row.Data[2]?.ScalarValue || 0);
                if (month) {
                  monthlyDataMap[month - 1] = rapTotal;
                  bitTotalMonthlyMap[month - 1] = bitTotal;
                }
              });
              return {
                id: site.site_id,
                name: site.name,
                gatewayName,
                Asphalt_Day: parseFloat(dailyRow[0]?.ScalarValue || 0),
                RAP_Total_Day: parseFloat(dailyRow[1]?.ScalarValue || 0),
                Bit_Total: parseFloat(dailyRow[2]?.ScalarValue || 0),
                Fuel_Total: parseFloat(dailyRow[3]?.ScalarValue || 0),
                Elec_Per_Ton: parseFloat(dailyRow[4]?.ScalarValue || 0),
                Fuel_Per_Ton: parseFloat(dailyRow[5]?.ScalarValue || 0),
                Gas_Per_Ton: parseFloat(dailyRow[6]?.ScalarValue || 0),
                Wasted_Agg: parseFloat(dailyRow[7]?.ScalarValue || 0),
                Wasted_Elec: parseFloat(dailyRow[8]?.ScalarValue || 0),
                Wasted_Fuel: parseFloat(dailyRow[9]?.ScalarValue || 0),
                RAP_Total: parseFloat(dailyRow[10]?.ScalarValue || 0),
                Asphalt_Year: parseFloat(dailyRow[11]?.ScalarValue || 0),
                Breakdown_Hrs: parseFloat(dailyRow[12]?.ScalarValue || 0),
                Offline_Hrs: parseFloat(dailyRow[13]?.ScalarValue || 0),
                Idle_Hrs: parseFloat(dailyRow[14]?.ScalarValue || 0),
                Production_Hrs: parseFloat(dailyRow[15]?.ScalarValue || 0),
                RAP_Total_Month: monthlyDataMap,
                Bit_Total_Month: bitTotalMonthlyMap,
              };
            } catch (error) {
              console.error(`Error fetching gateway data for site ${site.site_id}:`, error.message);
              return null;
            }
          })
        );

        const successfulSites = enrichedSites.filter((site) => site !== null);
        setUserSites(successfulSites);
        // In your data transformation:
        const chartData = successfulSites.map((site) => ({
          name: site.name,
          data: site.Bit_Total_Month || Array(12).fill(0),
        }));

        setLineChartData(chartData);
      } catch (error) {
        console.error("Error fetching sites or data:", error.message);
        setError("An error occurred while fetching data.");
      } finally {
        setLoading(false); // Stop loading after fetch
      }
    };

    fetchData();

    const interval = setInterval(() => {
      fetchData();
    }, 1000000); // Auto-refresh every 5 minutes

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [userToken, userId]); // Dependencies to re-run effect if token or user changes
  const handleCardClick = () => {
    navigate(`/section-list`);
  };
  console.log("Rendering LineChart with data:", lineChartData);

  return (
    <DashboardLayout>
      {/* Loading Spinner Overlay */}
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="inherit" />
        </div>
      )}
      {/* Navbar: Keeps the navbar always on top of the content */}
      <div style={{ zIndex: 10000, position: "relative" }}>
        <DashboardNavbar />
      </div>
      <div style={{ filter: loading ? "blur(5px)" : "none", transition: "filter 0.3s ease" }}>
        <VuiBox>
          <Grid container spacing={0.5} mb={0.5}>
            <Grid item xs={12} sm={6} md={3}>
              <Card sx={{ height: "100%" }}>
                <VuiBox sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
                  <VuiTypography variant="h6" color="white">
                    Asphalt
                  </VuiTypography>

                  <PieChart
                    data={
                      userSites.length > 0
                        ? userSites.map((site) => ({
                            name: site.name,
                            Asphalt_Year: site.Asphalt_Year || 0,
                          }))
                        : [{ name: "No Data", Asphalt_Year: 0 }]
                    }
                  />
                </VuiBox>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Card sx={{ height: "100%" }}>
                <VuiBox
                  sx={{
                    height: "300px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <VuiTypography variant="h6" color="white">
                    RAP
                  </VuiTypography>
                  <VuiBox sx={{ flexGrow: 1 }}>
                    <VerticalStackedBarChart
                      siteData={
                        userSites.length > 0
                          ? userSites.map((site) => ({
                              name: site.name,
                              RAP_Total_Month: site.RAP_Total_Month || Array(12).fill(0),
                            }))
                          : [{ name: "No Data", RAP_Total_Month: Array(12).fill(0) }]
                      }
                    />
                  </VuiBox>
                </VuiBox>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Card sx={{ height: "100%" }}>
                <VuiBox
                  sx={{
                    height: "370px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <VuiTypography variant="h6" color="white">
                    Binder
                  </VuiTypography>
                  <VuiBox sx={{ flexGrow: 1 }}>
                    <LineChart
                      lineChartData={lineChartData}
                      lineChartOptions={lineChartOptionsDashboard}
                    />
                  </VuiBox>
                </VuiBox>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Card sx={{ height: "100%" }} onClick={() => handleCardClick()}>
                <VuiBox sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
                  <VuiTypography variant="h6" color="white">
                    PPM
                  </VuiTypography>
                  <AreaPpmSummary userSites={userSites || []} />
                </VuiBox>
              </Card>
            </Grid>
          </Grid>

          <VuiBox mt={0.5}>
            <Grid container spacing={0.5}>
              {userSites.map((site) => (
                <Grid item xs={12} sm={6} md={4} key={site.id}>
                  <DynamicSiteCard site={site} />
                </Grid>
              ))}
            </Grid>
          </VuiBox>
        </VuiBox>
        <Footer />
      </div>
    </DashboardLayout>
  );
}

export default AreaDashboard;
