import { useMemo } from "react";
import PropTypes from "prop-types";
import { Table as MuiTable, TableBody, TableContainer, TableRow } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton"; // Keeping VuiButton
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";

function Table({ columns, rows }) {
  const { grey } = colors;
  const { size, fontWeightBold } = typography;
  const { borderWidth } = borders;

  const renderColumns = columns.map(({ name, align }) => (
    <VuiBox
      key={name}
      component="th"
      pt={1.5}
      pb={1.25}
      textAlign={align}
      fontSize={size.xxs}
      fontWeight={fontWeightBold}
      color="text"
      opacity={0.7}
      borderBottom={`${borderWidth[1]} solid ${grey[700]}`}
    >
      {name}
    </VuiBox>
  ));

  const renderRows = rows.map((row, key) => {
    const rowKey = `row-${key}`;

    const tableRow = columns.map(({ name, align }) => {
      let content;

      if (name === "Action") {
        content = (
          <VuiButton color="info" onClick={() => row.onClick(row["User ID"])}>
            Open Dialog
          </VuiButton>
        );
      } else if (Array.isArray(row[name])) {
        content = (
          <VuiBox display="flex" alignItems="center">
            <VuiTypography color="white" variant="button" fontWeight="medium">
              {row[name][1]}
            </VuiTypography>
          </VuiBox>
        );
      } else {
        content = (
          <VuiTypography variant="button" fontWeight="regular" color="text">
            {row[name]}
          </VuiTypography>
        );
      }

      return (
        <VuiBox
          key={`${rowKey}-${name}`}
          component="td"
          p={1}
          textAlign={align}
          borderBottom={`${borderWidth[1]} solid ${grey[700]}`}
        >
          {content}
        </VuiBox>
      );
    });

    return <TableRow key={rowKey}>{tableRow}</TableRow>;
  });

  return useMemo(
    () => (
      <TableContainer>
        <MuiTable>
          <VuiBox component="thead">
            <TableRow>{renderColumns}</TableRow>
          </VuiBox>
          <TableBody>{renderRows}</TableBody>
        </MuiTable>
      </TableContainer>
    ),
    [columns, rows]
  );
}

Table.defaultProps = {
  columns: [],
  rows: [],
};

Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  rows: PropTypes.arrayOf(PropTypes.object),
};

export default Table;