import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  Card,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  CircularProgress,
} from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";
import VuiTypography from "components/VuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import VuiPagination from "components/VuiPagination";
import Icon from "@mui/material/Icon";
import { useParams } from "react-router-dom";
import { TimestreamQueryClient, QueryCommand } from "@aws-sdk/client-timestream-query";

function DataExport() {
  const { site_id } = useParams();
  const [siteData, setSiteData] = useState([]);
  const [stockedMaterialsData, setStockedMaterialsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(12);
  const [dialogOpen, setDialogOpen] = useState(true);
  const [dateRange, setDateRange] = useState({ startDate: "", endDate: "" });
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [includeStockedMaterials, setIncludeStockedMaterials] = useState(false);
  const [siteName, setSiteName] = useState('');

  const columnsOptions = [
    { label: "Asphalt Production for the Day", value: "Asphalt_Day" },
    { label: "Asphalt Production for the Week", value: "Asphalt_Week" },
    { label: "Asphalt Production for the Month", value: "Asphalt_Month" },
    { label: "Asphalt Production for the Year", value: "Asphalt_Year" },
    { label: "RAP Total for the Day", value: "RAP_Total_Day" },
    { label: "RAP Total for the Week", value: "RAP_Total_Week" },
    { label: "RAP Total for the Month", value: "RAP_Total_Month" },
    { label: "RAP Total for the Year", value: "RAP_Total_Year" },
    { label: "RAP Utilisation for the Day", value: "RAP_Utilisation_Day" },
    { label: "RAP Utilisation for the Week", value: "RAP_Utilisation_Week" },
    { label: "RAP Utilisation for the Month", value: "RAP_Utilisation_Month" },
    { label: "RAP Utilisation for the Year", value: "RAP_Utilisation_Year" },
    { label: "Electric Total", value: "Electric_Total" },
    { label: "Wasted Electricity", value: "Wasted_Elec" },
    { label: "Wasted Aggregate", value: "Wasted_Agg" },
    { label: "Wasted Fuel", value: "Wasted_Fuel" },
    { label: "Aggregate Total", value: "Agg_Total" },
    { label: "Bitumen Total", value: "Bit_Total" },
    { label: "Filler Total", value: "Filler_Total" },
    { label: "Additive Total", value: "Additive_Total" },
    { label: "RAP Total", value: "RAP_Total" },
    { label: "Gas Total", value: "Gas_Total" },
    { label: "Fuel Total", value: "Fuel_Total" },
  ];

  const timestreamClient = new TimestreamQueryClient({
    region: "us-east-1",
    credentials: {
      accessKeyId: "AKIARUIVQDAN4QY4OFNB",
      secretAccessKey: "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",
    },
  });

  useEffect(() => {
    const fetchSiteData = async () => {
      try {
        const response = await fetch(
          `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/site/profile?site_id=${site_id}`
        );
        const data = await response.json();
        setSiteName(data.name);
      } catch (error) {
        console.error("Error fetching site data:", error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSiteData();
  }, [site_id]);

  const fetchAllTimestreamData = async (queryString) => {
    let nextToken = null;
    let allData = [];
    
    do {
      try {
        const command = new QueryCommand({
          QueryString: queryString,
          NextToken: nextToken
        });
        
        const response = await timestreamClient.send(command);
        
        if (response.Rows) {
          allData.push(...response.Rows);
        }
        
        nextToken = response.NextToken;
        
      } catch (error) {
        console.error("Error fetching timestream data:", error);
        break;
      }
    } while (nextToken);
    
    return allData;
  };

  const fetchSiteData = async () => {
    try {
      setLoading(true);

      const startDateFormatted = new Date(dateRange.startDate)
        .toISOString()
        .replace("T", " ")
        .slice(0, 23);
      const endDateFormatted = new Date(dateRange.endDate)
        .toISOString()
        .replace("T", " ")
        .slice(0, 23);
      const columns = selectedColumns.map((col) => col.value).join(", ");
      const columnsClause = columns ? `, ${columns}` : '';
      const query = `SELECT time${columnsClause} FROM "Flexy205-dbv2"."gatewaydata" WHERE time BETWEEN TIMESTAMP '${startDateFormatted}' AND TIMESTAMP '${endDateFormatted}' ORDER BY time ASC`;

      const allRows = await fetchAllTimestreamData(query);

      const formattedData = allRows.map((row) => {
        const rowData = { "Date Time": new Date(row.Data[0].ScalarValue).toLocaleString() };
        selectedColumns.forEach((col, index) => {
          rowData[col.label] = row.Data[index + 1]?.ScalarValue || 'N/A';
        });
        return rowData;
      });

      // Filter data to only include rows with changes
      let filteredData = [];
      if (formattedData.length > 0) {
        filteredData.push(formattedData[0]);
        for (let i = 1; i < formattedData.length; i++) {
          const currentRow = formattedData[i];
          const previousRow = formattedData[i - 1];
          const hasChange = selectedColumns.some(col => {
            const currentValue = currentRow[col.label];
            const previousValue = previousRow[col.label];
            return currentValue !== previousValue;
          });
          if (hasChange) {
            filteredData.push(currentRow);
          }
        }
      }

      setSiteData(filteredData);

      if (includeStockedMaterials) {
        await fetchStockedMaterialsData();
      }
    } catch (error) {
      console.error("Error fetching site data:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchStockedMaterialsData = async () => {
    try {
      const gatewayName = await fetchGatewayName();
      if (!gatewayName) return;

      const query = `SELECT * FROM "Flexy205-dbv2"."gatewaydata" WHERE ThingName = '${gatewayName}' ORDER BY time ASC`;
      const completeData = await fetchAllTimestreamData(query);

      const materials = [];
      const uniqueIndexes = new Set();
      completeData.forEach((row) => {
        const columnData = row.Data.reduce((acc, col, idx) => {
          acc[row.ColumnInfo[idx].Name] = col.ScalarValue;
          return acc;
        }, {});
        
        Object.keys(columnData).forEach((key) => {
          if (key.startsWith("SM_Name_")) {
            const index = key.split("_")[2];
            const totalKey = `SM_Total_${index}`;
            if (columnData[totalKey] && !uniqueIndexes.has(index)) {
              materials.push({
                nameValue: columnData[key],
                total: columnData[totalKey],
                index: index,
                key: key,
                totalKey: totalKey,
              });
              uniqueIndexes.add(index);
            }
          }
        });
      });

      setStockedMaterialsData(materials.sort((a, b) => a.index - b.index));
    } catch (error) {
      console.error("Error fetching stocked materials data:", error);
    }
  };

  const fetchGatewayName = async () => {
    try {
      const response = await fetch(
        `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/gateway/profile?site_id=${site_id}`
      );
      const data = await response.json();
      return data[0]?.name;
    } catch (error) {
      console.error("Error fetching gateway name:", error.message);
      return null;
    }
  };

  const handleDialogClose = () => setDialogOpen(false);

  const handleSubmit = () => {
    fetchSiteData();
    handleDialogClose();
  };

  const displayedRows = siteData.slice((page - 1) * rowsPerPage, page * rowsPerPage);
  const totalPages = Math.ceil(siteData.length / rowsPerPage);
  const csvData = includeStockedMaterials
    ? siteData.map(row => ({
        ...row,
        ...stockedMaterialsData.reduce((acc, material) => {
          acc[material.key] = material.nameValue;
          acc[material.totalKey] = material.total;
          return acc;
        }, {})
      }))
    : siteData;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <Card>
          <VuiBox display="flex" justifyContent="space-between" alignItems="center" p={2}>
            <VuiTypography variant="lg" color="white">
              Data Export - {siteName}
            </VuiTypography>
            <div>
              <VuiButton
                variant="contained"
                color="secondary"
                onClick={() => setDialogOpen(true)}
                sx={{ mr: 2 }}
              >
                Edit
              </VuiButton>
              <CSVLink data={csvData} filename="Report.csv" style={{ textDecoration: "none" }}>
                <VuiButton variant="contained" color="primary">
                  Download
                </VuiButton>
              </CSVLink>
            </div>
          </VuiBox>

          {loading ? (
            <VuiBox display="flex" justifyContent="center" py={4}>
              <CircularProgress />
            </VuiBox>
          ) : (
            <Table
              columns={[
                { name: "Date Time", align: "left" },
                ...selectedColumns.map(col => ({ name: col.label, align: "center" })),
                ...(includeStockedMaterials
                  ? stockedMaterialsData.flatMap(material => [
                      { name: material.key, align: "center" },
                      { name: material.totalKey, align: "center" }
                    ])
                  : [])
              ]}
              rows={displayedRows.map(row => ({
                ...row,
                ...(includeStockedMaterials && stockedMaterialsData.reduce((acc, material) => {
                  acc[material.key] = material.nameValue;
                  acc[material.totalKey] = material.total;
                  return acc;
                }, {}))
              }))}
            />
          )}

          <VuiBox display="flex" justifyContent="center" p={2}>
            <VuiPagination>
              <VuiPagination item onClick={() => setPage(Math.max(1, page - 1))} disabled={page === 1}>
                <Icon>keyboard_arrow_left</Icon>
              </VuiPagination>
              {Array.from({ length: totalPages }, (_, i) => (
                <VuiPagination
                  key={i + 1}
                  item
                  active={page === i + 1}
                  onClick={() => setPage(i + 1)}
                >
                  {i + 1}
                </VuiPagination>
              ))}
              <VuiPagination item onClick={() => setPage(Math.min(totalPages, page + 1))} disabled={page === totalPages}>
                <Icon>keyboard_arrow_right</Icon>
              </VuiPagination>
            </VuiPagination>
          </VuiBox>
        </Card>
      </VuiBox>

      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Edit Date Range and Data</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <VuiTypography variant="subtitle1" gutterBottom>
                Select Date Range
              </VuiTypography>
              <TextField
                type="datetime-local"
                label="Start Date"
                fullWidth
                value={dateRange.startDate}
                onChange={e => setDateRange({ ...dateRange, startDate: e.target.value })}
                InputLabelProps={{ shrink: true }}
                sx={{ mb: 2 }}
              />
              <TextField
                type="datetime-local"
                label="End Date"
                fullWidth
                value={dateRange.endDate}
                onChange={e => setDateRange({ ...dateRange, endDate: e.target.value })}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={12}>
              <VuiTypography variant="subtitle1" gutterBottom>
                Select Data Columns
              </VuiTypography>
              <FormGroup>
                {columnsOptions.map(col => (
                  <FormControlLabel
                    key={col.value}
                    control={
                      <Checkbox
                        checked={selectedColumns.some(c => c.value === col.value)}
                        onChange={() => setSelectedColumns(prev =>
                          prev.some(c => c.value === col.value)
                            ? prev.filter(c => c.value !== col.value)
                            : [...prev, col]
                        )}
                      />
                    }
                    label={col.label}
                  />
                ))}
              </FormGroup>
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={includeStockedMaterials}
                    onChange={e => setIncludeStockedMaterials(e.target.checked)}
                  />
                }
                label="Include Stocked Materials"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            disabled={!dateRange.startDate || !dateRange.endDate || (!selectedColumns.length && !includeStockedMaterials)}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Footer />
    </DashboardLayout>
  );
}

export default DataExport;