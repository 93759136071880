import React from "react";
import { Card, useMediaQuery, useTheme } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import SemiCircleProgress from "./SemiCircleProgress";
import colors from "assets/theme/base/colors";
import linearGradient from "assets/theme/functions/linearGradient";

const ProductionCardRAP = ({
  title,
  RAP_Utilisation_Day,
  RAP_Utilisation_Week,
  RAP_Utilisation_Month,
  RAP_Utilisation_Year,
  color,
  unit,
  maxDayValue,
  maxWeekValue,
  maxMonthValue,
  maxYearValue,
  productionCardSize,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  const { gradients } = colors;
  const { cardContent } = gradients;

  // Updated getSemiCircleSize to provide more controlled sizing
  const getSemiCircleSize = () => {
    switch (true) {
      case productionCardSize <= 3: // Smaller grid sizes
        return {
          day: { width: 200, height: 180 },
          secondary: { width: 100, height: 90 },
          infoBoxMargin: {
            day: "-40px",
            secondary: "-30px",
          },
        };
      case productionCardSize <= 6: // Medium grid sizes
        return {
          day: { width: 250, height: 220 },
          secondary: { width: 130, height: 110 },
          infoBoxMargin: {
            day: "-50px",
            secondary: "-35px",
          },
        };
      default: // Larger grid sizes
        return {
          day: { width: 280, height: 250 },
          secondary: { width: 150, height: 130 },
          infoBoxMargin: {
            day: "-60px",
            secondary: "-40px",
          },
        };
    }
  };

  const sizes = getSemiCircleSize();

  const InfoBox = ({ progress, label, sx, margin }) => (
    <VuiBox
      sx={{
        width: "90%",
        padding: "4px 8px",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        borderRadius: "8px",
        background: linearGradient(
          colors.gradients.cardContent.main,
          colors.gradients.cardContent.state,
          colors.gradients.cardContent.deg
        ),
        position: "relative",
        zIndex: 10,
        marginTop: margin || 0,
        ...sx,
      }}
    >
      <VuiTypography color="text" variant="caption" display="inline-block">
        0%
      </VuiTypography>
      <VuiBox
        flexDirection="column"
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ flex: 1, textAlign: "center" }}
      >
        <VuiTypography
          color="white"
          variant="h5"
          sx={{
            fontSize: {
              xs: "0.7rem",
              sm: "0.9rem",
              md: "1rem",
            },
          }}
        >
          {Math.round(progress)} {unit}
        </VuiTypography>
        <VuiTypography color="text" variant="caption" display="inline-block">
          {label}
        </VuiTypography>
      </VuiBox>
      <VuiTypography color="text" variant="caption" display="inline-block">
        100%
      </VuiTypography>
    </VuiBox>
  );

  return (
    <Card
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        padding: 1,
        overflow: "visible",
      }}
    >
      <VuiBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
        p={1}
        sx={{ paddingBottom: 0 }}
      >
        <VuiTypography
          variant="sm"
          color="white"
          fontWeight="bold"
          sx={{
            mb: 0.5,
            fontSize: {
              xs: "0.8rem",
              sm: "0.9rem",
              md: "1rem",
              lg: "1.2rem",
            },
            textAlign: "center",
          }}
        >
          {title}
        </VuiTypography>

        <VuiBox
          display="flex"
          flexDirection={isSmallScreen ? "column" : "row"}
          justifyContent="center"
          alignItems="center"
          height="100%"
          width="100%"
          gap={1}
          sx={{
            paddingBottom: 0,
            flexWrap: "wrap",
          }}
        >
          {/* Day Progress */}
          <VuiBox
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              flex: "1 1 auto",
              width: "100%",
              maxWidth: "300px",
              position: "relative",
            }}
          >
            <SemiCircleProgress
              percentage={Math.round(RAP_Utilisation_Day)} // Rounded value
              size={{
                width: sizes.day.width,
                height: sizes.day.height,
              }}
              strokeWidth={6}
              strokeColor={color}
            />
            <InfoBox
              progress={Math.round(maxDayValue) || 0}
              label="Day"
              margin={sizes.infoBoxMargin.day}
              sx={{
                width: "90%",
                maxWidth: sizes.day.width * 0.9,
                marginTop: "-70px",
              }}
            />
          </VuiBox>

          {/* Week, Month, Year Progress Grid */}
          <VuiBox
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gridTemplateRows: "auto auto",
              justifyItems: "center",
              alignItems: "center",
              width: "100%",
              gap: 1,
            }}
          >
            {/* Week Progress */}
            <VuiBox
              sx={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <SemiCircleProgress
                percentage={Math.round(RAP_Utilisation_Week)} // Rounded value
                size={sizes.secondary}
                strokeWidth={6}
                strokeColor={color}
              />
              <InfoBox
                progress={Math.round(maxWeekValue) || 0} // Rounded value
                label="Week"
                margin={sizes.infoBoxMargin.secondary}
                sx={{ width: "90%" }}
              />
            </VuiBox>

            {/* Month Progress */}
            <VuiBox
              sx={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <SemiCircleProgress
                percentage={Math.round(RAP_Utilisation_Month) || 0} // Rounded value
                size={sizes.secondary}
                strokeWidth={6}
                strokeColor={color}
              />
              <InfoBox
                progress={Math.round(maxMonthValue) || 0} // Rounded value
                label="Month"
                margin={sizes.infoBoxMargin.secondary}
                sx={{ width: "90%" }}
              />
            </VuiBox>

            {/* Year Progress */}
            <VuiBox
              sx={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <SemiCircleProgress
                percentage={Math.round(RAP_Utilisation_Year) || 0} // Rounded value
                size={sizes.secondary}
                strokeWidth={6}
                strokeColor={color}
              />
              <InfoBox
                progress={Math.round(maxYearValue) || 0} // Rounded value
                label="Year"
                margin={sizes.infoBoxMargin.secondary}
                sx={{ width: "90%" }}
              />
            </VuiBox>
          </VuiBox>
        </VuiBox>
      </VuiBox>
    </Card>
  );
};

export default ProductionCardRAP;
