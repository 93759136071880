import { useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";

const useAuth = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkTokenExpiration = () => {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          const currentTime = Date.now() / 1000;
  
          if (decodedToken.exp < currentTime) {
            localStorage.clear();
            navigate("/sign-in");
          } else {
            localStorage.setItem("is_admin", decodedToken.role_id === 3 ? "true" : "false");
          }
        } catch (err) {
          console.error("Error decoding token", err);
          localStorage.clear();
          navigate("/sign-in");
        }
      } else {
        navigate("/sign-in");
      }
    };
  
    checkTokenExpiration();
  }, [navigate]);
};

export default useAuth;