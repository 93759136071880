import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";
import ConfiguratorRoot from "examples/Configurator/ConfiguratorRoot";
import { useVisionUIController, setOpenConfigurator } from "context";
import logo from "assets/images/bbsys.png";
import routes from "routes";

function Configurator() {
  const [controller, dispatch] = useVisionUIController();
  const { openConfigurator } = controller;
  const navigate = useNavigate();
  const configuratorRef = useRef(null);

  // Get user role from localStorage
  const userRole = localStorage.getItem("role_id"); // Assuming role_id is stored as string
  const roleId = userRole ? parseInt(userRole) : null;

  const handleCloseConfigurator = () => setOpenConfigurator(dispatch, false);

  const handleNavigation = (route) => {
    navigate(route);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (configuratorRef.current && !configuratorRef.current.contains(event.target)) {
        handleCloseConfigurator();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [configuratorRef]);

  // Function to check if a route should be visible based on user role
  const shouldShowRoute = (route) => {
    // Always show these basic routes to all users
    const commonRoutes = ["Settings", "Sign Out", "Section List"];
    if (commonRoutes.includes(route.name)) return true;

    // Admin has access to everything
    if (roleId === 3) return true; // Assuming 3 is Admin role_id

    // Role-specific routes
    switch (roleId) {
      case 1: // Area Manager
        return ["Area Dashboard", "Site Dashboard", "Data Export"].includes(route.name);
      case 2: // Site Manager
        return ["Site Dashboard", "Data Export"].includes(route.name);
      case 4: // Engineer
        return ["Engineer Page"].includes(route.name);
      default:
        return false;
    }
  };

  return (
    <ConfiguratorRoot ref={configuratorRef} variant="permanent" ownerState={{ openConfigurator }}>
      <VuiBox
        backgroundColor="black"
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={3}
        pb={0.8}
        px={3}
      >
        <VuiBox component="img" src={logo} alt="Logo" width="25px" height="35px" />
        <Icon
          sx={({ typography: { size, fontWeightBold }, palette: { white } }) => ({
            fontSize: `${size.md} !important`,
            fontWeight: `${fontWeightBold} !important`,
            stroke: `${white.main} !important`,
            strokeWidth: "2px",
            cursor: "pointer",
            mt: 2,
          })}
          onClick={handleCloseConfigurator}
        >
          close
        </Icon>
      </VuiBox>

      <Divider light />

      <VuiBox px={3} py={2} display="flex" flexDirection="column" gap={2}>
        {routes
          .filter((route) => {
            // Skip routes that shouldn't be in the configurator
            if (!route.route || ["Sign In", "Forgot Password", "Reset Password","PPM"].includes(route.name)) {
              return false;
            }
            return shouldShowRoute(route);
          })
          .map((route) => (
            route.type === "collapse" && (
              <VuiButton
                key={route.key}
                variant="text"
                startIcon={route.icon}
                sx={{ color: "white", justifyContent: "flex-start" }}
                onClick={() => handleNavigation(route.route)}
              >
                {route.name}
              </VuiButton>
            )
          ))}
      </VuiBox>
    </ConfiguratorRoot>
  );
}

export default Configurator;